import '@/translation/config';

import { ChakraProvider } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { env } from '@/env/client';
import theme from '@/lib/theme';
import { admin } from '@/routes/admin';
import { app } from '@/routes/app';
import { auth } from '@/routes/auth';
import { exports } from '@/routes/exports';
import { responsible } from '@/routes/responsible';
import { root } from '@/routes/root';
import { unsubscribe } from '@/routes/unsubscribe';
import * as Sentry from "@sentry/react";

const rawSentryEnabled = env.VITE_SENTRY_ENABLED; 
// Convierte a booleano
const booleanSentryEnabled = rawSentryEnabled  === 'true';

Sentry.init({
	enabled: booleanSentryEnabled,
	
	dsn: env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
dayjs.extend(relativeTime);

const router = createBrowserRouter([
	...root,
	app,
	auth,
	responsible,
	exports,
	unsubscribe,
	admin,
]);
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<GoogleOAuthProvider clientId={env.VITE_GOOGLE_CLIENT}>
			<ChakraProvider
				theme={theme}
				toastOptions={{
					defaultOptions: { position: 'bottom-right', isClosable: true },
				}}
			>
				<QueryClientProvider client={queryClient}>
					<RouterProvider router={router} />
				</QueryClientProvider>
			</ChakraProvider>
		</GoogleOAuthProvider>
	</React.StrictMode>
);
