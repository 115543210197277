import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { User } from '@/types';

interface UserState {
	user?: User;
	setUser: (payload: User) => void;
	updateUser: (payload: Partial<User>) => void;
	removeUser: () => void;
}

export const useUserStore = create<UserState>()(
	persist(
		(set) => ({
			user: undefined,
			setUser: (payload) => set(() => ({ user: payload })),
			updateUser: (payload) =>
				set((state) => ({
					user: {
						...state.user!,
						...payload,
					},
				})),
			removeUser: () => set(() => ({ user: undefined })),
		}),
		{
			name: 'user', // name of the item in the storage (must be unique)
		}
	)
);
