import { Spinner, Stack } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";

import ProjectLayout from "@/layouts/ProjectLayout";
import { api } from "@/lib/axios";
import { useProjectStore } from "@/store/project";

async function fetchProjectById(id: string, pathname: string) {
  let url = `/project/${id}`;

  if (pathname.includes("responsible")) {
    url = `/responsible/${id}`;
  }

  const { data } = await api.get(url);

  // TODO: fix this in API
  if ("error" in data) {
    return null;
  }

  return data;
}

export function ProjectGuard() {
  const { setProject } = useProjectStore();
  const { id = "" } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const { data, isLoading } = useQuery(
    ["project", id],
    () => fetchProjectById(id, pathname),
    {
      onSuccess: (data) => {
        if (data) setProject(data);
      },
    }
  );

  if (isLoading)
    return (
      <Stack w="100%" h="80vh" alignItems="center" justify="center">
        <Spinner />
      </Stack>
    );

  if (!data) {
    let url = "/app/dashboard";

    if (pathname.includes("responsible")) {
      url = "/responsible/indicators";
    }

    return <Navigate to={url} replace />;
  }

  return (
    <ProjectLayout>
      <Outlet />
    </ProjectLayout>
  );
}
