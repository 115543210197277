import { Container, Stack } from '@chakra-ui/react';

import { ProjectDelete } from '@/components/project/Delete';
import { ProjectInformation } from '@/components/project/Information';
import { ProjectMembers } from '@/components/project/Members';
import { ProjectPause } from '@/components/project/Pause';
import { ProjectSummary } from '@/components/project/Summary';
import { Tags } from '@/components/project/Tags';
import { useProjectStore } from '@/store/project';
import { useUserStore } from '@/store/user';

export default function Settings() {
	const { user } = useUserStore();
	const { project } = useProjectStore();

	return (
		<Container maxW="4xl" as={Stack} spacing={12}>
			<ProjectInformation />
			<ProjectSummary />
			<ProjectMembers />
			<Tags />
			<ProjectPause />
			{project?.members.find(
				({ email, role }) => role === 'owner' && email === user?.email
			) && <ProjectDelete />}
		</Container>
	);
}
