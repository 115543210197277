import {
  Button,
  DrawerBody,
  DrawerFooter,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";

import { api } from "@/lib/axios";
import { Indicator, Value } from "@/types";

interface Props {
  indicator: Indicator;
}

async function fetchLogs(indicator: Indicator, page: number) {
  const { data } = await api.get<{
    data: Value[];
    info: Record<string, number>;
  }>(
    `/full-project/${indicator.project}/indicator/logs/${indicator._id}?page=${page}`
  );

  return data;
}

export function ValueLogs({ indicator }: Props) {
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["logs"],
    ({ pageParam = 1 }) => fetchLogs(indicator, pageParam),
    {
      getNextPageParam: ({ info }) => {
        if (!info.next) return false;
        return info.next;
      },
    }
  );
  const { t } = useTranslation();

  const logs = useMemo(
    () =>
      data?.pages.reduce((prev, page) => {
        return {
          info: page.info,
          data: [...prev.data, ...page.data],
        };
      }),
    [data]
  );

  return (
    <Flex direction="column" h="100%">
      <DrawerBody flex="1" overflowY="auto" overflowX="hidden">
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>{t("author")}</Th>
                <Th>{t("created_at")}</Th>
                <Th>{t("month")}</Th>
                <Th>{t("year")}</Th>
                <Th isNumeric>{t("value")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {logs?.data?.map(
                ({ _id, uploadedBy, month, value, year, createdAt }) => (
                  <Tr key={`log-${_id}`}>
                    <Td>{uploadedBy}</Td>
                    <Td> {dayjs().to(dayjs(createdAt))}</Td>
                    <Td>{t(`months.${month}`)}</Td>
                    <Td>{year}</Td>
                    <Td isNumeric>{value}</Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </DrawerBody>
      <DrawerFooter>
        {logs?.data && hasNextPage && (
          <Button
            onClick={() => fetchNextPage()}
            mt={4}
            width="full"
            isLoading={isLoading}
          >
            {t("load_more")}
          </Button>
        )}
      </DrawerFooter>
    </Flex>
  );
}
