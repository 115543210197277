import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';

export const env = createEnv({
	clientPrefix: 'VITE_',
	client: {
		VITE_API_URL: z.string().min(1),
		VITE_GOOGLE_CLIENT: z.string().min(1),
		VITE_SENTRY_DSN: z.string(),
		VITE_SENTRY_ENABLED: z.enum(['true', 'false']).default('false')
	},
	runtimeEnv: import.meta.env,
});
