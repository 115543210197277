import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  HStack,
  Input,
  SimpleGrid,
  Skeleton,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { api } from "@/lib/axios";
import { createArray } from "@/lib/utils";
import { Indicator, Value } from "@/types";

interface Props {
  indicator: Indicator;
  onClose: () => void;
}

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

async function fetchValues(id: Indicator["_id"], year: number) {
  const { data } = await api.get<Value[]>(
    `/value?year=${year}&indicator=${id}`
  );

  return createArray(12).map((month) => {
    const item = data.find((d) => d.month === month && year) || {
      value: 0,
      _id: null,
    };

    return {
      month,
      year,
      indicator: id,
      value: item.value,
      _id: item._id,
    };
  });
}

type Form = { values: Value[] };

export function Values({ onClose, indicator }: Props) {
  const { _id, project } = indicator;
  const [year, setYear] = useState(currentYear);
  const { data, isLoading } = useQuery(
    ["values", year, _id],
    () => fetchValues(_id, year),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, dirtyFields },
    resetField,
  } = useForm<Form>();
  const { fields } = useFieldArray({
    control,
    name: "values",
  });
  const toast = useToast();

  useEffect(() => {
    if (data) {
      setValue("values", data as Value[]);
    }
  }, [data, setValue]);

  const onSubmit = async (formData: Form) => {
    try {
      const filtered = formData.values.filter(({ month, year, value }) => {
        const item = data?.find((d) => d.month === month && year);

        if (!item) return true;

        return item.value !== value;
      });

      await api.patch(
        `full-project/${project}/indicator/${indicator?._id}/values`,
        { values: filtered }
      );

      resetField("values");

      toast({
        title: "Values saved.",
        status: "success",
      });
    } catch (error) {
      toast({
        status: "error",
        title: "Failed to save values",
      });
    }
  };

  return (
    <SimpleGrid
      templateRows="auto 1fr auto"
      gap={4}
      h="full"
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <HStack justify="center" gap={12}>
        <Button
          size="sm"
          leftIcon={<ArrowLeft size={14} />}
          colorScheme="gray"
          isDisabled={!!dirtyFields.values?.length}
          onClick={() => setYear(year - 1)}
        >
          {year - 1}
        </Button>
        <Heading size="md">{year}</Heading>
        <Button
          size="sm"
          rightIcon={<ArrowRight size={14} />}
          isDisabled={year === currentYear || !!dirtyFields.values?.length}
          colorScheme="gray"
          onClick={() => setYear(year + 1)}
        >
          {year + 1}
        </Button>
      </HStack>
      <Stack overflowY="auto">
        <SimpleGrid columns={4} gap={4} my={6}>
          {isLoading &&
            createArray(12).map((item) => <Skeleton key={item} h={28} />)}
          {!isLoading &&
            fields.map((item, index) => (
              <Card key={`${item.month}-${item.year}`}>
                <CardHeader py={2}>
                  <Heading size="sm" textAlign="center">
                    {t(`months.${item.month}`)}
                  </Heading>
                </CardHeader>
                <CardBody pt={1}>
                  <Controller
                    render={({ field }) => (
                      <Input
                        type="number"
                        value={field.value}
                        isDisabled={
                          item.month > currentMonth && item.year === currentYear
                        }
                        onChange={(e) => {
                          const cleanValue = e.target.value.replace(
                            /[^0-9-]/g,
                            ""
                          );

                          field.onChange(cleanValue);
                        }}
                      />
                    )}
                    name={`values.${index}.value`}
                    control={control}
                  />
                </CardBody>
              </Card>
            ))}
        </SimpleGrid>
      </Stack>

      {!!dirtyFields.values?.length && (
        <Alert status="info">
          <AlertIcon />
          <AlertTitle>You have unsaved changes,</AlertTitle>
          <AlertDescription>
            save to be able to change the year.
          </AlertDescription>
        </Alert>
      )}

      <Flex justify="flex-end">
        <Button
          colorScheme="gray"
          mr={3}
          onClick={onClose}
          isDisabled={isSubmitting}
        >
          {t("cancel")}
        </Button>
        <Button type="submit" isLoading={isSubmitting}>
          {t("save")}
        </Button>
      </Flex>
    </SimpleGrid>
  );
}
