import { Button, Flex, HStack, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, NavLink } from 'react-router-dom';

import { MobileMenu } from '@/components/layout/MobileMenu';
import { Logo } from '@/components/Logo';
import { UserMenu } from '@/components/user/Menu';
import { NavItem } from '@/types';

const links: NavItem[] = [
  {
    href: '/admin/projects',
    label: 'projects',
  },
  {
    href: '/admin/users',
    label: 'users',
  },
  {
    href: '/admin/emails',
    label: 'email.emails',
  },
];

interface Props {
  children: React.ReactNode;
}

export function AdminLayout({ children }: Props) {
  const { t } = useTranslation();

  return (
    <Stack p='4' spacing={8} minH='100vh' overflowX='hidden'>
      <Flex as='header' justify='space-between' align='center'>
        <Logo />
        <HStack
          flex='1'
          justify='center'
          gap={8}
          display={{ base: 'none', md: 'flex' }}
        >
          {links.map(({ href, label }) => (
            <NavLink
              key={label}
              to={href}
              style={({ isActive }) => {
                return {
                  fontWeight: '700',
                  color: isActive ? '#2B6CB0' : '',
                };
              }}
            >
              {t(label)}
            </NavLink>
          ))}
        </HStack>
        <HStack gap={6}>
          <Button
            as={RouterLink}
            to='/app/dashboard'
            size='sm'
            variant='outline'
            colorScheme='gray'
            display={{ base: 'none', sm: 'flex' }}
          >
            {t('goApp')}
          </Button>
          <UserMenu />
          <MobileMenu links={links} />
        </HStack>
      </Flex>
      {children}
    </Stack>
  );
}
