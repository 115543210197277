import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  HStack,
  Stack,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { DownloadIcon } from "lucide-react";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { api } from "@/lib/axios";
import { fetchExport } from "@/lib/utils";
import { Project } from "@/types";

const DownloadCsv = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const [tags, setTags] = useState<string[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [checkedProjects, setCheckedProjects] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    getTags();
  }, []);

  async function getTags() {
    try {
      const { data } = await api.get("/project/tags");
      setTags(data);
    } catch (error) {
      console.error("Error getting project tags", error);
    }
  }

  const handleChange = async (selected: string[]) => {
    try {
      if (selected.length > 0) {
        const queryParams = new URLSearchParams();
        selected.forEach((tag) => queryParams.append("tags", tag));

        const response = await api(`/project/filter?${queryParams.toString()}`);
        setProjects(response.data);
        const projectIds = response.data.map((project: Project) => project._id);
        setCheckedProjects(projectIds);
        return projects;
      } else {
        setProjects([]);
        setCheckedProjects([]);
      }
    } catch (error) {
      console.error("No projects were found with the requested tags", error);
    }
  };

  const handleCheckChange = (projectId: string) => {
    setCheckedProjects((prev) => {
      if (prev.includes(projectId)) {
        return prev.filter((id) => id !== projectId);
      } else {
        return [...prev, projectId];
      }
    });
  };

  const handleDownlaod = (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const queryParams = new URLSearchParams();
      checkedProjects.forEach((id) => queryParams.append("projects", id));
      const fileName = "projects.csv";
      fetchExport(`/export/project/tags?${queryParams.toString()}`, fileName);
      toast({ title: "Download successful", status: "success" });
    } catch (error) {
      toast({
        status: "error",
        title: "Error to download",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        size="sm"
        colorScheme="gray"
        onClick={onOpen}
        leftIcon={<DownloadIcon size={16} />}
      >
        {t("project.hashtags.download")}
      </Button>
      <Drawer size="sm" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            {t("project.hashtags.download")}
          </DrawerHeader>
          <DrawerBody>
            <Stack
              as="form"
              onSubmit={handleDownlaod}
              direction="column"
              h="100%"
              py={3}
              justifyContent={"space-between"}
            >
              <Stack spacing={4}>
                <FormControl>
                  <Text fontSize={"large"} pb={3}>
                    {t("project.hashtags.select")}
                  </Text>
                  <Select
                    isMulti
                    colorScheme="primary"
                    size={{ base: "sm", md: "md" }}
                    options={tags.map((tag) => ({
                      label: tag,
                      value: tag,
                    }))}
                    onChange={(selectedOptions) => {
                      const selectedValues = (
                        selectedOptions as { label: string; value: string }[]
                      ).map((option) => option.value);
                      handleChange(selectedValues);
                    }}
                    placeholder={t("placeholder.select")}
                  />
                </FormControl>

                {projects?.map((project) => (
                  <Card
                    bg="muted"
                    key={project._id}
                    onClick={() => handleCheckChange(project._id)}
                    role="button"
                  >
                    <CardBody>
                      <Checkbox
                        fontWeight={"bold"}
                        isChecked={checkedProjects.includes(project._id)}
                        onChange={() => handleCheckChange(project._id)}
                      >
                        {project.name}
                      </Checkbox>

                      <HStack mt={2}>
                        {project.tags.map((tag) => (
                          <Tag key={tag} minW="min-content" maxW="max-content">
                            {tag}
                          </Tag>
                        ))}
                      </HStack>
                    </CardBody>
                  </Card>
                ))}
              </Stack>

              <ButtonGroup
                display="grid"
                alignSelf="end"
                gridTemplateColumns="repeat(2,1fr)"
              >
                <Button
                  type="button"
                  colorScheme="gray"
                  onClick={onClose}
                  isDisabled={isLoading}
                >
                  {t("buttons.close")}
                </Button>
                <Button
                  type="submit"
                  isDisabled={!checkedProjects.length}
                  isLoading={isLoading}
                >
                  {t("buttons.download")}
                </Button>
              </ButtonGroup>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DownloadCsv;
