import {
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  Link,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { MobileMenu } from "@/components/layout/MobileMenu";
import { Logo } from "@/components/Logo";
import { NavItem } from "@/types";

export const Header = () => {
  const { t } = useTranslation();
  const [scrolled, setScrolled] = useState(false);
  const { pathname } = useLocation();

  const links: NavItem[] = [
    {
      href: "/",
      label: t("footer.home"),
    },
    {
      href: "/pricing",
      label: t("footer.pricing"),
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box
      w="100%"
      pos="fixed"
      top="0"
      left="0"
      zIndex={10}
      p={4}
      bg="background"
      borderBottom={scrolled ? "1px solid" : "none"}
      borderColor="muted"
      transition="all 0.3s"
    >
      <Container maxW="7xl">
        <HStack justify="space-between" gap={6}>
          <Logo />
          <HStack
            gap={8}
            ml={20}
            w="100%"
            display={{ base: "none", lg: "flex" }}
          >
            {links.map(({ href, label }) => (
              <Link
                to={href}
                as={RouterLink}
                key={label}
                fontWeight="semibold"
                color={href === pathname ? "brand.500" : "text"}
              >
                {t(label)}
              </Link>
            ))}
          </HStack>
          <Box display={{ base: "none", lg: "block" }}>
            <AccountButtons />
          </Box>
          <MobileMenu links={links}>
            <AccountButtons />
          </MobileMenu>
        </HStack>
      </Container>
    </Box>
  );
};

function AccountButtons() {
  const { t } = useTranslation();

  return (
    <ButtonGroup
      flexDirection={{ base: "column", lg: "row" }}
      w={{ base: "100%", lg: "auto" }}
      gap={2}
    >
      <Button as={RouterLink} to="/demo" variant="ghost">
        {t("get_demo")}
      </Button>
      <Button
        as={RouterLink}
        to="/app/dashboard"
        variant="outline"
        border="2px"
      >
        {t("auth.logIn")}
      </Button>
      <Button as={RouterLink} to="/auth/register">
        {t("auth.signUpFree")}
      </Button>
    </ButtonGroup>
  );
}
