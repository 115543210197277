import { Navigate, Outlet } from "react-router-dom";

import { IS_ADMIN } from "@/lib/constants";
import { useUserStore } from "@/store/user";

export function AdminGuard() {
  const { user } = useUserStore();

  if (!user || !IS_ADMIN.includes(user.role)) {
    return <Navigate to="/app/dashboard" replace />;
  }

  return <Outlet />;
}
