import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { api } from "@/lib/axios";
import { useProjectStore } from "@/store/project";

export function ProjectDelete() {
  const { project, removeProject } = useProjectStore();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const cancelRef = useRef(null);
  const navigate = useNavigate();

  const onDelete = async () => {
    setIsLoading(true);
    try {
      await api.delete(`/full-project/${project?._id}`);
      toast({
        status: "success",
        title: "Project Deleted",
      });
      removeProject();
      navigate("/app/dashboard", { replace: true });
    } catch (error) {
      toast({
        status: "error",
        title: "Error to delete project",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <Heading as="h3" size="md">
          {t("project.delete")}
        </Heading>
      </CardHeader>

      <CardBody>
        <Alert status="error">
          <AlertIcon />
          <Box>
            <AlertDescription>{t("project.remove.msg")} </AlertDescription>
          </Box>
        </Alert>
      </CardBody>
      <CardFooter justify="flex-end">
        <Button colorScheme="red" onClick={onOpen}>
          {t("delete")}
        </Button>

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          closeOnOverlayClick={!isLoading}
          closeOnEsc={!isLoading}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {t("project.remove.title")}
              </AlertDialogHeader>

              <AlertDialogBody>{t("project.remove.msg")} </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={onClose}
                  colorScheme="gray"
                  isDisabled={isLoading}
                >
                  {t("cancel")}
                </Button>
                <Button
                  colorScheme="red"
                  onClick={onDelete}
                  ml={3}
                  isLoading={isLoading}
                >
                  {t("delete")}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </CardFooter>
    </Card>
  );
}
