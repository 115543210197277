import { z } from 'zod';

export const demoSchema = z.object({
  email: z.string().email({ message: 'demo.error_email' }),
  name: z.string().min(3, { message: 'demo.name_error' }),
  lastName: z.string().min(3, { message: 'demo.last_name_error' }),
  company: z.string().min(3, { message: 'demo.name_error' }),
  companySize: z.string().min(1, { message: 'demo.company_size_error' }),
  phone: z.string(),
  message: z.string().max(250, { message: 'demo.message_error' }),
});

export type Inputs = z.infer<typeof demoSchema>;
