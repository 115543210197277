import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { GoogleButton } from "@/components/GoogleButton";
import { InputPassword } from "@/components/InputPassword";
import { api } from "@/lib/axios";
import { EXPIRE_TOKEN } from "@/lib/constants";
import { Login, login } from "@/lib/validations/auth";
import { useUserStore } from "@/store/user";
import { User } from "@/types";

export default function LoginPage() {
  const toast = useToast();
  const { setUser } = useUserStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Login>({
    resolver: zodResolver(login),
  });

  const onSubmit = async (formData: Login) => {
    try {
      const { data } = await api.post<{ token: string; user: User }>(
        "/auth/login",
        formData
      );
      Cookies.set("token", data.token, {
        expires: EXPIRE_TOKEN,
      });
      setUser(data.user);
      navigate("/app/dashboard");
    } catch (error) {
      toast({
        status: "error",
        title: "Invalid Credentials",
      });
    }
  };

  return (
    <Container as={Stack} spacing={8}>
      <Heading>{t("auth.login")}</Heading>

      <GoogleButton />
      <Text textAlign="center" color="text">
        - {t("auth.orEmail")} -
      </Text>

      <Stack as="form" spacing={4} mt={4} onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.email} isRequired>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input id="email" {...register("email")} />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.password} isRequired>
          <FormLabel htmlFor="password">{t("auth.password")}</FormLabel>
          <InputPassword id="password" {...register("password")} />
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
        <Link
          as={RouterLink}
          to="/auth/password-recover"
          color="brand.500"
          m="auto"
        >
          {t("auth.forgotPassword")}
        </Link>
        <Button isLoading={isSubmitting} type="submit">
          {t("auth.login")}
        </Button>
      </Stack>

      <HStack justify="center">
        <Text color="text">{t("auth.noAccount")}</Text>
        <Link as={RouterLink} to="/auth/register" color="brand.500">
          {t("auth.createAccount")}
        </Link>
      </HStack>
    </Container>
  );
}
