import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { api } from "@/lib/axios";
import { IS_ADMIN } from "@/lib/constants";
import { useUserStore } from "@/store/user";

export const EnableOnboarding = () => {
  const toast = useToast();
  const { user, updateUser } = useUserStore();
  const { t } = useTranslation();

  const handleOnboarding = async () => {
    try {
      await api.patch("/user", { onboarding: false });
      updateUser({ onboarding: false });
      toast({
        status: "info",
        title: t("onboarding.onboardingEnabled"),
        duration: 2000,
      });
    } catch (error) {
      toast({
        status: "error",
        title: "No se pudo habilitar el onboarding. Inténtalo de nuevo.",
      });
    }
  };

  if (IS_ADMIN.includes(user?.role ?? "")) {
    return (
      <Card as="form">
        <CardHeader>
          <Heading size="md">{t("onboarding.enableOnboarding")}</Heading>
        </CardHeader>
        <CardBody as={Stack} spacing={8}>
          <Text>{t("onboarding.repeatOnboarding")}</Text>
        </CardBody>
        <CardFooter justify="flex-end">
          <Button onClick={handleOnboarding}>{t("onboarding.enable")}</Button>
        </CardFooter>
      </Card>
    );
  }

  return null;
};
