import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { api } from '@/lib/axios';
import { useUserStore } from '@/store/user';
import { zodResolver } from '@hookform/resolvers/zod/src/zod.js';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { EXPIRE_TOKEN } from '@/lib/constants';
import { useSearchParams } from 'react-router-dom';
import { changePasswordSchema } from '@/lib/validations/auth';
import { Eye, EyeOff } from 'lucide-react';


interface FormData {
  newPassword: string;
  confirmNewPassword: string;
}
export const ChangePassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const {user} = useUserStore()
  const toast = useToast();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(changePasswordSchema),
  });

  useEffect(() => {
		const token = searchParams.get('token') || Cookies.get('token');

		if (token) {
			Cookies.set('token', token, {expires: EXPIRE_TOKEN});
		}
	}, [searchParams]);
  const onSubmit = async (formData: FormData) => {
    const email = user?.email
    const password = formData.newPassword;
    const confirm_password = formData.confirmNewPassword;
    try {
      await api.post('/auth/update-password', { email, password, confirm_password });
      toast({
        status: 'success',
        title: 'Password changed successfully',
        description: 'You can now sign in with your new password.',
      });
    } catch (error) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Something went wrong. Please try again later.',
      });
    }
  };

  const handleToggleNewPassword = () => setShowNewPassword(!showNewPassword);
const handleToggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <Card>
      <CardHeader>
        <Heading size="md">{t('auth.change_password')}</Heading>
      </CardHeader>
      <CardBody>
      <Stack spacing={4} as="form" onSubmit={handleSubmit(onSubmit)}>
  <FormControl isInvalid={!!errors.newPassword} isRequired>
    <FormLabel htmlFor="newPassword">
      {t('auth.new_password')}
    </FormLabel>
    <InputGroup>
      <Input
        id="newPassword"
        type={showNewPassword ? 'text' : 'password'}
        placeholder={t('auth.enter_new_password')}
        {...register('newPassword')}
      />
      <InputRightElement>
        <IconButton
          aria-label={showNewPassword ? 'Hide password' : 'Show password'}
          icon={showNewPassword ? <EyeOff /> : <Eye />}
          onClick={handleToggleNewPassword}
          variant="ghost"
          color="currentcolor"
        />
      </InputRightElement>
    </InputGroup>
    <FormErrorMessage>
      {errors.newPassword && errors.newPassword.message}
    </FormErrorMessage>
  </FormControl>
  <FormControl isInvalid={!!errors.confirmNewPassword} isRequired>
    <FormLabel htmlFor="confirmNewPassword">
      {t('auth.confirm_new_password')}
    </FormLabel>
    <InputGroup>
      <Input
        id="confirmNewPassword"
        type={showConfirmPassword ? 'text' : 'password'}
        placeholder={t('auth.repite_password')}
        {...register('confirmNewPassword')}
      />
      <InputRightElement>
        <IconButton
          aria-label={showConfirmPassword ? 'Hide password' : 'Show password'}
          icon={showConfirmPassword ? <EyeOff /> : <Eye />}
          onClick={handleToggleConfirmPassword}
          variant="ghost"
          color="currentcolor"
        />
      </InputRightElement>
    </InputGroup>
    <FormErrorMessage>
      {errors.confirmNewPassword && errors.confirmNewPassword.message}
    </FormErrorMessage>
  </FormControl>
  <Button isLoading={isSubmitting} maxWidth="fit-content" ml="auto" type="submit">
    {t('auth.change_password')}
  </Button>
</Stack>
      </CardBody>
    </Card>
  );
};
