import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { api } from "@/lib/axios";
import { EXPIRE_TOKEN } from "@/lib/constants";
import { Forgot, forgot } from "@/lib/validations/auth";
import { Eye, EyeOff } from "lucide-react";

export default function PasswordForgotPage() {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Forgot>({
    resolver: zodResolver(forgot),
    defaultValues: {
      email: localStorage.getItem("email-recover-password") ?? "",
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token") || Cookies.get("token");

    if (token) {
      Cookies.set("token", token, { expires: EXPIRE_TOKEN });
    }
  }, [searchParams]);

  const onSubmit = async (formData: Forgot) => {
    try {
      await api.post("/auth/reset-password", formData);
      localStorage.removeItem("email-recover-password");
      toast({
        status: "success",
        title: "Password changed",
        description: "Sign in to continue",
      });
      navigate("/auth/login");
    } catch (error) {
      toast({
        status: "error",
        title: "Invalid Credentials",
      });
    }
  };
  const handleToggleNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleToggleConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  return (
    <Container as={Stack} spacing={8}>
      <Heading>{t("auth.change_password")}</Heading>

      <Stack as="form" spacing={4} mt={4} onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.email} isRequired>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input id="email" {...register("email")} />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.password} isRequired>
          <FormLabel htmlFor="password">{t("auth.password")}</FormLabel>
          <InputGroup>
            <Input
              type={showNewPassword ? "text" : "password"}
              placeholder={t("auth.enter_new_password")}
              id="password"
              {...register("password")}
            />
            <InputRightElement>
              <IconButton
                aria-label={showNewPassword ? "Hide password" : "Show password"}
                icon={showNewPassword ? <EyeOff /> : <Eye />}
                onClick={handleToggleNewPassword}
                variant="ghost"
                color="currentcolor"
              />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.confirm_password} isRequired>
          <FormLabel htmlFor="confirm_password">
            {t("auth.confirm_password")}
          </FormLabel>
          <InputGroup>
            <Input
              id="confirm_password"
              type={showConfirmPassword ? "text" : "password"}
              placeholder={t("auth.confirm_password")}
              {...register("confirm_password")}
            />
            <InputRightElement>
              <IconButton
                aria-label={
                  showConfirmPassword ? "Hide password" : "Show password"
                }
                icon={showConfirmPassword ? <EyeOff /> : <Eye />}
                onClick={handleToggleConfirmPassword}
                variant="ghost"
                color="currentcolor"
              />
            </InputRightElement>
          </InputGroup>

          <FormErrorMessage>
            {errors.confirm_password && errors.confirm_password.message}
          </FormErrorMessage>
        </FormControl>

        <Button isLoading={isSubmitting} type="submit">
          {t("auth.change_password")}
        </Button>
      </Stack>
    </Container>
  );
}
