import { Stack } from '@chakra-ui/react';
import {
	closestCorners,
	DndContext,
	DragEndEvent,
	DragOverEvent,
	DragOverlay,
	DragStartEvent,
	PointerSensor,
	UniqueIdentifier,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';

import { Container } from '@/components/draggable/Container';
import { Item } from '@/components/draggable/SortableItem';
import { ProjectEmpty } from '@/components/project/Empty';
import { api } from '@/lib/axios';
import { useProjectStore } from '@/store/project';

export default function Project() {
	const { project } = useProjectStore();

	const { groups = [] } = project!;

	const [items, setItems] = useState<Record<string, UniqueIdentifier[]>>({});

	useEffect(() => {
		setItems(
			groups.reduce((acc: Record<string, UniqueIdentifier[]>, curr) => {
				const { _id, indicators = [] } = curr;
				if (!acc[_id]) {
					acc[_id] = indicators
						.sort((a, b) => a.order - b.order)
						.map((i) => i._id);
				}

				return acc;
			}, {})
		);
	}, [groups]);

	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 10,
			},
		})
	);

	if (!project?.groups?.length) {
		return <ProjectEmpty />;
	}

	return (
		<Stack direction="row" overflowX="auto" pb={2} spacing={6} flex="1">
			<DndContext
				sensors={sensors}
				collisionDetection={closestCorners}
				onDragStart={handleDragStart}
				onDragOver={handleDragOver}
				onDragEnd={handleDragEnd}
			>
				{Object.entries(items).map(([id, items]) => (
					<Container key={id} id={id} items={items} />
				))}
				<DragOverlay>
					{activeId ? <Item id={activeId} isOverlay /> : null}
				</DragOverlay>
			</DndContext>
		</Stack>
	);

	function findContainer(id?: UniqueIdentifier) {
		if (!id) return;
		if (id in items) {
			return id;
		}

		return Object.keys(items).find((key) => items[key].includes(id));
	}

	function handleDragStart(event: DragStartEvent) {
		const { active } = event;
		const { id } = active;

		setActiveId(id);
	}

	function handleDragOver(event: DragOverEvent) {
		const { active, over } = event;
		const { id } = active;

		// Find the containers
		const activeContainer = findContainer(id);
		const overContainer = findContainer(over?.id);

		if (
			!activeContainer ||
			!overContainer ||
			activeContainer === overContainer
		) {
			return;
		}

		setItems((prev) => {
			const activeItems = prev[activeContainer];
			const overItems = prev[overContainer];

			// Find the indexes for the items
			const activeIndex = activeItems.indexOf(id);
			const overIndex = overItems.indexOf(over!.id);

			let newIndex;
			if (over!.id in prev) {
				// We're at the root droppable of a container
				newIndex = overItems.length + 1;
			} else {
				const modifier = 0;

				newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
			}

			return {
				...prev,
				[activeContainer]: [
					...prev[activeContainer].filter((item) => item !== active.id),
				],
				[overContainer]: [
					...prev[overContainer].slice(0, newIndex),
					items[activeContainer][activeIndex],
					...prev[overContainer].slice(newIndex, prev[overContainer].length),
				],
			};
		});
	}

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event;

		const activeContainer = findContainer(active.id);
		const overContainer = findContainer(over?.id);

		if (
			!activeContainer ||
			!overContainer ||
			activeContainer !== overContainer
		) {
			return;
		}

		const activeIndex = items[activeContainer].indexOf(active.id);
		const overIndex = items[overContainer].indexOf(over!.id);

		if (activeIndex !== overIndex) {
			// Update in server
			api
				.patch(`/indicator/${active.id}`, {
					order: overIndex,
					group: overContainer,
				})
				.then((resp) => console.log(resp.status))
				.catch((err) => console.log(err));

			setItems((items) => ({
				...items,
				[overContainer]: arrayMove(
					items[overContainer],
					activeIndex,
					overIndex
				),
			}));
		}

		setActiveId(null);
	}
}
