import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Select,
	SimpleGrid,
	Stack,
	useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { api } from '@/lib/axios';
import { COUNTRIES } from '@/lib/constants';
import { account as schema } from '@/lib/validations/user';
import { useUserStore } from '@/store/user';
import { User } from '@/types';

type Schema = z.infer<typeof schema>;

export function UserAccount() {
	const { user, updateUser } = useUserStore();
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<Schema>({
		resolver: zodResolver(schema),
		defaultValues: user,
	});

	const toast = useToast();
	const { t } = useTranslation();

	const onSubmit = async (formData: Schema) => {
		try {
			const { data } = await api.patch<User>('/user', formData);
			toast({
				title: 'Account updated.',
				description: "We have saved the changes to your account.",
				status: 'success',
			});
			updateUser(data);
		} catch (error) {
			toast({
				status: 'error',
				title: 'Error',
				description: 'We could not save the changes'
			});
		}
	};

	return (
		<Card as="form" onSubmit={handleSubmit(onSubmit)}>
			<CardHeader>
				<Heading size="md">{t('account')}</Heading>
			</CardHeader>

			<CardBody as={Stack} spacing={8}>
				<SimpleGrid columns={{ base: 1, md: 2 }} gap={4}>
					<FormControl isInvalid={!!errors.name} isRequired>
						<FormLabel htmlFor="name">{t('name')}</FormLabel>
						<Input id="name" {...register('name')} />
						<FormErrorMessage>
							{errors.name && errors.name.message}
						</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={!!errors.lastName} isRequired>
						<FormLabel htmlFor="lastName">{t('lastName')}</FormLabel>
						<Input id="lastName" {...register('lastName')} />
						<FormErrorMessage>
							{errors.lastName && errors.lastName.message}
						</FormErrorMessage>
					</FormControl>
				</SimpleGrid>
				<FormControl isInvalid={!!errors.email} isRequired>
					<FormLabel htmlFor="email">{t('email.index')}</FormLabel>
					<Input id="email" {...register('email')} readOnly isDisabled />
					<FormErrorMessage>
						{errors.email && errors.email.message}
					</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={!!errors.phone} isRequired>
					<FormLabel htmlFor="phone">{t('phone')}</FormLabel>
					<Input id="phone" {...register('phone')} />
					<FormErrorMessage>
						{errors.phone && errors.phone.message}
					</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={!!errors.country} isRequired>
					<FormLabel htmlFor="country">{t('country')}</FormLabel>
					<Select placeholder={t('select_option')} {...register('country')}>
						{COUNTRIES.map((country) => (
							<option key={country} value={country}>
								{country}
							</option>
						))}
					</Select>
					<FormErrorMessage>
						{errors.country && errors.country.message}
					</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={!!errors.company} isRequired>
					<FormLabel htmlFor="company">{t('company')}</FormLabel>
					<Input id="company" {...register('company')} />
					<FormErrorMessage>
						{errors.company && errors.company.message}
					</FormErrorMessage>
				</FormControl>
			</CardBody>
			<CardFooter justify="flex-end">
				<Button isLoading={isSubmitting} type="submit">
					{t('save')}
				</Button>
			</CardFooter>
		</Card>
	);
}
