import axios, { AxiosError, AxiosInstance } from "axios";
import Cookies from "js-cookie";

import { env } from "@/env/client";

/**
 * Create an axios instance
 * @type {AxiosInstance}
 * @example
 * import { api } from '@/utils/axios';
 * api.get('/users');
 * api.post('/users', { name: 'John Doe' });
 * // etc...
 */
export const api: AxiosInstance = axios.create({
  baseURL: env.VITE_API_URL,
});

/**
 * Set the token in the header
 */
api.interceptors.request.use(
  async (config) => {
    const token = Cookies.get("token");

    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const url = error.response?.config.url;
    if (
      error.response &&
      [401].includes(error.response.status) &&
      !url?.includes("auth")
    ) {
      Cookies.remove("token");
      localStorage.removeItem("user");
      return location.reload();
    }
    return Promise.reject(error);
  }
);
