import {
	Button,
	Container,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Stack,
	useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { InputPassword } from '@/components/InputPassword';
import { api } from '@/lib/axios';
import { EXPIRE_TOKEN } from '@/lib/constants';
import { validToken } from '@/lib/utils';
import { Confirm, confirm } from '@/lib/validations/auth';
import { useUserStore } from '@/store/user';
import { User } from '@/types';

export default function ConfirmEmail() {
	const toast = useToast();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { setUser } = useUserStore();
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<Confirm>({
		resolver: zodResolver(confirm),
		defaultValues: {
			token: searchParams.get('token') || '',
		},
	});

	useEffect(() => {
		const token = searchParams.get('token');
		if (!validToken(token)) {
			navigate('/', { replace: false });
		}
	}, [navigate, searchParams]);

	const onSubmit = async (formData: Confirm) => {
		try {
			const { data } = await api.post<{ token: string; user: User }>(
				'/auth/confirm-email',
				formData
			);
			Cookies.set('token', data.token, {
				expires: EXPIRE_TOKEN,
			});
			setUser(data.user);
			navigate('/app/dashboard');
		} catch (error) {
			toast({
				status: 'error',
				title: 'Invalid Credentials',
			});
		}
	};

	return (
		<Container>
			<Heading>Confirmar</Heading>

			<Stack as="form" spacing={4} mt={4} onSubmit={handleSubmit(onSubmit)}>
				<FormControl isInvalid={!!errors.email} isRequired>
					<FormLabel htmlFor="email">Email</FormLabel>
					<Input id="email" {...register('email')} />
					<FormErrorMessage>
						{errors.email && errors.email.message}
					</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={!!errors.password} isRequired>
					<FormLabel htmlFor="password">password</FormLabel>
					<InputPassword id="password" {...register('password')} />
					<FormErrorMessage>
						{errors.password && errors.password.message}
					</FormErrorMessage>
				</FormControl>
				<Button mt={4} isLoading={isSubmitting} type="submit">
					Submit
				</Button>
			</Stack>
		</Container>
	);
}
