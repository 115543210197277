import { RouteObject } from "react-router-dom";

import { PublicLayout } from "@/layouts/PublicLayout";
import Contact from "@/pages/contact";
import ErrorPage from "@/pages/error-pages";
import Home from "@/pages/home/Home";
import Pricing from "@/pages/pricing/Pricing";

import Demo from "../pages/demo";

export const root: RouteObject[] = [
  {
    path: "/",
    element: <PublicLayout />,
    ErrorBoundary: ErrorPage,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "demo",
        element: <Demo />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "contact-us",
        element: <Contact />,
      },
    ],
  },
];
