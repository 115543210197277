import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ChevronDown } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { fetchExport } from "@/lib/utils";
import { useIndicatorStore } from "@/store/indicator";
import { useProjectStore } from "@/store/project";
import { useUserStore } from "@/store/user";

const currentYear = new Date().getFullYear();

export default function TablePage() {
  const { project } = useProjectStore();
  const { user } = useUserStore();
  const { setIndicator } = useIndicatorStore();
  const [year, setYear] = useState(currentYear);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const toast = useToast();

  const handleExport = async (type: string) => {
    try {
      setIsLoading(true);

      const filename = `${project?.name}-${year}`;
      await fetchExport(
        `/export/project/${project?._id}/${year}/${type}`,
        filename
      );
      toast({
        title: "Success",
        description: "Exported successfully",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Something went wrong",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box minH="100vh">
      <HStack justify="flex-end" align="center">
        <Select
          placeholder="Select year"
          defaultValue={year}
          w="auto"
          size="sm"
          value={year}
          onChange={(e) => setYear(Number(e.target.value))}
        >
          {[currentYear - 2, currentYear - 1, currentYear].map((year) => (
            <option value={year} key={year}>
              {year}
            </option>
          ))}
        </Select>
        <Menu>
          <MenuButton
            as={Button}
            size="sm"
            rightIcon={<ChevronDown size={16} />}
            isLoading={isLoading}
          >
            {t("export")}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => handleExport("csv")}>CSV</MenuItem>
            <MenuItem onClick={() => handleExport("xlsx")}>XLSX</MenuItem>
          </MenuList>
        </Menu>
      </HStack>
      <Stack spacing={10}>
        {project?.groups.map((group) => (
          <Stack key={`table-column-${group._id}`} pb={20}>
            <Heading as="h2" fontSize="2xl">
              {group.name}
            </Heading>
            {group.indicators.length ? (
              <TableContainer>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>{t("indicator.index")}</Th>
                      <Th>{t("responsible.index")}</Th>
                      <Th>{t("project.hashtags.hashtags")}</Th>
                      <Th>{t("indicator.lastValue")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {group.indicators.map((indicator) => (
                      <Tr
                        key={indicator._id}
                        onClick={() => setIndicator(indicator)}
                        _hover={{ bg: "muted" }}
                        role="button"
                      >
                        <Td>{indicator.name}</Td>
                        <Td>{indicator.responsible}</Td>
                        <Td>
                          {indicator.responsible === user?.email && (
                            <Tag colorScheme="blue" size="sm">
                              {t("responsible.index")}
                            </Tag>
                          )}
                          {indicator.paused && (
                            <Tag colorScheme="orange" size="sm">
                              {t("paused")}
                            </Tag>
                          )}
                        </Td>

                        {indicator.lastValue ? (
                          <Td>
                            {t(`months.${indicator.lastValue?.month}`)}{" "}
                            {String(indicator.lastValue?.year)}
                            <Text
                              fontWeight="bold"
                              fontSize="md"
                              display="inline"
                              ml={4}
                            >
                              {indicator.lastValue?.value}
                            </Text>
                          </Td>
                        ) : (
                          <Td />
                        )}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Center bg="lightenBackground" h="75px">
                <Text>Create indicator</Text>
              </Center>
            )}
          </Stack>
        ))}
      </Stack>
      {!project?.groups.length && (
        <VStack spacing={8} textAlign="center" px={[3, 5, 10]} m="auto">
          <Text fontWeight="bold">{t("empty.indicatorsTitle")}</Text>
          <Text fontSize="sm" color="gray.400">
            {t("empty.indicatorsMsg")}
          </Text>
        </VStack>
      )}
    </Box>
  );
}
