import Cookies from "js-cookie";
import { Navigate, Outlet } from "react-router-dom";

import { AppLayout } from "@/layouts/AppLayout";
import { IS_ADMIN } from "@/lib/constants";
import { OnboardingContainer } from "@/pages/app/onboarding/OnboardingContainer";
import { useUserStore } from "@/store/user";

export function AuthGuard() {
  const { user } = useUserStore();

  if (!user || !Cookies.get("token")) {
    return <Navigate to="/auth/login" replace />;
  }

  if (!user.onboarding && IS_ADMIN.includes(user.role)) {
    return <OnboardingContainer />;
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
}
