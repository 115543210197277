import {
	Alert,
	AlertDescription,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	AlertIcon,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Heading,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from '@/lib/axios';
import { useProjectStore } from '@/store/project';

export function ProjectPause() {
	const { updateProject, project } = useProjectStore();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [isLoading, setIsLoading] = useState(false);
	const toast = useToast();
	const cancelRef = useRef(null);

	const togglePause = async () => {
		setIsLoading(true);
		const newPaused = !project?.paused_at;

		try {
			const pausedAtValue = newPaused ? new Date() : null;
			await api.patch(`/project/${project?._id}`, {
				paused_at: pausedAtValue,
			});

			updateProject({ ...project, paused_at: pausedAtValue });

			onClose();
			toast({
				title: newPaused ? t('project.paused') : t('project.resumed'),
			});
		} catch (error) {
			toast({
				status: 'error',
				title: 'Failed update project',
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Card>
			<CardHeader>
				<Heading as="h3" size="md">
					{project?.paused_at ? t('project.resume') : t('project.pause')}
				</Heading>
			</CardHeader>

			<CardBody>
				<Alert status="info">
					<AlertIcon />
					<AlertDescription>
						{project?.paused_at
							? t('project.messages.resume')
							: t('project.messages.pause')}
					</AlertDescription>
				</Alert>
			</CardBody>
			<CardFooter justify="flex-end">
				<Button
					onClick={onOpen}
					colorScheme={project?.paused_at ? 'brand' : 'orange'}
				>
					{project?.paused_at ? t('resume') : t('pause')}
				</Button>
				<AlertDialog
					isOpen={isOpen}
					leastDestructiveRef={cancelRef}
					onClose={onClose}
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								{project?.paused_at ? t('project.resume') : t('project.pause')}
							</AlertDialogHeader>
							<AlertDialogBody>{t('project.messages.confirm')}</AlertDialogBody>
							<AlertDialogFooter>
								<Button ref={cancelRef} onClick={onClose} colorScheme="gray">
									{t('cancel')}
								</Button>
								<Button
									onClick={togglePause}
									isLoading={isLoading}
									ml={3}
									colorScheme={project?.paused_at ? 'brand' : 'orange'}
								>
									{project?.paused_at ? t('resume') : t('pause')}
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</CardFooter>
		</Card>
	);
}
