import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { GoogleButton } from "@/components/GoogleButton";
import { InputPassword } from "@/components/InputPassword";
import { api } from "@/lib/axios";
import { CreateAccount, createAccount } from "@/lib/validations/auth";

export default function RegisterPage() {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateAccount>({
    resolver: zodResolver(createAccount),
  });
  const { t } = useTranslation();
  const [registered, setRegistered] = useState(false);

  const onSubmit = async (formData: CreateAccount) => {
    try {
      await api.post("/auth/register", formData);
      setRegistered(true);
    } catch (error) {
      let title = "Failed to create your account";

      if (error instanceof AxiosError) {
        title = error.response?.data.message;
      }

      toast({
        status: "error",
        title,
      });
    }
  };

  if (registered) {
    return (
      <Container>
        <Stack align="center" maxW="md" spacing={8}>
          <Image src="/send.png" alt="email send" w="150px" />
          <Heading textAlign="center">{t("auth.confirm.title")}</Heading>
          <Text>{t("auth.confirm.description")}</Text>
        </Stack>
      </Container>
    );
  }

  return (
    <Container as={Stack} spacing={8}>
      <div>
        <Heading>{t("auth.createAccount")}</Heading>

        <HStack>
          <Text color="text">{t("auth.haveAccount")}</Text>
          <Link as={RouterLink} to="/auth/login" color="brand.500">
            {t("auth.login")}
          </Link>
        </HStack>
      </div>
      <GoogleButton />
      <Text textAlign="center" color="text">
        - {t("auth.orEmail")} -
      </Text>

      <Stack as="form" mt={4} onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.name} isRequired>
          <FormLabel htmlFor="name">{t("name")}</FormLabel>
          <Input id="name" {...register("name")} />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.lastName} isRequired>
          <FormLabel htmlFor="lastName">{t("lastName")}</FormLabel>
          <Input id="lastName" {...register("lastName")} />
          <FormErrorMessage>
            {errors.lastName && errors.lastName.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.email} isRequired>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input id="email" type="email" {...register("email")} />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.password} isRequired>
          <FormLabel htmlFor="password">{t("auth.password")}</FormLabel>
          <InputPassword id="password" {...register("password")} />
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
        <Button mt={4} isLoading={isSubmitting} type="submit">
          {t("auth.createAccount")}
        </Button>
      </Stack>
    </Container>
  );
}
