import { Heading, Spinner, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { api } from '@/lib/axios';
import { useUserStore } from '@/store/user';

export default function Unsubscribed() {
	const [isLoading, setIsLoading] = useState(true);
	const { user } = useUserStore();

	useEffect(() => {
		if (!user || !user.emails?.indicator) {
			setIsLoading(false);
			return;
		}

		api.post('/user/unsubscribe').finally(() => setIsLoading(false));
	}, [user]);

	if (!user) {
		return <Navigate to="/auth/login" replace />;
	}

	return (
		<Stack justify="center" align="center" h="70vh">
			{isLoading ? <Spinner /> : <Heading>You've been unsubscribed.</Heading>}
		</Stack>
	);
}
