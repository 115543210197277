import { create } from 'zustand';

import { Indicator } from '@/types';

interface IndicatorState {
	indicator?: Indicator;
	setIndicator: (payload?: Indicator) => void;
	removeIndicator: () => void;
}

export const useIndicatorStore = create<IndicatorState>()((set) => ({
	indicator: undefined,
	setIndicator: (payload) => set(() => ({ indicator: payload })),
	removeIndicator: () => set(() => ({ indicator: undefined })),
}));
