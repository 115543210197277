import {
  Flex,
  Heading,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Emails from './components/Emails';
import IndicatorsEmails from './components/IndicatorsEmails';

const currentYear = new Date().getFullYear();

export default function EmailsTab() {
  const { t } = useTranslation();
  const [year, setYear] = useState('0');

  const handleTabChange = () => {
    setYear('0');
  };

  return (
    <Stack spacing={8}>
      <Flex justify='space-between'>
        <Heading>{t('email.emails')}</Heading>
        <Select w='auto' value={year} onChange={(e) => setYear(e.target.value)}>
          <option value='0'>{t('all')}</option>
          <option value={currentYear}>{currentYear}</option>
          <option value={currentYear - 1}>{currentYear - 1}</option>
          <option value={currentYear - 2}>{currentYear - 2}</option>
        </Select>
      </Flex>
      <Tabs isLazy onChange={handleTabChange}>
        <TabList>
          <Tab>
            <Heading fontSize='xl'> {t('email.general')}</Heading>
          </Tab>
          <Tab>
            <Heading fontSize='xl'>{t('indicator.indicators')} </Heading>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel css={{ paddingLeft: 0, paddingRight: 0 }}>
            {' '}
            <Emails year={year} />
          </TabPanel>
          <TabPanel css={{ paddingLeft: 0, paddingRight: 0 }}>
            {' '}
            <IndicatorsEmails year={year} />{' '}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
