import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { MoreHorizontal, Send } from "lucide-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { EmailLogs } from "@/components/indicator/EmailLogs";
import { IndicatorInformation } from "@/components/indicator/Information";
import { ValueLogs } from "@/components/indicator/ValueLogs";
import { Values } from "@/components/indicator/Values";
import { api } from "@/lib/axios";
import { CAN_RESEND_EMAIL } from "@/lib/constants";
import { useIndicatorStore } from "@/store/indicator";
import { useProjectStore } from "@/store/project";
import { useUserStore } from "@/store/user";

import { CopyModal } from "./Copy";
import { Pause } from "./Pause";
import { RemoveModal } from "./Remove";

export const UpdateIndicator = () => {
  const { indicator, removeIndicator } = useIndicatorStore();
  const { t } = useTranslation();
  const toast = useToast();
  const [isResending, setIsResending] = useState(false);

  const { user } = useUserStore();
  const { project } = useProjectStore();

  const canResendEmail = useMemo(() => {
    if (!user || !project) return false;

    if (user.role === "super-admin" || user.role === "admin") return true;

    const userMember = project.members.find(
      (member) => member.email === user.email
    );

    if (!userMember) return false;

    const userRole = userMember.role;

    return CAN_RESEND_EMAIL.includes(userRole);
  }, [user, project]);

  if (!indicator) return null;

  const resendEmail = async () => {
    setIsResending(true);
    try {
      await api.post(`/responsible/${indicator._id}/resend-email`);
      toast({
        status: "success",
        title: "Email resent successfully",
        duration: 2000,
      });
    } catch (error) {
      toast({
        status: "error",
        title: "Failed to resend email",
        duration: 2000,
      });
    } finally {
      setIsResending(false);
    }
  };

  return (
    <Drawer placement="right" size="lg" onClose={removeIndicator} isOpen>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Stack flexDirection="row" justifyContent="space-between" mr={6}>
            <Heading size="md">{indicator.name}</Heading>
            <Popover>
              <PopoverTrigger>
                <IconButton
                  aria-label="More options"
                  icon={<MoreHorizontal />}
                  variant="ghost"
                  colorScheme="gray"
                  size="xs"
                />
              </PopoverTrigger>
              <PopoverContent maxW="200px" mx={4}>
                <PopoverHeader fontSize="xs">{t("options")}</PopoverHeader>
                <PopoverBody as={Stack} px={1}>
                  <Pause indicator={indicator} />
                  {canResendEmail && (
                    <Button
                      justifyContent="flex-start"
                      leftIcon={<Send size={16} />}
                      variant="ghost"
                      colorScheme="gray"
                      isLoading={isResending}
                      size="xs"
                      iconSpacing={3}
                      ml={1}
                      onClick={resendEmail}
                    >
                      {t("resend")}
                    </Button>
                  )}
                  <CopyModal indicator={indicator} />
                </PopoverBody>
                <PopoverFooter px={1}>
                  <RemoveModal
                    indicator={indicator}
                    onClose={removeIndicator}
                  />
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </Stack>
          <Text fontSize="xs" color="gray">
            {t("created_at")} {dayjs(indicator.createdAt).format("MM-DD-YYYY")}
          </Text>
        </DrawerHeader>

        <Tabs as={DrawerBody} colorScheme="brand" isLazy isFitted>
          <TabList>
            <Tab>{t("values")}</Tab>
            <Tab>{t("information")}</Tab>
            <Tab>{t("indicator.history_changes")}</Tab>
            <Tab>{t("indicator.email_events")}</Tab>
          </TabList>

          <TabPanels h="calc(100% - 75px)">
            <TabPanel h="100%" px={0}>
              <Values onClose={removeIndicator} indicator={indicator} />
            </TabPanel>
            <TabPanel h="100%" px={0}>
              <IndicatorInformation
                onClose={removeIndicator}
                indicator={indicator}
              />
            </TabPanel>
            <TabPanel px={0}>
              <ValueLogs indicator={indicator} />
            </TabPanel>
            <TabPanel px={0}>
              <EmailLogs indicator={indicator} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </DrawerContent>
    </Drawer>
  );
};
