import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { Check, X } from "lucide-react";
import { useTranslation } from "react-i18next";

import { plans } from "@/lib/constants";

const PlansTable = () => {
  const { t } = useTranslation();

  return (
    <Table bg="muted" variant="striped" minW="auto" my={12}>
      <Thead>
        <Tr>
          <Th></Th>
          {plans.map(({ plan: { type }, color }, index) => (
            <Th
              key={index}
              borderTop={color}
              style={{ width: "25%" }}
              textAlign="center"
            >
              {type}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        <TableRow title={t("pricing.titles.cost")}>
          {plans.map(({ plan: { cost, description } }, index) => (
            <Td key={index} textAlign="center" style={{ width: "25%" }}>
              {cost}
              <Text>{t(`pricing.plans.${description}`)} </Text>
            </Td>
          ))}
        </TableRow>

        <TableRow title={t("pricing.titles.users")}>
          {plans.map(({ plan: { users } }, index) => (
            <Td key={index} textAlign="center" style={{ width: "25%" }}>
              {typeof users === "number" ? users : t(`pricing.plans.${users}`)}
            </Td>
          ))}
        </TableRow>
        <TableRow title={t("pricing.titles.recipients")}>
          {plans.map(({ plan: { recipients } }, index) => (
            <Td key={index} textAlign="center" style={{ width: "25%" }}>
              {typeof recipients === "number"
                ? recipients
                : t(`pricing.plans.${recipients}`)}
            </Td>
          ))}
        </TableRow>
        <TableRow title={t("pricing.titles.projects")}>
          {plans.map(({ plan: { projects } }, index) => (
            <Td key={index} textAlign="center" style={{ width: "25%" }}>
              {typeof projects === "number"
                ? projects
                : t(`pricing.plans.${projects}`)}
            </Td>
          ))}
        </TableRow>
        <TableRow title={t("pricing.titles.support")}>
          {plans.map(({ plan: { support } }, index) => (
            <Td key={index} textAlign="center" style={{ width: "25%" }}>
              {t(`pricing.plans.${support}`)}
            </Td>
          ))}
        </TableRow>
        <TableRow title={t("pricing.titles.push")}>
          {plans.map(({ plan: { push } }, index) => (
            <Td textAlign="center" key={index} style={{ width: "25%" }}>
              {push ? (
                <Check style={{ display: "inline-block" }} color="green" />
              ) : (
                <X style={{ display: "inline-block" }} color="red" />
              )}
            </Td>
          ))}
        </TableRow>
        <TableRow title={t("pricing.titles.branding")}>
          {plans.map(({ plan: { branding }, desc_branding }, index) => (
            <Td textAlign="center" key={index} style={{ width: "25%" }}>
              {branding ? (
                <Check
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  color="green"
                />
              ) : (
                <X
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  color="red"
                />
              )}{" "}
              {desc_branding && t(`pricing.plans.${desc_branding}`)}
            </Td>
          ))}
        </TableRow>
        <TableRow title={t("pricing.titles.whatsapp_sms")}>
          {plans.map(({ plan: { whatsapp_SMS }, desc_notif }, index) => (
            <Td textAlign="center" key={index} style={{ width: "25%" }}>
              {whatsapp_SMS ? (
                <Check
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  color="green"
                />
              ) : (
                <X
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  color="red"
                />
              )}{" "}
              {desc_notif && t(`pricing.plans.${desc_notif}`)}
            </Td>
          ))}
        </TableRow>
      </Tbody>
    </Table>
  );
};

const TableRow = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <Tr>
    <Td fontWeight="bold">{title}</Td>
    {children}
  </Tr>
);

export default PlansTable;
