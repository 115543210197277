import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Heading,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Select as ChakraSelect,
	SimpleGrid,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select } from 'chakra-react-select';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { api } from '@/lib/axios';
import { createArray } from '@/lib/utils';
import { summary } from '@/lib/validations/project';
import { useProjectStore } from '@/store/project';
import { Project } from '@/types';

const SHOW_MONTHS = ['custom', 'annual'];

type Schema = z.infer<typeof summary>;

export function ProjectSummary() {
	const { project, updateProject } = useProjectStore();
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
		setValue,
		control,
	} = useForm<Schema>({
		resolver: zodResolver(summary),
		defaultValues: {
			days: project?.launchDate?.days || [],
			months: project?.launchDate?.months || [],
			isPreviousMonth: project?.launchDate?.isPreviousMonth || false,
			ofType: project?.launchDate?.ofType || 'never',
		},
	});
	const toast = useToast();
	const [days, setDays] = useState<number[]>(project?.launchDate?.days || []);

	const type = useWatch({
		control,
		name: 'ofType',
	});

	const { t } = useTranslation();

	const onSubmit = async (formData: Schema) => {
		try {
			const { data } = await api.patch<Project>(`/project/${project?._id}`, {
				launchDate: {
					...formData,
					days,
				},
			});
			updateProject(data);
			toast({
				status: 'success',
				title: 'Project Updated',
			});
		} catch (error) {
			toast({
				status: 'error',
				title: 'Error to update project',
			});
		}
	};

	return (
		<Card as="form" onSubmit={handleSubmit(onSubmit)}>
			<CardHeader>
				<Heading as="h3" size="md">
					{t('project.summary.title')}
				</Heading>
				<Text>{t('project.summary.description')}</Text>
			</CardHeader>
			<CardBody as={Stack} spacing={8}>
				<FormControl isInvalid={!!errors?.ofType} isRequired>
					<FormLabel htmlFor="ofType">{t('indicator.request_data')}</FormLabel>
					<ChakraSelect {...register('ofType')}>
						<option value="never">{t('never')}</option>
						<option value="monthly">{t('monthly')}</option>
						<option value="annual">{t('annual')}</option>
						<option value="custom">{t('custom')}</option>
					</ChakraSelect>
					<FormHelperText>{t(`indicator.messages.${type}`)}</FormHelperText>
					<FormErrorMessage>{errors.ofType?.message}</FormErrorMessage>
				</FormControl>

				{type !== 'never' && (
					<>
						<FormControl>
							<FormLabel htmlFor="ofType">{t('select_days')}</FormLabel>
							<Popover placement="bottom-start">
								<PopoverTrigger>
									<Button
										w="full"
										size="md"
										colorScheme="gray"
										variant="outline"
										justifyContent="flex-start"
									>
										{!days.length ? t('select_days') : days.join(', ')}
									</Button>
								</PopoverTrigger>
								<PopoverContent>
									<PopoverBody>
										<SimpleGrid columns={5} gap={1}>
											{createArray(31).map((day) => (
												<Button
													key={`day-${day}`}
													colorScheme={days.includes(day) ? 'brand' : 'gray'}
													variant={days.includes(day) ? 'solid' : 'outline'}
													onClick={() => {
														if (days.includes(day)) {
															setDays(days.filter((d) => d !== day));
														} else {
															if (days.length === 5) return;
															setDays([...days, day]);
														}
													}}
												>
													{day}
												</Button>
											))}
										</SimpleGrid>
									</PopoverBody>
								</PopoverContent>
							</Popover>
						</FormControl>
						{SHOW_MONTHS.includes(type) && (
							<FormControl isInvalid={!!errors.months}>
								<FormLabel>{t('select_months')}</FormLabel>
								<Select
									options={createArray(12).map((i) => ({
										value: i,
										label: t(`months.${i}`),
									}))}
									defaultValue={project?.launchDate?.months?.map((m) => ({
										value: m,
										label: t(`months.${m}`),
									}))}
									onChange={(option) => {
										if (!option) return;
										if ('value' in option) {
											setValue('months', [option.value]);
											return;
										}
										setValue(
											'months',
											option?.map((v) => v.value)
										);
									}}
									isMulti={type === 'custom'}
								/>
							</FormControl>
						)}
						<FormControl isInvalid={!!errors?.isPreviousMonth}>
							<Checkbox {...register('isPreviousMonth')}>
								{t('indicator.messages.previous_month')}
							</Checkbox>
						</FormControl>
					</>
				)}
			</CardBody>
			<CardFooter justify="flex-end">
				<Button isLoading={isSubmitting} type="submit">
					{t('save')}
				</Button>
			</CardFooter>
		</Card>
	);
}
