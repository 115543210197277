import { ReactNode, useEffect, useMemo, useState } from 'react';
import Joyride, { Step } from 'react-joyride';

import BeaconComponent from '@/components/tour/BeaconComponent';
import Tooltip from '@/components/tour/Tooltip';

const joyrideStyles = {
  options: {
    zIndex: 10000,
  },
};

export default function useTour(
  steps: Step[],
  userOnboarding: boolean | undefined
): ReactNode {
  const [run, setRun] = useState(false);

  useEffect(
    function () {
      !userOnboarding ? setRun(true) : setRun(false);
    },
    [userOnboarding]
  );

  const tour = useMemo<ReactNode>(
    () => (
      <Joyride
        beaconComponent={BeaconComponent}
        tooltipComponent={Tooltip}
        continuous
        run={run}
        showProgress
        steps={steps}
        styles={joyrideStyles}
      />
    ),
    [steps, run]
  );

  return tour;
}
