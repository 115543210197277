import { Heading, Stack, Text, VStack } from '@chakra-ui/react';
import { PlusIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { CreateGroup } from '@/components/group/Create';

export function ProjectEmpty() {
	const { t } = useTranslation();

	return (
		<Stack minH="50vh" justify="center" align="center">
			<VStack textAlign="center" spacing={4}>
				<Heading size="sm">{t('project.empty.title')}</Heading>
				<Text fontSize="sm" color="gray">
					{t('project.empty.description')}
				</Text>
				<CreateGroup leftIcon={<PlusIcon size={14} />}>
					{t('group.create')}
				</CreateGroup>
			</VStack>
		</Stack>
	);
}
