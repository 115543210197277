import { extendTheme, ThemeOverride } from '@chakra-ui/react';

const base: ThemeOverride = {
	config: {
		initialColorMode: 'light',
		useSystemColorMode: false,
	},
	colors: {
		brand: {
			50: '#dce6fd',
			100: '#dce6fd',
			200: '#c0d4fd',
			300: '#95b9fb',
			400: '#6494f6',
			500: '#3f6ff2',
			600: '#2e53e7',
			700: '#213cd4',
			800: '#2132ac',
			900: '#202f88',
		},
	},
	semanticTokens: {
		colors: {
			background: {
				default: 'white',
				_dark: 'gray.800',
			},
			muted: {
				default: 'whitesmoke',
				_dark: 'gray.900',
			},
			'muted.hover': {
				default: 'gray.50',
				_dark: 'gray.700',
			},
		},
	},
	components: {
		Button: {
			defaultProps: {
				colorScheme: 'brand',
			},
		},
	},
};

export default extendTheme(base);
