import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { api } from "@/lib/axios";
import { member } from "@/lib/validations/project";
import { useProjectStore } from "@/store/project";
import { Project } from "@/types";

const schema = z.object({
  name: z.string().min(3),
  description: z.string(),
  members: z.array(member).optional(),
});

type Schema = z.infer<typeof schema>;

interface CreateProjectProps {
  onStepComplete: () => void;
}

export const CreateProject = ({ onStepComplete }: CreateProjectProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      members: [],
    },
  });

  const toast = useToast();
  const { t } = useTranslation();
  const { setProject } = useProjectStore();

  const onSubmit = async (formData: Schema) => {
    const payload = {
      ...formData,
      members: [],
    };
    try {
      const response = await api.post<Project>("/project", payload);
      setProject(response.data);
      toast({
        status: "info",
        title: "Proyecto creado",
        duration: 2000,
      });
      onStepComplete();
    } catch (error) {
      toast({
        status: "error",
        title: "Error to create project",
      });
    } finally {
      reset();
    }
  };

  return (
    <Card
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w={{ base: "100%", md: "70%" }}
    >
      <CardHeader>
        <Heading as="h3" size="lg">
          {t("onboarding.createYourProject")}
        </Heading>
      </CardHeader>
      <CardBody as={Stack} spacing={8}>
        <FormControl isInvalid={!!errors.name}>
          <FormLabel htmlFor="name">{t("name")}</FormLabel>
          <Input id="name" {...register("name")} />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="description">{t("description")}</FormLabel>
          <Textarea id="description" {...register("description")} />
        </FormControl>
      </CardBody>
      <CardFooter>
        <Flex justify="flex-end" w="100%">
          <Button isLoading={isSubmitting} type="submit" w="100px">
            {t("create")}
          </Button>
        </Flex>
      </CardFooter>
    </Card>
  );
};
