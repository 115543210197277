import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverTrigger,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import dayjs from "dayjs";
import { List, MailWarningIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

import { useIndicatorStore } from "@/store/indicator";
import { useProjectStore } from "@/store/project";
import { useUserStore } from "@/store/user";

import { ItemOptions } from "../indicator/ItemOptions";

interface Props {
  id: UniqueIdentifier;
  isOverlay?: boolean;
}

export function Item({ id, isOverlay }: Props) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { project } = useProjectStore();
  const { setIndicator } = useIndicatorStore();
  const { user } = useUserStore();
  const indicator = project?.groups
    .flatMap((g) => g.indicators)
    .find((i) => i._id === id);
  const { t } = useTranslation();

  if (!indicator) return null;

  const { responsible, paused, name, request_data, updatedAt, lastValue } =
    indicator;

  return (
    <Card
      maxW="xs"
      opacity={isOverlay ? 0.4 : 1}
      onClick={() => setIndicator(indicator)}
      role="group"
      onContextMenu={(e) => {
        e.preventDefault();
        onToggle();
      }}
    >
      <CardHeader
        p={1}
        as={HStack}
        spacing={4}
        borderBottom="1.5px solid"
        borderColor="gray"
        justifyContent="space-between"
      >
        <Flex align="center" justify="space-between" w="full" gap={2}>
          <Stack direction="row">
            {responsible === user?.email && (
              <Tag colorScheme="blue" size="sm">
                {t("responsible.index")}
              </Tag>
            )}
            {paused && (
              <Tag colorScheme="orange" size="sm">
                {t("paused")}
              </Tag>
            )}
          </Stack>
          <Popover isOpen={isOpen} onClose={onClose} placement="right">
            <PopoverTrigger>
              <IconButton
                aria-label="Edit indicator"
                opacity={{ base: 1, md: 0 }}
                _groupHover={{ opacity: 1 }}
                variant="ghost"
                size="xs"
                colorScheme="gray"
                icon={<List size={16} />}
                onClick={(e) => {
                  e.stopPropagation();
                  onToggle();
                }}
              />
            </PopoverTrigger>

            <ItemOptions indicator={indicator} />
          </Popover>
        </Flex>
      </CardHeader>
      <CardBody as={HStack} justifyContent="space-between" p={2}>
        <Flex flexDirection="column">
          <Tooltip label={name}>
            <Heading as="h4" size="sm" noOfLines={1}>
              {name}
            </Heading>
          </Tooltip>
          <Tooltip label={responsible}>
            <Text color="gray" fontSize="sm" noOfLines={1}>
              {responsible.length > 30
                ? responsible.slice(0, 30) + "..."
                : responsible}
            </Text>
          </Tooltip>
          {request_data?.ofType === "never" && (
            <Tooltip label={t("messages.never_request_mail")}>
              <Icon as={MailWarningIcon} color={"yellow.400"} />
            </Tooltip>
          )}
          <Text color="gray" fontSize="xs">
            {dayjs().to(dayjs(updatedAt))}
          </Text>
        </Flex>
        {lastValue && (
          <Tag flexDirection="column" p={2} fontSize="xs" minW="fit-content">
            {t(`shortMonths.${lastValue?.month}`)}{" "}
            {String(lastValue?.year).slice(-2)}
            <Text fontWeight="bold" fontSize="md">
              {lastValue?.value}
            </Text>
          </Tag>
        )}
      </CardBody>
    </Card>
  );
}

export function SortableItem({ id }: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Item id={id} />
    </div>
  );
}
