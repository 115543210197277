import {
  Button,
  Center,
  Container,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useRouteError } from "react-router-dom";

import { AppLayout } from "@/layouts/AppLayout";
import { PublicLayout } from "@/layouts/PublicLayout";

export default function ErrorPage() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const error = useRouteError();
  console.log(error);
  const isApp = pathname.includes("/app");

  return (
    <Layout isApp={isApp}>
      <Container minH="100vh">
        <Center>
          <Stack
            align="center"
            maxW="md"
            spacing={8}
            textAlign="center"
            mt={12}
          >
            <Image src="/sad.png" alt="email send" w="150px" />
            <div>
              <Heading>{t("error.title")}</Heading>
              <Text>{t("error.description")}</Text>
            </div>
            <Button as={Link} to={isApp ? "/app/dashboard" : "/"}>
              {t("goToHome")}
            </Button>
          </Stack>
        </Center>
      </Container>
    </Layout>
  );
}

function Layout({ children, isApp }: PropsWithChildren<{ isApp: boolean }>) {
  if (isApp) {
    return <AppLayout>{children}</AppLayout>;
  }

  return <PublicLayout>{children}</PublicLayout>;
}
