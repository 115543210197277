import { Heading, Stack, Text } from "@chakra-ui/react";
import { Check } from "lucide-react";
import { useTranslation } from "react-i18next";

import PlanCards from "./components/PlansCards";
import PlansTable from "./components/Table";

const Pricing = () => {
  const { t } = useTranslation();
  return (
    <Stack align="center" textAlign="center" spacing={6} m="auto">
      <Stack gap={5}>
        <Heading as="h1" my={2} size="2xl">
          {t("pricing.explore.title")}
        </Heading>
        <Text fontSize="lg">{t("pricing.explore.text1")}</Text>
        <Text fontSize="lg">{t("pricing.explore.text2")}</Text>
        <PlanCards />
      </Stack>
      <Stack mt={2} gap={5}>
        <Heading as="h2" my={2} size="2xl">
          {t("pricing.whyChoose.title")}
        </Heading>
        <Text textAlign="center" fontSize="lg">
          {t("pricing.whyChoose.subtitle")}
        </Text>
        <Stack spacing={3}>
          <Text>
            <Check
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "5px",
              }}
              color="green"
            />

            {t("pricing.whyChoose.flexibility")}
          </Text>
          <Text>
            <Check
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "5px",
              }}
              color="green"
            />
            {t("pricing.whyChoose.saveTime")}
          </Text>
        </Stack>
        <PlansTable />
      </Stack>
    </Stack>
  );
};
export default Pricing;
