import {
  Container,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

//import PlansTable from '@/pages/pricing/components/Table';
import { useUserStore } from '@/store/user';

import Billing from './Billing';
import MyPlan from './MyPlan';
const ManagePlan = () => {
  const { user } = useUserStore();
  const { t } = useTranslation();
  console.log(user?.role);
  return (
    <Container maxW='4xl' as={Stack} spacing={12}>
      <Tabs>
        <TabList>
          <Tab> {t('settings_profile.plan_overview')}</Tab>
          <Tab> {t('settings_profile.billing')} </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {/* <PlansTable /> */}
            <MyPlan />
          </TabPanel>
          <TabPanel>
            <Billing />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default ManagePlan;
