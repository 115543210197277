import { Circle, Flex, Link, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { Globe, Mail } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { Logo } from "@/components/Logo";
import LangSelect from "@/components/user/LangSelect";

interface Props {
  to: string;
  label: string;
  isExternal?: boolean;
}

export const Footer = () => {
  const { t } = useTranslation();

  const menuLinks = [
    {
      title: "pages",
      links: [
        { to: "/", label: "footer.home" },
        {
          to: "https://racimollc.us/",
          label: "footer.about",
          isExternal: true,
        },
        { to: "/pricing", label: "footer.pricing" },
      ],
    },
    {
      title: "company",
      links: [
        {
          to: "mailto:hello@racimollc.us",
          label: "footer.support",
        },
        {
          to: "mailto:hello@racimollc.us",
          label: "footer.press",
        },
        {
          to: "/contact-us",
          label: "footer.contact",
        },
      ],
    },
    {
      title: "account",
      links: [
        {
          to: "/app/dashboard",
          label: "auth.logIn",
        },
        {
          to: "/auth/register",
          label: "auth.register",
        },
        {
          to: "/demo",
          label: "get_demo",
        },
      ],
    },
  ];

  return (
    <VStack
      borderY="1px solid"
      borderColor="muted"
      pt="50px"
      spacing="40px"
      pb={5}
      align="stretch"
      w="full"
    >
      <SimpleGrid columns={[2]} spacing={8} w="full">
        <Flex justifyContent="flex-start">
          <Logo />
        </Flex>
        <Flex
          justifyContent={{ base: "flex-start", sm: "flex-end" }}
          alignItems="center"
        >
          <Circle size="40px" color="brand.500">
            <Globe size={20} />
          </Circle>
          <LangSelect />
        </Flex>
      </SimpleGrid>
      <SimpleGrid columns={[1, 2, 4, 4]} spacing={8} w="full">
        {menuLinks.map(({ title, links }) => (
          <VStack key={title} align="flex-start">
            <Text fontWeight="bold">{t(title)}</Text>
            {links.map(({ to, label, isExternal }, index) => (
              <FooterLink
                key={index}
                to={to}
                label={label}
                isExternal={isExternal}
              />
            ))}
          </VStack>
        ))}
        <VStack align="flex-start">
          <Circle size="40px" bg="brand.500" color="white">
            <Mail size={24} />
          </Circle>
          <Text>{t("footer.message")}</Text>
          <RouterLink to="mailto:hello@racimollc.us">
            <Text color="brand.500" fontWeight="bold">
              hello@racimollc.us
            </Text>
          </RouterLink>
        </VStack>
      </SimpleGrid>
      <Text color="gray" fontSize="sm" alignSelf="center" textAlign="center">
        Copyright © {new Date().getFullYear()} Octopush.{" "}
        {t("footer.rights_reserved")}.
      </Text>
    </VStack>
  );
};

const FooterLink = ({ to, label, isExternal = false }: Props) => {
  const { t } = useTranslation();

  if (isExternal) {
    return (
      <Link href={to} isExternal _hover={{ color: "brand.500" }}>
        <Text>{t(label)}</Text>
      </Link>
    );
  } else {
    return (
      <RouterLink to={to}>
        <Text _hover={{ color: "brand.500" }}>{t(label)}</Text>
      </RouterLink>
    );
  }
};
