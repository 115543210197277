import {
  Container,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ChangePassword } from '@/components/profile/Changepassword';
import { UserAccount } from '@/components/user/Account';
import { EmailPreferences } from '@/components/user/EmailPreferences';
import { EnableDashboardTour } from '@/components/user/EnableDashboardTour';
import { EnableOnboarding } from '@/components/user/EnableOnboarding';

export default function Profile() {
  const { t } = useTranslation();
  return (
    <Container maxW='4xl' as={Stack} spacing={12}>
      <Tabs>
        <TabList marginBottom={4}>
          <Tab>{t('settings_profile.account')}</Tab>
          <Tab>{t('settings_profile.change_password')}</Tab>
          <Tab>{t('settings_profile.notifications')}</Tab>
          <Tab>{t('settings_profile.aditional_settings')}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>         
              <UserAccount />        
          </TabPanel>
          <TabPanel>
            <ChangePassword />
          </TabPanel>
          <TabPanel>
            <EmailPreferences />
          </TabPanel>
          <TabPanel >
          <Stack gap={4}>
            <EnableDashboardTour />
            <EnableOnboarding />
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
}
