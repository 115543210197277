import {
	Button,
	ButtonProps,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IndicatorInformation } from '@/components/indicator/Information';

export const CreateIndicator = forwardRef<HTMLButtonElement, ButtonProps>(
	(props, ref) => {
		const { isOpen, onOpen, onClose } = useDisclosure();
		const { t } = useTranslation();

		return (
			<>
				<Button ref={ref} {...props} onClick={onOpen} />
				<Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
					<DrawerOverlay />
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader>{t('indicator.create')}</DrawerHeader>

						<DrawerBody>
							<IndicatorInformation onClose={onClose} />
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			</>
		);
	}
);
