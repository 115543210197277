import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { Share } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


const currentYear = new Date().getFullYear();

interface Props {
	type: 'users' | 'projects';
}

export function ExportModal({ type }: Props) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { t } = useTranslation();
	const toast = useToast();
	const [year, setYear] = useState('0');
	const [isLoading, setIsLoading] = useState(false);

	async function handleExport() {
		setIsLoading(true);
		try {
			let url = `${import.meta.env.VITE_API_URL}/admin/${type}/export`;

			if (year !== '0') {
				url += `?year=${year}`;
			}

			fetch(url, {
				headers: {
					Authorization: `Bearer ${Cookies.get('token')}`,
				},
			})
				.then((response) => {
					if (response.ok) {
						return response.blob();
					}

					throw new Error('Network response was not ok.');
				})
				.then((blob) => {
					const url = URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${type}.csv`);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					URL.revokeObjectURL(url);
					onClose();
				})
				.catch((error) => {
					console.error(error);
					alert('There has been a problem with your fetch operation');
				})
				.finally(() => {
					setIsLoading(false);
				});
		} catch (error) {
			toast({
				title: 'Error',
				status: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<>
			<Button onClick={onOpen}>{t('export')}</Button>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('export')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>{t('year')}</Text>
						<Select defaultValue="0" onChange={(e) => setYear(e.target.value)}>
							<option value="0">{t('all')}</option>
							<option value={currentYear}>{currentYear}</option>
							<option value={currentYear - 1}>{currentYear - 1}</option>
							<option value={currentYear - 2}>{currentYear - 2}</option>
						</Select>
					</ModalBody>

					<ModalFooter>
						<Button
							colorScheme="gray"
							mr={3}
							onClick={onClose}
							isDisabled={isLoading}
						>
							{t('cancel')}
						</Button>
						<Button
							leftIcon={<Share size={16} />}
							onClick={handleExport}
							isLoading={isLoading}
						>
							{t('export')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
