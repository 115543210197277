import { RouteObject } from 'react-router-dom';

import { AdminGuard } from '@/guards/AdminGuard';
import { AdminLayout } from '@/layouts/AdminLayout';
import EmailsTab from '@/pages/admin/EmailsTab';
import Projects from '@/pages/admin/Projects';
import Users from '@/pages/admin/Users';
export const admin: RouteObject = {
  path: '/admin',
  element: (
    <AdminLayout>
      <AdminGuard />
    </AdminLayout>
  ),
  children: [
    {
      path: 'projects',
      element: <Projects />,
    },
    {
      path: 'users',
      element: <Users />,
    },
    {
      path: 'emails',
      element: <EmailsTab />,
    },
  ],
};
