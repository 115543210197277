import {
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  Portal,
  Stack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Indicator } from "@/types";

import { CopyModal } from "./Copy";
import { Pause } from "./Pause";
import { RemoveModal } from "./Remove";

interface ItemOptionsProps {
  indicator: Indicator;
  onClose?: () => void;
}

export const ItemOptions = ({ indicator, onClose }: ItemOptionsProps) => {
  const { t } = useTranslation();

  const handleClose = onClose || (() => {});

  return (
    <Portal>
      <PopoverContent maxW="200px">
        <PopoverHeader fontSize="xs">{t("options")}</PopoverHeader>
        <PopoverBody as={Stack} px={1}>
          <Pause indicator={indicator} />
          <CopyModal indicator={indicator} />
          <RemoveModal indicator={indicator} onClose={handleClose} />
        </PopoverBody>
      </PopoverContent>
    </Portal>
  );
};
