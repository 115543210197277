import Cookies from "js-cookie";

export function validToken(token?: string | null) {
  if (!token) return false;

  return token.match(
    /^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-\\/=]*)/
  );
}

export function createArray(cantidad: number): number[] {
  return Array.from({ length: cantidad }, (_, i) => i + 1);
}

export const fetchExport = async (
  url: string,
  filename: string
): Promise<void> => {
  return fetch(import.meta.env.VITE_API_URL + url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.blob();
      }

      throw new Error("Network response was not ok.");
    })
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      if (link) {
        link.href = url;
        link.setAttribute("download", filename);
        document.body?.appendChild(link);
        link.click();
        document.body?.removeChild(link);
      }

      URL.revokeObjectURL(url);
    });
};
