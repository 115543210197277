import { RouteObject } from 'react-router-dom';

import { ProjectGuard } from '@/guards/ProjectGuard';
import { ResponsibleGuard } from '@/guards/ResponsibleGuard';
import { PublicLayout } from '@/layouts/PublicLayout';
import Project from '@/pages/app/project';
import ProjectsList from '@/pages/responsible/Projects';

export const responsible: RouteObject = {
	path: '/responsible',
	element: (
		<ResponsibleGuard>
			<PublicLayout />
		</ResponsibleGuard>
	),
	children: [
		{
			path: 'indicators',
			element: <ProjectsList />,
		},
		{
			path: 'project',
			element: <ProjectGuard />,
			children: [
				{
					path: ':id',
					element: <Project />,
				},
			],
		},
	],
};
