import admin from './admin.json';
import auth from './auth.json';
import common from './common.json';
import demo from './demo.json';
import download from './download.json';
import email from './email.json';
import error from './error.json';
import footer from './footer.json';
import group from './group.json';
import home from './home.json';
import indicator from './indicator.json';
import navbar from './navbar.json';
import onboarding from './onboarding.json';
import pagination from './pagination.json';
import pricing from './pricing.json';
import project from './project.json';
import responsible from './responsible.json';
import tour from './tour.json';
import settings_profile from './settings_profile.json';

const translation = {
  ...common,
  auth,
  admin,
  home,
  navbar,
  responsible,
  project,
  group,
  indicator,
  email,
  pagination,
  download,
  error,
  onboarding,
  pricing,
  demo,
  footer,
  tour,
  settings_profile,
};

export default translation;
