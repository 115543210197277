import { Center, IconButton, Image, SimpleGrid } from '@chakra-ui/react';
import { XIcon } from 'lucide-react';
import { Link, Outlet } from 'react-router-dom';

import AuthKanban from '@/assets/kanban.svg';


export function AuthLayout() {
	return (
		<SimpleGrid
			columns={{ base: 1, md: 2 }}
			h="100vh"
			gap={8}
			position="relative"
		>
			<Center display={{ base: 'none', md: 'flex' }}>
				<Image src={AuthKanban} alt="kanban image" />
			</Center>
			<Center>
				<Outlet />
			</Center>

			<IconButton
				as={Link}
				to="/"
				icon={<XIcon size={20} />}
				aria-label="Go to home"
				colorScheme="gray"
				variant="outline"
				position="absolute"
				top={2}
				right={2}
			/>
		</SimpleGrid>
	);
}
