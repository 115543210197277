import { z } from 'zod';

export const account = z.object({
	name: z.string().min(3),
	lastName: z.string().min(3),
	email: z.string().email(),
	phone: z.string().min(10, 'Phone number is not valid'),
	country: z.string(),
	company: z.string().min(3),
});

export const emailPreferences = z.object({
	indicator: z.boolean().default(true),
});
