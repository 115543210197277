import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Switch,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from '@/lib/axios';
import { useProjectStore } from '@/store/project';
import { Indicator } from '@/types';

interface UpdateModalProps {
	indicator: Indicator;
}

export const Pause = ({ indicator }: UpdateModalProps) => {
	const { updateIndicator } = useProjectStore();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isLoading, setIsLoading] = useState(false);
	// const cancelRef = React.useRef()
	const cancelRef = useRef(null);
	const toast = useToast();

	const handleTogglePause = async () => {
		setIsLoading(true);
		const newPausedState = !indicator.paused;
		try {
			await api.patch(`/indicator/${indicator._id}`, {
				paused: newPausedState,
			});

			updateIndicator({ ...indicator, paused: newPausedState });

			onClose();
			toast({
				title: newPausedState
					? t('indicator.messages.paused')
					: t('indicator.messages.resumed'),
			});
		} catch (error) {
			toast({
				status: 'error',
				title: 'Failed update indicator',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const onOpenModalClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onOpen();
	};

	return (
		<>
			<Button
				justifyContent="flex-start"
				leftIcon={<Switch size="sm" isChecked={indicator.paused} />}
				variant="ghost"
				size="xs"
				colorScheme="gray"
				onClick={onOpenModalClick}
			>
				{indicator.paused ? t('indicator.resume') : t('indicator.pause')}
			</Button>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{indicator.paused ? t('indicator.resume') : t('indicator.pause')}
						</AlertDialogHeader>

						<AlertDialogBody>
							{indicator.paused
								? t('indicator.messages.resume')
								: t('indicator.messages.pause')}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose} colorScheme="gray">
								{t('cancel')}
							</Button>
							<Button
								colorScheme={indicator.paused ? 'brand' : 'red'}
								onClick={handleTogglePause}
								isLoading={isLoading}
								ml={3}
							>
								{indicator.paused ? t('resume') : t('pause')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};
