import { RouteObject } from 'react-router-dom';

import { AuthLayout } from '@/layouts/AuthLayout';
import ConfirmEmail from '@/pages/auth/ConfirmEmail';
import LoginPage from '@/pages/auth/Login';
import PasswordForgotPage from '@/pages/auth/PasswordForgot';
import PasswordRecoverPage from '@/pages/auth/PasswordRecover';
import RegisterPage from '@/pages/auth/Register';

export const auth: RouteObject = {
	path: '/auth',
	element: (
		<AuthLayout />
	),
	children: [
		{
			path: 'login',
			element: <LoginPage />,
		},
		{
			path: 'register',
			element: <RegisterPage />,
		},
		{
			path: 'confirm-email',
			element: <ConfirmEmail />,
		},
		{
			path: 'password-recover',
			element: <PasswordRecoverPage />
		},
		{
			path: 'forgot-password',
			element: <PasswordForgotPage />
		}
	],
};
