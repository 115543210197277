import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { forwardRef, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { api } from "@/lib/axios";
import { useProjectStore } from "@/store/project";
import { Group } from "@/types";

interface Props extends ButtonProps {
  groupId: Group["_id"];
}

export const DeleteGroup = forwardRef<HTMLButtonElement, Props>(
  ({ groupId, ...props }, ref) => {
    const { removeGroup } = useProjectStore();
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const cancelRef = useRef(null);
    const toast = useToast();

    const handleDelete = async () => {
      setIsLoading(true);
      try {
        await api.delete(`/group/${groupId}`);
        removeGroup(groupId);
        onClose();
      } catch (error) {
        toast({
          status: "error",
          title: "Failed remove group",
        });
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <>
        <Button ref={ref} {...props} onClick={onOpen} />
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {t("group.delete")}
              </AlertDialogHeader>

              <AlertDialogBody>{t("group.msg")}</AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={onClose}
                  isDisabled={isLoading}
                  colorScheme="gray"
                >
                  {t("cancel")}
                </Button>
                <Button
                  colorScheme="red"
                  onClick={handleDelete}
                  isLoading={isLoading}
                  ml={3}
                >
                  {t("delete")}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  }
);
