import {
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	IconButton,
	Stack,
	useDisclosure,
} from '@chakra-ui/react';
import { Menu } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { NavItem } from '@/types';

interface Props {
	links: NavItem[];
	children?: React.ReactNode;
}

export function MobileMenu({ links, children }: Props) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { pathname } = useLocation();
	const { t } = useTranslation();

	return (
		<>
			<IconButton
				colorScheme="gray"
				variant="ghost"
				aria-label="Open menu"
				icon={<Menu size={20} />}
				onClick={onOpen}
				display={{ base: 'flex', lg: 'none' }}
			/>
			<Drawer isOpen={isOpen} placement="right" onClose={onClose}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Menu</DrawerHeader>

					<DrawerBody>
						<Stack>
							{links.map(({ href, label }) => (
								<Button
									as={Link}
									to={href}
									key={label}
									textTransform="capitalize"
									variant="ghost"
									colorScheme={pathname === href ? 'brand' : 'gray'}
									isActive={pathname === href}
									justifyContent="flex-start"
								>
									{t(label)}
								</Button>
							))}
						</Stack>
					</DrawerBody>
					<DrawerFooter>{children}</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
}
