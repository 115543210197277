import {
  Button,
  Container,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import styles from "./home.module.css";

const Home = () => {
  const { t } = useTranslation();

  return (
    <Container maxW="6xl" pb={12}>
      {/* Top Content */}
      <VStack textAlign="center" my={20} spacing={10}>
        <Heading size="4xl" maxW="2xl" as="h2">
          {t("home.title")}{" "}
          <div className={styles.words}>
            <span>{t("home.time")}</span>
            <span>{t("home.money")}</span>
            <span>{t("home.kpi")}</span>
            <span>{t("home.performance")}</span>
            <span>{t("home.processes")}</span>
            <span>{t("home.management")}</span>
          </div>
        </Heading>
        <Text maxW="2xl" fontSize="xl">
          {t("home.description")}
        </Text>
        <Button as={RouterLink} to="/app/dashboard" maxW="md">
          {t("home.getStarted")}
        </Button>
      </VStack>

      {/* Logos */}

      <Stack align="center" my={36}>
        <Heading as="h3" size="md" textAlign="center">
          {t("home.organizationsTrustUs")}
        </Heading>
        {/* !TODO: Logos */}
      </Stack>

      {/* Main Contnet */}
      <Stack spacing={32} as="section">
        <Stack
          align="center"
          textAlign="center"
          spacing={5}
          maxW={["90%", "75%"]}
          m="auto"
        >
          <Heading as="h3" size="2xl">
            {t("home.workgroup.title")}
          </Heading>
          <Text fontSize="lg">{t("home.workgroup.text")}</Text>
        </Stack>
        <Stack align="flex-start" spacing={10}>
          <Heading as="h3">{t("home.work.title")}</Heading>
          <List spacing={3}>
            <ListItem>
              <ListIcon fontSize={18} />
              {t("home.work.task")}
            </ListItem>
            <ListItem>
              <ListIcon fontSize={22} />
              {t("home.work.audience")}
            </ListItem>
            <ListItem>{t("home.work.visualize")}</ListItem>
            <ListItem>
              <ListIcon fontSize={22} />
              {t("home.work.analyze")}
            </ListItem>
          </List>
          <Button as={RouterLink} to="/app/dashboard">
            {t("home.tryItNow")}
          </Button>
        </Stack>
        <Stack align="flex-end" spacing={10}>
          <Heading as="h3">{t("home.advantages.title")}</Heading>
          <List spacing={3}>
            <ListItem>{t("home.advantages.decentralized")}</ListItem>
            <ListItem>{t("home.advantages.access")}</ListItem>
            <ListItem>{t("home.advantages.compatible")}</ListItem>
            <ListItem>{t("home.advantages.contact")}</ListItem>
          </List>
          <Button as={RouterLink} to="/app/dashboard">
            {t("home.learnMore")}
          </Button>
        </Stack>

        <Stack align="center" textAlign="center" spacing={5}>
          <Heading as="h3" size="3xl">
            {t("home.security.title")}
          </Heading>
          <Text fontSize="lg">{t("home.security.text")}</Text>
          <Button as={RouterLink} to="/app/dashboard">
            {t("home.exploreOctopush")}
          </Button>
        </Stack>
      {/* pricing */}

      <Stack spacing={32} as="section">
        <Stack
          align="center"
          textAlign="center"
          spacing={5}
          maxW={["90%", "75%"]}
          m="auto"
        >
          <Heading as="h3" size="2xl">
            {t("home.planSection.title")}
          </Heading>
          <Text fontSize="lg">{t("home.planSection.description")}</Text>
          <Button as={RouterLink} to="/pricing">
            {t("home.planSection.view")}
          </Button>
        </Stack>
			  </Stack>
		
      </Stack>
    </Container>
  );
};

export default Home;
