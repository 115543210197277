import {
  ButtonGroup,
  Heading,
  HStack,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { GroupIcon, PlusIcon } from "lucide-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { CreateGroup } from "@/components/group/Create";
import { CreateIndicator } from "@/components/indicator/Create";
import { UpdateIndicator } from "@/components/indicator/Update";
import { CAN_EDIT } from "@/lib/constants";
import { useProjectStore } from "@/store/project";
import { useUserStore } from "@/store/user";

interface Props {
  children: React.ReactNode;
}

export default function ProjectLayout({ children }: Props) {
  const { user } = useUserStore();
  const { project } = useProjectStore();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const canEdit = useMemo(
    () =>
      !pathname.includes("responsible") &&
      project?.members.some(
        (member) =>
          member.email === user?.email && CAN_EDIT.includes(member.role)
      ),
    [user, project, pathname]
  );

  return (
    <Stack spacing={8} minH="90vh">
      <HStack
        justify="space-between"
        alignItems={{ base: "flex-start", md: "center" }}
        flexDirection={{ base: "column", md: "row" }}
        gap={6}
      >
        <Link as={RouterLink} to={`/app/project/${project?._id}`}>
          <Text fontSize="xs">{t("project.name")}</Text>
          <Heading size="lg">{project?.name}</Heading>
        </Link>

        <HStack spacing={6}>
          <Link as={RouterLink} to={`/app/project/${project?._id}`}>
            {t("overview")}
          </Link>
          <Link as={RouterLink} to={`/app/project/${project?._id}/table`}>
            {t("table")}
          </Link>
          <Link as={RouterLink} to={`/app/project/${project?._id}/settings`}>
            {t("settings")}
          </Link>
        </HStack>

        <div>
          {canEdit && (
            <ButtonGroup size="sm">
              <CreateIndicator leftIcon={<PlusIcon size={14} />}>
                {t("indicator.create")}
              </CreateIndicator>
              <CreateGroup
                colorScheme="gray"
                variant="outline"
                leftIcon={<GroupIcon size={14} />}
              >
                {t("group.create")}
              </CreateGroup>
            </ButtonGroup>
          )}
        </div>
      </HStack>
      {children}
      <UpdateIndicator />
    </Stack>
  );
}
