import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { Copy } from 'lucide-react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { api } from '@/lib/axios';
import { useProjectStore } from '@/store/project';
import { Indicator } from '@/types';

interface IndicatorProps {
	indicator: Indicator;
}

interface FormData {
	name: string;
	group: string;
}

export const CopyModal = ({ indicator }: IndicatorProps) => {
	const { project, addIndicator } = useProjectStore();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const defaultGroupOption = project?.groups.find(
		(group) => group._id === indicator.group
	);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isSubmitting },
	} = useForm<FormData>({
		defaultValues: {
			name: indicator.name,
			group: defaultGroupOption?._id,
		},
	});

	const onSubmit = async (data: FormData) => {
		try {
			const newIndicatorData = {
				name: data.name,
				group: data.group,
				project: indicator.project,
				responsible: indicator.responsible,
				request_data: indicator.request_data,
				// paused: indicator.paused,
			};
			const response = await api.post<Indicator>(
				'/indicator',
				newIndicatorData
			);
			const newIndicator = response.data;

			addIndicator(newIndicator);

			toast({ title: t('indicator.messages.created') });
			onClose();
		} catch (error) {
			toast({ title: t('indicator.messages.error_create'), status: 'error' });
		}
	};

	const onOpenCopyModalClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onOpen();
	};

	return (
		<>
			<Button
				justifyContent="flex-start"
				leftIcon={<Copy size={16} />}
				variant="ghost"
				size="xs"
				colorScheme="gray"
				iconSpacing={3}
				ml={1}
				onClick={onOpenCopyModalClick}
			>
				{t('indicator.copy')}
			</Button>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<form onSubmit={handleSubmit(onSubmit)}>
						<ModalHeader>{t('indicator.copy')}</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<FormControl isInvalid={!!errors.name} isRequired>
								<FormLabel htmlFor="name">{t('title')}</FormLabel>
								<Input
									id="name"
									{...register('name', { required: true })}
									placeholder={t('placeholder.name')}
								/>
								<FormErrorMessage>{errors.name?.message}</FormErrorMessage>
							</FormControl>

							<FormControl mt={4} isRequired>
								<FormLabel>{t('group.index')}</FormLabel>
								<Controller
									name="group"
									control={control}
									render={({ field }) => (
										<Select
											options={project?.groups.map((group) => ({
												label: group.name,
												value: group._id,
											}))}
											defaultValue={
												defaultGroupOption && {
													label: defaultGroupOption.name,
													value: defaultGroupOption._id,
												}
											}
											onChange={(val) => field.onChange(val?.value)}
										/>
									)}
								/>
							</FormControl>
						</ModalBody>

						<ModalFooter>
							<Button
								variant="outline"
								mr={3}
								onClick={onClose}
								isDisabled={isSubmitting}
								colorScheme="gray"
							>
								{t('cancel')}
							</Button>
							<Button isDisabled={isSubmitting} type="submit">
								{t('indicator.create')}
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>
		</>
	);
};
