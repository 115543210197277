import {
  Card,
  CardBody,
  CardHeader,
  Circle,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Check, Megaphone, MessageCircle } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <VStack textAlign="center" my={10} spacing={10}>
      <Heading as="h3" size="2xl">
        {t("footer.contact_message.title")}
      </Heading>
      <Text maxW="2xl" fontSize="xl">
        {t("footer.contact_message.description")}
      </Text>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
        <Card px={12}>
          <CardHeader>
            <Stack justify="center" alignItems="center" mb={4}>
              <Circle size="50px" bg="brand.500" color="white">
                <Check size={30} />
              </Circle>
            </Stack>
            <Heading size="md">General</Heading>
          </CardHeader>
          <CardBody>
            <Link to="mailto:hello@racimollc.us">hello@racimollc.us</Link>
          </CardBody>
        </Card>
        <Card px={12}>
          <CardHeader>
            <Stack justify="center" alignItems="center" mb={4}>
              <Circle size="50px" bg="brand.500" color="white">
                <MessageCircle size={30} />
              </Circle>
            </Stack>
            <Heading size="md">{t("footer.support")}</Heading>
          </CardHeader>
          <CardBody>
            <Link to="mailto:hello@racimollc.us">
              {t("footer.technical_assistance")}
            </Link>
          </CardBody>
        </Card>
        <Card px={12}>
          <CardHeader>
            <Stack justify="center" alignItems="center" mb={4}>
              <Circle
                size="50px"
                bg="white"
                borderWidth="2px"
                borderColor="brand.500"
                color="brand.500"
              >
                <Megaphone size={30} />
              </Circle>
            </Stack>
            <Heading size="md">{t("footer.press")}</Heading>
          </CardHeader>
          <CardBody>
            <Link to="mailto:hello@racimollc.us">{t("footer.contact")}</Link>
          </CardBody>
        </Card>
      </SimpleGrid>
    </VStack>
  );
}
