import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Stack,
	Textarea,
	useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { api } from '@/lib/axios';
import { project } from '@/lib/validations/project';
import { useProjectStore } from '@/store/project';
import { Project } from '@/types';

const schema = project.omit({members:true});
type Schema = z.infer<typeof schema>;


export function ProjectInformation() {
	const { project, updateProject } = useProjectStore();
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<Schema>({
		resolver: zodResolver(schema),
		defaultValues: {
			name: project?.name,
			description: project?.description,
		},
	});

	const toast = useToast();
	const { t } = useTranslation();

	const onSubmit = async (formData: Schema) => {
		try {
			const { data } = await api.patch<Project>(
				`/project/${project?._id}`,
				formData
			);
			updateProject(data);
			toast({
				status: 'success',
				title: 'Project Updated',
			});
		} catch (error) {
			toast({
				status: 'error',
				title: 'Error to update project',
			});
		}
	};

	return (
		<Card as="form" onSubmit={handleSubmit(onSubmit)}>
			<CardHeader>
				<Heading as="h3" size="md">
					{t('information')}
				</Heading>
			</CardHeader>

			<CardBody as={Stack} spacing={8}>
				<FormControl isInvalid={!!errors.name} isRequired>
					<FormLabel htmlFor="name">{t('name')}</FormLabel>
					<Input id="name" {...register('name')} />
					<FormErrorMessage>
						{errors.name && errors.name.message}
					</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={!!errors.description}>
					<FormLabel htmlFor="description">{t('description')}</FormLabel>
					<Textarea id="description" {...register('description')} />
					<FormErrorMessage>
						{errors.description && errors.description.message}
					</FormErrorMessage>
				</FormControl>
			</CardBody>
			<CardFooter justify="flex-end">
				<Button isLoading={isSubmitting} type="submit">
					{t('save')}
				</Button>
			</CardFooter>
		</Card>
	);
}
