import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Select as ReactSelect } from "chakra-react-select";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { createArray } from "@/lib/utils";
import { Indicator } from "@/types";

const schema = z.object({
  name: z.string().min(3),
  description: z.string(),
  project: z.string().min(3),
  group: z.string().min(3),
  responsible: z.string().email().optional().or(z.literal("")),
  request_data: z.object({
    isPreviousMonth: z.boolean().default(false),
    ofType: z.string().min(3).default("never"),
    days: z.array(z.number()).optional(),
    months: z.array(z.number()).optional(),
  }),
});

type Schema = z.infer<typeof schema>;

const SHOW_CALENDLY = ["monthly", "annual", "custom"];
const SHOW_MONTHS = ["custom", "annual"];

interface Props {
  control: Control<Schema>;
  register: UseFormRegister<Schema>;
  type: string;
  days: number[];
  setDays: (days: number[]) => void;
  errors: FieldErrors<Schema>;
}

interface IndicatorProps {
  indicator: Indicator;
}

export function DateSelect(
  { control, register, type, days, setDays, errors }: Props,
  { indicator }: IndicatorProps
) {
  const { t } = useTranslation();

  return (
    <>
      <FormControl isInvalid={!!errors.request_data?.ofType} isRequired>
        <FormLabel htmlFor="ofType">{t("indicator.request_data")}</FormLabel>
        <Select {...register("request_data.ofType")}>
          <option value="never">{t("never")}</option>
          <option value="monthly">{t("monthly")}</option>
          <option value="annual">{t("annual")}</option>
          <option value="custom">{t("custom")}</option>
        </Select>
        {type && (
          <FormHelperText>{t(`indicator.messages.${type}`)}</FormHelperText>
        )}
        {type === "never" && (
          <Text color="red.500" fontSize="sm" fontWeight="bold">
            {t("indicator.messages.never_option_mail")}
          </Text>
        )}
        <FormErrorMessage>
          {errors.request_data?.ofType && errors.request_data?.ofType.message}
        </FormErrorMessage>
      </FormControl>

      {type !== "never" && (
        <>
          <FormControl>
            <FormLabel htmlFor="days">{t("select_days")}</FormLabel>
            <Popover placement="bottom-start">
              <PopoverTrigger>
                <Button
                  isDisabled={!SHOW_CALENDLY.includes(type)}
                  w="full"
                  size="md"
                  colorScheme="gray"
                  variant="outline"
                  justifyContent="flex-start"
                >
                  {!days.length ? t("select_days") : days.join(", ")}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverBody>
                  <SimpleGrid columns={5} gap={1}>
                    {createArray(31).map((day) => (
                      <Button
                        key={`day-${day}`}
                        colorScheme={days.includes(day) ? "brand" : "gray"}
                        variant={days.includes(day) ? "solid" : "outline"}
                        onClick={() => {
                          if (days.includes(day)) {
                            setDays(days.filter((d) => d !== day));
                          } else {
                            if (days.length === 5) return;
                            setDays([...days, day]);
                          }
                        }}
                      >
                        {day}
                      </Button>
                    ))}
                  </SimpleGrid>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </FormControl>

          {SHOW_MONTHS.includes(type) && (
            <FormControl isInvalid={!!errors.request_data?.months}>
              <FormLabel htmlFor="months">{t("select_months")}</FormLabel>
              <Controller
                name="request_data.months"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    options={createArray(12).map((m) => ({
                      value: m,
                      label: t(`months.${m}`),
                    }))}
                    value={field.value?.map((m) => ({
                      value: m,
                      label: t(`months.${m}`),
                    }))}
                    defaultValue={indicator?.request_data?.months?.map((m) => ({
                      value: m,
                      label: t(`months.${m}`),
                    }))}
                    onChange={(option) => {
                      if (!option) {
                        field.onChange([]);
                        return;
                      }
                      if (Array.isArray(option)) {
                        field.onChange(option.map((v) => v.value));
                      } else {
                        if ("value" in option) {
                          field.onChange([option.value]);
                        }
                      }
                    }}
                    isMulti={type === "custom"}
                  />
                )}
              />
            </FormControl>
          )}

          <FormControl isRequired={!!errors.request_data?.isPreviousMonth}>
            <Checkbox {...register("request_data.isPreviousMonth")}>
              {t("indicator.messages.previous_month")}
            </Checkbox>
          </FormControl>
        </>
      )}
    </>
  );
}
