import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { api } from "@/lib/axios";
import { ROLES } from "@/lib/constants";
import { member as schema } from "@/lib/validations/project";
import { useProjectStore } from "@/store/project";
import { Project } from "@/types";

type Schema = z.infer<typeof schema>;

interface AddMemberProps {
  onStepComplete: () => void;
}

export const AddMember = ({ onStepComplete }: AddMemberProps) => {
  const { project, updateProject } = useProjectStore();
  const [members, setMembers] = useState<Schema[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const toast = useToast();
  const { t } = useTranslation();

  const onSubmit = async (formData: Schema) => {
    const emailLowerCase = formData.email.toLowerCase();
    const exist = members.some((m) => m.email === emailLowerCase);
    if (!exist) {
      setMembers([...members, { ...formData, email: emailLowerCase }]);
      reset();
    } else {
      toast({
        title: "Miembro existente",
        description: "Este miembro ya ha sido agregado.",
        status: "warning",
        duration: 3000,
      });
      reset();
    }
  };

  const onNext = async () => {
    setIsLoading(true);
    if (members.length) {
      try {
        const response = await api.patch<Project>(`/project/${project?._id}`, {
          members,
        });
        updateProject(response.data);
        toast({
          status: "info",
          title: "Miembro agregado",
          duration: 2000,
        });
        onStepComplete();
      } catch (error) {
        toast({
          status: "error",
          title: "Error to add member",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      onStepComplete();
    }
  };

  return (
    <VStack spacing={4} w="100%">
      <Card
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        w={{ base: "100%", md: "70%" }}
      >
        <CardBody as={Stack} spacing={8}>
          <Heading as="h3" size="lg">
            {t("onboarding.addMemberToProject")}
          </Heading>
          <Stack>
            <FormControl isInvalid={!!errors.email} size="sm">
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input id="email" size="sm" {...register("email")} />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.role} size="sm">
              <FormLabel htmlFor="role">{t("role")}</FormLabel>
              <Select
                id="role"
                size="sm"
                placeholder="Select role"
                {...register("role")}
              >
                {ROLES.map((role) => (
                  <option value={role} key={role}>
                    {role}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.role && errors.role.message}
              </FormErrorMessage>
              <Flex mt={4}>
                <Controller
                  control={control}
                  name="canReceiveEmail"
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      id="canReceiveEmail"
                      isChecked={value}
                      onChange={onChange}
                    >
                      {t("onboarding.receiveSummary")}
                    </Checkbox>
                  )}
                />
              </Flex>
            </FormControl>
          </Stack>
        </CardBody>
        <CardFooter>
          <Flex justify="flex-end" w="100%">
            <Button type="submit" w="100px">
              {t("add")}
            </Button>
          </Flex>
        </CardFooter>
        {!!members.length && (
          <>
            <Heading as="h4" size="sm" mt={3} pl="20px">
              {t("members")}
            </Heading>
            <TableContainer>
              <Table mt={3}>
                <Thead>
                  <Tr>
                    <Th>Email</Th>
                    <Th>{t("receive_summary")}</Th>
                    <Th>{t("role")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {members.map(({ email, canReceiveEmail, role }, index) => (
                    <Tr key={index}>
                      <Td>{email}</Td>
                      <Td>
                        <Switch isChecked={canReceiveEmail} />
                      </Td>
                      <Td>{role}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )}
      </Card>
      <Flex
        justify="flex-end"
        w={{ base: "100%", md: "70%" }}
        mt={2}
        fontWeight="semibold"
      >
        <Button type="button" onClick={onNext} isLoading={isLoading}>
          {members.length ? t("save") : t("skip")}
        </Button>
      </Flex>
    </VStack>
  );
};
