import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Select } from "chakra-react-select";
import { ActivitySquare, PlusCircle } from "lucide-react";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { api } from "@/lib/axios";
import { colourOptions } from "@/lib/constants";
import { useProjectStore } from "@/store/project";
import { Group, Indicator } from "@/types";

import { AddIndicator } from "./AddIndicator";

const group = z.object({
  name: z.string().min(3),
  color: z.string().min(3),
  project: z.string().min(3),
});

type Schema = z.infer<typeof group>;

interface CreateGroupProps {
  onStepComplete: () => void;
}

export const CreateGroup = ({ onStepComplete }: CreateGroupProps) => {
  const [showAddIndicator, setShowAddIndicator] = useState(false);
  const [indicators, setIndicators] = useState<Indicator[]>([]);
  const { project, addGroup, updateProject } = useProjectStore();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Schema>({
    resolver: zodResolver(group),
    defaultValues: {
      project: project?._id,
    },
  });
  const [groupId, setGroupId] = useState<string | null>(null);
  const [groupCreated, setGroupCreated] = useState(false);

  const toast = useToast();
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const onSubmit = async (formData: Schema) => {
    const payload = {
      ...formData,
      project: project?._id,
    };
    if (!project?.groups) {
      updateProject({ ...project, groups: [] });
    }
    try {
      const response = await api.post<Group>("/group", payload);
      setGroupId(response.data._id);
      addGroup(response.data);
      setShowAddIndicator(true);
      toast({
        status: "info",
        title: "Grupo creado",
        duration: 2000,
      });
      setGroupCreated(true);
    } catch (error) {
      toast({
        status: "error",
        title: "Failed to create your group",
      });
    }
  };

  const handleAddIndicatorClick = () => {
    if (groupId) {
      setShowAddIndicator(true);
    } else {
      toast({
        status: "warning",
        title: "Cree un grupo antes de agregar un indicador.",
      });
    }
  };

  const handleAddIndicator = (newIndicator: Indicator) => {
    setIndicators((currentIndicators) => [...currentIndicators, newIndicator]);
    setShowAddIndicator(false);
  };

  return (
    <VStack w="100%">
      <Card
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        w={{ base: "100%", md: "70%" }}
      >
        <CardHeader>
          <Heading as="h3" size="lg">
            {t("group.create")}
          </Heading>
        </CardHeader>
        <CardBody as={Stack}>
          <Stack direction={{ base: "column", xl: "row" }}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel htmlFor="name">{t("group.group_name")}</FormLabel>
              <Input id="name" {...register("name")} />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.color}>
              <FormLabel htmlFor="color">Color</FormLabel>
              <Controller
                name="color"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    ref={ref}
                    options={colourOptions}
                    selectedOptionStyle="check"
                    value={colourOptions.find(
                      (option) => option.value === value
                    )}
                    onBlur={onBlur}
                    onChange={(option) => onChange(option?.value || "")}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.color && errors.color.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
          <Flex justify="flex-end">
            <Button
              mt={4}
              isLoading={isSubmitting}
              type="submit"
              isDisabled={groupCreated}
            >
              {t("create")}
            </Button>
          </Flex>
        </CardBody>
        <CardFooter>
          <VStack w="100%">
            <Flex justify="flex-start" alignItems="center" w="100%">
              <Button
                colorScheme="gray"
                color="black"
                gap={2}
                onClick={handleAddIndicatorClick}
              >
                {t("project.addIndicator")}
                <PlusCircle size="18" />
              </Button>
            </Flex>
            {indicators.map((indicator, index) => (
              <Flex key={index} mt={2} w="100%" gap={2} alignItems="center">
                <ActivitySquare size="16" />
                {indicator.name}
              </Flex>
            ))}
          </VStack>
          <Flex justify="flex-end" w={{ base: "100%", md: "70%" }}>
            <Button
              isLoading={isSubmitting}
              type="submit"
              style={{ display: "none" }}
              ref={submitRef}
              w="100px"
            >
              {t("save")}
            </Button>
          </Flex>
        </CardFooter>
      </Card>
      {showAddIndicator && groupId && (
        <AddIndicator
          onAdd={handleAddIndicator}
          onCancel={() => setShowAddIndicator(false)}
          groupId={groupId}
        />
      )}
      <Flex justify="flex-end" w={{ base: "100%", md: "70%" }}>
        <Button
          type="submit"
          w="100px"
          isDisabled={indicators.length === 0}
          mt={4}
          onClick={onStepComplete}
        >
          {t("finish")}
        </Button>
      </Flex>
    </VStack>
  );
};
