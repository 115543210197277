import {
  Box,
  ButtonGroup,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { api } from '@/lib/axios';
import { LIMIT } from '@/lib/constants';
import { createArray } from '@/lib/utils';

interface Events {
  event: string;
  date: Date;
}

interface Indicator {
  name: string;
}

interface Project {
  name?: string;
  _id?: string;
}

interface Indicators {
  createdAt: string;
  email: string;
  events: Events[];
  indicator: Indicator;
  project: Project;
  _id: string;
}

interface EmailsProps {
  year: string;
}

const fetchEmails = async (year = '0') => {
  let url = `/admin/emails/indicators`;

  if (year !== '0') {
    url += `&year=${year}`;
  }

  return api.get<Indicators[]>(url).then(({ data }) => data);
};

const isError = ['error', 'soft_bounce', 'hard_bounce', 'blocked'];

export default function IndicatorsEmails({ year }: EmailsProps) {
  const { t } = useTranslation();
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const { isLoading, data } = useQuery(
    ['indicators', year],
    () => fetchEmails(year),
    {
      keepPreviousData: true,
    }
  );

  const columns = React.useMemo<ColumnDef<Indicators>[]>(
    () => [
      {
        accessorFn: (row) => row.project?.name || '',
        header: t('project.index'),
      },
      {
        accessorFn: (row) => row.indicator?.name || '',
        header: t('indicator.index'),
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'createdAt',
        header: t('email.shipping_date'),
        cell: ({ getValue }) =>
          dayjs(getValue<Date>()).format('DD/MM/YYYY HH:mm:ss'),
      },
      {
        accessorFn: (row) => {
          const opened = row.events.find(
            (eventObj) => eventObj.event === 'opened'
          );
          return opened ? dayjs(opened.date).format('DD/MM/YYYY HH:mm:ss') : '';
        },
        header: t('email.open_at'),
      },
      {
        accessorFn: (row) => {
          const click = row.events.find(
            (eventObj) => eventObj.event === 'click'
          );
          return click ? 'Click' : '';
        },
        header: t('email.interaction'),
      },
      {
        accessorFn: (row) => {
          const click = row.events.find(
            (eventObj) => eventObj.event === 'click'
          );
          return click ? dayjs(click.date).format('DD/MM/YYYY HH:mm:ss') : '';
        },
        header: t('email.click'),
      },
      {
        accessorFn: (row) => {
          const errorEvent = row.events.find((eventObj) =>
            isError.includes(eventObj.event)
          );
          return errorEvent ? 'Error' : '';
        },
        header: t('alerts.error'),
      },
    ],
    [t]
  );

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 12,
      },
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <Stack spacing={8}>
      <TableContainer minH='75vh'>
        <Table variant='simple'>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <Box
                          {...{
                            onClick: header.column.getToggleSortingHandler(),
                            cursor: header.column.getCanSort()
                              ? 'pointer'
                              : 'default',
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                        </Box>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isLoading &&
              createArray(LIMIT).map((item) => (
                <Tr key={item}>
                  <Td colSpan={10}>
                    <Skeleton w='100%' h={10} />
                  </Td>
                </Tr>
              ))}
            {!isLoading &&
              table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <Stack justify='center' align='center'>
        <ButtonGroup size='sm' colorScheme='gray'>
          <IconButton
            icon={<ChevronsLeft />}
            aria-label='prev'
            onClick={() => table.setPageIndex(0)}
            isDisabled={!table.getCanPreviousPage()}
          />
          <IconButton
            icon={<ChevronLeft />}
            aria-label='prev'
            onClick={() => table.previousPage()}
            isDisabled={!table.getCanPreviousPage()}
          />
          <IconButton
            icon={<ChevronRight />}
            aria-label='next'
            onClick={() => table.nextPage()}
            isDisabled={!table.getCanNextPage()}
          />
          <IconButton
            icon={<ChevronsRight />}
            aria-label='next'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            isDisabled={!table.getCanNextPage()}
          />
        </ButtonGroup>
      </Stack>
    </Stack>
  );
}
