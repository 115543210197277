import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import dayjs from "dayjs";
import { CheckCircle2 } from "lucide-react";
import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useParams, useSearchParams } from "react-router-dom";

import { fetchExport } from "@/lib/utils";
import { useProjectStore } from "@/store/project";

const currentDate = dayjs().format("dd/MM/yyyy");
const year = new Date().getFullYear();

const types = [
  {
    value: "csv",
    label: "CSV",
  },
  {
    value: "xlsx",
    label: "Excel",
  },
];

interface FormData {
  type: string;
}

const DownloadPage = () => {
  const { project } = useProjectStore();
  const [searchParams] = useSearchParams();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitSuccessful },
    setError,
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      type: searchParams.get("type") || undefined,
    },
  });
  const submitRef = useRef<HTMLButtonElement>(null);

  const { id } = useParams();

  useEffect(() => {
    if (getValues("type")) {
      submitRef.current?.click();
    }
  }, [getValues]);

  const onSubmit = async (formData: FormData) => {
    try {
      const { type } = formData;

      const nameParsed =
        project?.name.slice(0, 10).replaceAll(/\s/g, "_").toLowerCase() ||
        "project";

      const fileName =
        "octopush_" + nameParsed + "_" + currentDate + "." + type;
      await fetchExport(`/export/project/${id}/${year}/${type}`, fileName);
    } catch (error) {
      setError("root", {
        message: "",
      });
    }
  };

  return (
    <Center minH="75vh">
      {!isSubmitSuccessful && (
        <Card w="md" as="form" onSubmit={handleSubmit(onSubmit)} rounded="lg">
          <CardBody>
            <Stack spacing="4">
              <Heading size="lg" textAlign="center">
                {project?.name}
              </Heading>
              <Text fontSize="sm">
                This summary highlights how we identify and measure relevant
                indicators, thereby improving strategic decision making to
                achieve organizational goals..
              </Text>
              <Controller
                control={control}
                name="type"
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>File type</FormLabel>
                    <Select
                      defaultValue={types.find((t) => t.value === field.value)}
                      colorScheme="primary"
                      options={types}
                      onChange={(v) => {
                        field.onChange(v?.value);
                      }}
                    />
                  </FormControl>
                )}
              />
            </Stack>
          </CardBody>
          <CardFooter>
            <Button
              isLoading={isSubmitting}
              w="full"
              type="submit"
              ref={submitRef}
            >
              Download
            </Button>
          </CardFooter>
        </Card>
      )}

      {isSubmitSuccessful && <Downloaded />}
    </Center>
  );
};

const Downloaded = () => {
  const { t } = useTranslation();

  return (
    <Stack align="center" p={4} spacing={6} textAlign="center">
      <Icon as={CheckCircle2} boxSize={20} color="green.500" />
      <Heading as="h2" size="xl">
        {t("download.title")}
      </Heading>
      <Text color="gray">{t("download.description")}</Text>
      <Button as={Link} to="/app/dashboard">
        {t("goApp")}
      </Button>
    </Stack>
  );
};

export default DownloadPage;
