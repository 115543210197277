import {
  Avatar,
  Flex,
  Grid,
  Heading,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
// import { CreditCardIcon, User2Icon } from "lucide-react";
import { User2Icon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, Outlet } from 'react-router-dom';

import { useUserStore } from '@/store/user';

const SettingsLayout = () => {
  const { user } = useUserStore();
  const { t } = useTranslation();

  return (
    <Stack gap={4}>
      <Flex alignItems='center' mb={{ base: 2, md: 0 }}>
        <Avatar
          name={user?.name}
          src={user?.picture || undefined}
          size='md'
          mr={3}
        />
        <Heading as='h1' size='md' lineHeight='condensed'>
          <Text color='gray.500'>{user?.name}</Text>
        </Heading>
      </Flex>
      <Grid templateColumns={{ base: '1fr', lg: '400px 3fr' }}>
        <Stack mt={4} spacing={8} minH='20vh'>
          <Link
            as={RouterLink}
            to={`profile`}
            display='flex'
            alignItems='center'
          >
            <User2Icon size={24} style={{ marginRight: '8px' }} />
            {t('settings_profile.personal_profile')}
          </Link>

          {/* <Link
            as={RouterLink}
            to={`manage-plan`}
            display="flex"
            alignItems="center"
          >
            <CreditCardIcon size={24} style={{ marginRight: "8px" }} />
            {t("settings_profile.admin_settings")}
          </Link> */}
        </Stack>
        <Outlet />
      </Grid>
    </Stack>
  );
};

export default SettingsLayout;
