import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  HStack,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Skeleton,
  Stack,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { PlusIcon } from 'lucide-react';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Step } from 'react-joyride';
import { useInfiniteQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { DashboardCards } from '@/components/DashboardCards';
import DownloadCsv from '@/components/project/DownloadCsv';
import { ProjectMenu } from '@/components/project/Menu';
import useTour from '@/hooks/useTour';
import { api } from '@/lib/axios';
import { LIMIT } from '@/lib/constants';
import { createArray } from '@/lib/utils';
import { useUserStore } from '@/store/user';
import { Project } from '@/types';

const STEPS: Step[] = [
  {
    target: '.step-1',
    content: 'tour.dashboard.content_1',
  },
  {
    target: '.step-2',
    content: 'tour.dashboard.content_2',
  },
  {
    target: '.step-3',
    content: 'tour.dashboard.content_3',
  },
  {
    target: '.step-4',
    content: 'tour.dashboard.content_4',
  },
  {
    target: '.step-5',
    content: 'tour.dashboard.content_5',
  },
  {
    target: '.step-6',
    content: 'tour.dashboard.content_6',
  },
  {
    target: '.step-7',
    content: 'tour.dashboard.content_7',
  },
];

async function fetchProjects(page: number) {
  const { data } = await api.get<{
    data: Project[];
    info: Record<string, number>;
  }>(`/project?page=${page}&limit=${LIMIT}`);

  return data;
}

export default function Dashboard() {
  const { t } = useTranslation();
  const { user } = useUserStore();
  const observerTarget = useRef<HTMLDivElement>(null);
  const tour = useTour(STEPS, user?.onboardingApp);

  const { data, fetchNextPage, isLoading, hasNextPage } = useInfiniteQuery(
    ['projects'],
    ({ pageParam = 1 }) => fetchProjects(pageParam),
    {
      getNextPageParam: ({ info }) => {
        if (!info.next) return info.page;
        return info.next;
      },
    }
  );

  const projects = useMemo(
    () =>
      data?.pages.reduce((prev, page) => {
        return {
          info: page.info,
          data: [...prev.data, ...page.data],
        };
      }),
    [data]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, fetchNextPage, hasNextPage]);

  return (
    <Stack spacing={8}>
      {!isLoading ? tour : ''}
      <HStack justify='space-between'>
        <Heading size={{ base: 'lg', md: 'xl' }} className='step-1'>
          {t('welcome')}, {user?.name}
        </Heading>
        <Stack className='step-3'>
          <Button
            as={Link}
            to='/app/new-project'
            size='sm'
            leftIcon={<PlusIcon size={14} />}
          >
            {t('project.create')}
          </Button>
        </Stack>
      </HStack>

      <DashboardCards />

      <Stack
        gap={3}
        justifyContent={'space-between'}
        flexDir='row'
        alignItems='center'
      >
        <Heading size='md'> {t('projects')}</Heading>
        <Stack className='step-7'>
          <DownloadCsv />
        </Stack>
      </Stack>

      {!projects?.data.length && !isLoading && <Empty />}

      <SimpleGrid
        spacing={8}
        mb={12}
        templateColumns='repeat(auto-fill, minmax(320px, 1fr))'
        className='step-6'
      >
        {isLoading
          ? createArray(LIMIT).map((index) => (
              <CardSkeletonProject key={index} />
            ))
          : projects?.data.map((project: Project) => (
              <LinkBox
                _hover={{
                  bg: 'muted.hover',
                  transition: `all .3s ease`,
                }}
                key={project._id}
                as={Card}
                bg='muted'
              >
                <CardHeader>
                  <Flex justify='space-between' align='start' gap={4}>
                    <LinkOverlay as={Link} to={`/app/project/${project._id}`}>
                      <Heading fontWeight='semibold' size='md' noOfLines={1}>
                        {project.name}
                      </Heading>
                    </LinkOverlay>
                    <ProjectMenu project={project} />
                  </Flex>

                  <HStack my={2} isTruncated>
                    {project.tags.map((tag) => (
                      <Tag key={tag} minW='min-content' maxW='max-content'>
                        {tag}
                      </Tag>
                    ))}
                  </HStack>
                  <Text color='gray' fontSize='sm'>
                    {project.members.length} {t('collaborators')}
                  </Text>
                </CardHeader>
                <CardBody color='gray'>
                  <Text fontSize='sm'>
                    {dayjs().to(dayjs(project.createdAt))}
                  </Text>
                </CardBody>
              </LinkBox>
            ))}
      </SimpleGrid>
      <div ref={observerTarget}></div>
    </Stack>
  );
}

function Empty() {
  const { t } = useTranslation();

  return (
    <Stack minH='40vh' justify='center' align='center'>
      <VStack textAlign='center' spacing={4}>
        <Heading size='sm'>{t('empty.projectsTitle')}</Heading>
        <Text fontSize='sm' color='gray'>
          {t('empty.projectsMsg')}
        </Text>
        <Button
          as={Link}
          to='/app/new-project'
          size='sm'
          leftIcon={<PlusIcon size={14} />}
        >
          {t('project.create')}
        </Button>
      </VStack>
    </Stack>
  );
}

const CardSkeletonProject = () => {
  return (
    <Card bg='muted'>
      <CardHeader>
        <Flex justify='space-between' align='start' gap={4}>
          <Skeleton h='30px' w='250px' />
          <Skeleton h='30px' w='30px' />
        </Flex>
        <HStack my={2} isTruncated>
          {createArray(3).map((i) => (
            <Skeleton key={i} h='25px' w='75px' />
          ))}
        </HStack>
        <Skeleton h='20px' w='50%' />
      </CardHeader>
      <CardBody>
        <Skeleton height='20px' width='50%' />
      </CardBody>
    </Card>
  );
};
