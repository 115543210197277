import { Select } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

const langs = ['en', 'es'];

export default function LangSelect() {
	const { t, i18n } = useTranslation();

	const changeLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
		i18n.changeLanguage(e.target.value);
	};

	return (
		<Select
			size="xs"
			maxW="100px"
			onChange={changeLanguage}
			value={i18n.resolvedLanguage}
		>
			{langs.map((lang) => (
				<option key={lang} value={lang}>
					{t(`lang.${lang}`)}
				</option>
			))}
		</Select>
	);
}
