import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  IconButton,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { PlusIcon, Trash } from "lucide-react";
import { useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { api } from "@/lib/axios";
import { tag as schema } from "@/lib/validations/project";
import { useProjectStore } from "@/store/project";
import { Project } from "@/types";

type Schema = z.infer<typeof schema>;

export function Tags() {
  const { project } = useProjectStore();
  const toast = useToast();
  const { t } = useTranslation();

  const parsedTags = useMemo(() => {
    return project?.tags?.map((tag) => ({
      tag,
    }));
  }, [project?.tags]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      tags: parsedTags || [],
    },
  });

  const { fields, append, remove } = useFieldArray<Schema>({
    control,
    name: "tags",
  });

  const onSubmit = async (formData: Schema) => {
    try {
      const tags = formData.tags.map((item) => item.tag);
      await api.patch<Project>(`/project/${project?._id}`, { tags });
      toast({
        status: "success",
        title: t("project.hashtags.tagsUpdated"),
      });
    } catch (error) {
      toast({
        status: "error",
        title: "Error to update tags",
      });
    }
  };

  return (
    <Card as="form" onSubmit={handleSubmit(onSubmit)}>
      <CardHeader as={HStack} justify="space-between">
        <Heading as="h3" size="md">
          {t("project.hashtags.hashtags")}
        </Heading>
        <Button
          size="sm"
          colorScheme="gray"
          leftIcon={<PlusIcon size={14} />}
          onClick={() => append({ tag: "" })}
        >
          {t("add")}
        </Button>
      </CardHeader>

      <CardBody as={Stack} spacing={8}>
        {fields.map((item, index) => (
          <HStack key={item.id}>
            <FormControl
              isInvalid={errors?.tags && !!errors.tags[index]?.tag}
              size="sm"
            >
              <Input id="tags" {...register(`tags.${index}.tag`)} size="sm" />

              <FormErrorMessage>
                {errors?.tags && errors.tags[index]?.tag?.message}
              </FormErrorMessage>
            </FormControl>

            <IconButton
              colorScheme="red"
              size="sm"
              icon={<Trash size={16} />}
              aria-label="Remove tag"
              onClick={() => remove(index)}
            />
          </HStack>
        ))}
      </CardBody>
      <CardFooter justify="flex-end">
        <Button isLoading={isSubmitting} type="submit">
          {t("save")}
        </Button>
      </CardFooter>
    </Card>
  );
}
