import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { PlusIcon, Trash } from "lucide-react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { api } from "@/lib/axios";
import { ROLES } from "@/lib/constants";
import { project } from "@/lib/validations/project";
import { Project } from "@/types";

type Schema = z.infer<typeof project>;

export default function NewProject() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Schema>({
    resolver: zodResolver(project),
    defaultValues: {
      members: [],
    },
  });
  const { fields, append, remove } = useFieldArray<Schema>({
    control,
    name: "members",
  });
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = async (formData: Schema) => {
    try {
      const formattedData = {
        ...formData,
        members: formData.members.map((member) => ({
          ...member,
          email: member.email.toLowerCase(),
        })),
      };
      const { data } = await api.post<Project>("/project", formattedData);

      navigate(`/app/project/${data._id}`);
    } catch (error) {
      toast({
        status: "error",
        title: "Error to create project",
      });
    }
  };

  return (
    <Stack spacing={8}>
      <VStack align="flex-start" maxW="xl">
        <Heading size="lg"> {t("project.create")}</Heading>
        <Text>{t("project.message")}</Text>
      </VStack>

      <SimpleGrid columns={{ base: 1, md: 2 }} gap={12}>
        <Card as="form" onSubmit={handleSubmit(onSubmit)}>
          <CardHeader>
            <Heading as="h3" size="md">
              {t("information")}
            </Heading>
          </CardHeader>

          <CardBody as={Stack} spacing={8}>
            <FormControl isInvalid={!!errors.name} isRequired>
              <FormLabel htmlFor="name">{t("name")}</FormLabel>
              <Input id="name" {...register("name")} />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.description}>
              <FormLabel htmlFor="description">{t("description")}</FormLabel>
              <Textarea id="description" {...register("description")} />
              <FormErrorMessage>
                {errors.description && errors.description.message}
              </FormErrorMessage>
            </FormControl>

            <HStack justify="space-between">
              <Heading as="h3" size="md">
                {t("members")}
              </Heading>
              <Button
                size="sm"
                colorScheme="gray"
                leftIcon={<PlusIcon size={14} />}
                onClick={() =>
                  append({
                    email: "",
                    role: ROLES[0],
                    canReceiveEmail: false,
                  })
                }
              >
                {t("project.addMember")}
              </Button>
            </HStack>

            {fields.map((item, index) => (
              <HStack key={item.id}>
                <FormControl
                  key={item.id}
                  isInvalid={errors?.members && !!errors.members[index]?.email}
                  size="sm"
                >
                  <Input {...register(`members.${index}.email`)} size="sm" />
                </FormControl>
                <FormControl
                  key={item.id}
                  isInvalid={errors?.members && !!errors.members[index]?.role}
                  size="sm"
                >
                  <Select
                    size="sm"
                    placeholder="Select role"
                    {...register(`members.${index}.role`)}
                  >
                    {ROLES.map((role) => (
                      <option value={role} key={role}>
                        {role}
                      </option>
                    ))}
                  </Select>{" "}
                </FormControl>

                <IconButton
                  colorScheme="red"
                  size="sm"
                  icon={<Trash size={16} />}
                  aria-label="Remove member"
                  onClick={() => remove(index)}
                />
              </HStack>
            ))}
          </CardBody>
          <CardFooter>
            <Button w="100%" isLoading={isSubmitting} type="submit">
              {t("create")}
            </Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <Heading as="h3" size="md">
              Templates
            </Heading>
          </CardHeader>

          <CardBody>
            <Text>Coming soon...</Text>
          </CardBody>
        </Card>
      </SimpleGrid>
    </Stack>
  );
}
