import { z } from 'zod';

export const login = z.object({
	email: z.string().email(),
	password: z.string().min(6),
});

export type Login = z.infer<typeof login>;

export const createAccount = z.object({
	name: z.string().min(3),
	lastName: z.string().min(3),
	email: z.string().email(),
	password: z.string().min(6),
});

export type CreateAccount = z.infer<typeof createAccount>;

export const confirm = z.object({
	email: z.string().email(),
	password: z.string().min(6),
	token: z
		.string()
		.regex(/^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-\\/=]*)/),
});

export type Confirm = z.infer<typeof confirm>;


export const recover = z.object({
	email: z.string().email(),
});

export type Recover = z.infer<typeof recover>;

export const forgot = z.object({
	email: z.string().email(),
	password: z.string().min(6),
	confirm_password: z.string().min(6)
});

export type Forgot = z.infer<typeof forgot>;

export const changePasswordSchema = z.object({
	newPassword: z.string().min(6),
	confirmNewPassword: z.string().min(6),
  }).refine(data => data.newPassword === data.confirmNewPassword, {
	message: 'Passwords do not match',
	path: ['confirmNewPassword'],
  });
  