import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LangSelect from "@/components/user/LangSelect";
import ThemeSelect from "@/components/user/ThemeSelect";
import { IS_ADMIN } from "@/lib/constants";
import { useUserStore } from "@/store/user";

export function UserMenu() {
  const { user, removeUser } = useUserStore();
  const { t } = useTranslation();

  const logout = () => {
    Cookies.remove("token");
    removeUser();
  };

  if (!user) return null;

  return (
    <Menu>
      <MenuButton>
        <Avatar
          name={user?.name}
          src={user?.picture || undefined}
          size="sm"
          rounded="lg"
        />
      </MenuButton>
      <MenuList fontSize="sm">
        <MenuItem isDisabled>
          <div>
            <Text>
              {user?.name} {user?.lastName}
            </Text>
            <Text fontSize="xs">{user?.email}</Text>
          </div>
        </MenuItem>
        <MenuDivider />
        {IS_ADMIN.includes(user?.role) && (
          <MenuItem as={Link} to="/admin/projects">
            {t("admin_panel")}
          </MenuItem>
        )}
        <MenuItem as={Link} to="/app/settings/profile">
          {t("profile")}
        </MenuItem>
        <Flex py={1} px={3} mt={1}>
          <Text flex="1">{t("language")}</Text>
          <LangSelect />
        </Flex>
        <Flex py={1} px={3} mt={1}>
          <Text flex="1">{t("theme")}</Text>
          <ThemeSelect />
        </Flex>
        <MenuDivider />
        <MenuItem onClick={logout}>{t("auth.logout")}</MenuItem>
      </MenuList>
    </Menu>
  );
}
