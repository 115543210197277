import {
  Box,
  Button,
  FormControl,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Billing = () => {
  const { t } = useTranslation();

  return (
    <Box p={4} borderWidth="1px" borderRadius="lg">
      <Stack spacing={4}>
        <Text fontSize="xl" fontWeight="bold">{t('settings_profile.billing')}</Text>
        <Box>
          <Text fontWeight="bold"> {t('settings_profile.creditcard_info')}</Text>
          {/* Credit card form */}
          <FormControl mt={2}>
            <Input type="text" placeholder={t('settings_profile.placeholders.enter_creditcardnumber')} />
          </FormControl>
          <FormControl mt={2}>
            <Input type="text" placeholder={t('settings_profile.placeholders.enter_billingaddress')}/>
          </FormControl>
          <Button mt={4} colorScheme="blue">{t('settings_profile.update_creditcard')}</Button>
        </Box>
        <Box>
          <Text fontWeight="bold">{t('settings_profile.company_address')}</Text>
          {/* Company address form */}
          <FormControl mt={2}>
            <Input type="text" placeholder={t('settings_profile.placeholders.enter_companyaddress')} />
          </FormControl>
          <Button mt={4} colorScheme="blue">{t('settings_profile.update_companyaddress')}</Button>
        </Box>
        <Box>
          <Text fontWeight="bold">{t('settings_profile.billing_info')}</Text>
          {/* Billing information form */}
          <FormControl mt={2}>
            <Input type="text" placeholder={t('settings_profile.placeholders.enter_billinginfo')} />
          </FormControl>
          <Button mt={4} colorScheme="blue">{t('settings_profile.update_billing_info')}</Button>
        </Box>
        <Box>
          <Text fontWeight="bold">{t('settings_profile.send_invoicesto')}</Text>
          {/* Email form */}
          <FormControl mt={2}>
            <Input type="email" placeholder={t('settings_profile.placeholders.enter_email_facturation')} />
          </FormControl>
        </Box>
        <Box>
          <Text fontWeight="bold">{t('settings_profile.additional_invoiceinfo')}</Text>
          {/* Additional invoice information form */}
          <FormControl mt={2}>
            <Input type="text" placeholder={t('settings_profile.placeholders.enter_additionalinfo')} />
          </FormControl>
        </Box>
      </Stack>
    </Box>
  );
};

export default Billing;
