import { Spinner, Stack } from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Navigate, useSearchParams } from "react-router-dom";

import { api } from "@/lib/axios";
import { EXPIRE_TOKEN } from "@/lib/constants";
import { useUserStore } from "@/store/user";
import { User } from "@/types";

async function checkAuthStatus() {
  const { data } = await api.get<{ token: string; user: User }>(
    `/auth/check-auth-status`
  );

  return data;
}

export function ResponsibleGuard({ children }: { children: React.ReactNode }) {
  const { removeUser } = useUserStore();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { data, isLoading } = useQuery(["responsible"], () =>
    checkAuthStatus()
  );

  useEffect(() => {
    if (token) {
      removeUser();
      Cookies.set("token", token, {
        expires: EXPIRE_TOKEN,
      });
    }
  }, [token, removeUser]);

  if (isLoading)
    return (
      <Stack w="100%" h="80vh" alignItems="center" justify="center">
        <Spinner />
      </Stack>
    );

  if (!data) {
    return <Navigate to="/auth/login" replace />;
  }

  return children;
}
