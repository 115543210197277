import { Flex, Heading, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { useUserStore } from '@/store/user';

interface Props {
	size?: 'md' | 'lg';
}

export const Logo = ({ size = 'md' }: Props) => {
	const { user } = useUserStore();

	return (
		<Flex
			as={Link}
			to={user ? '/app/dashboard' : '/'}
			alignItems="center"
			gap={2}
		>
			<Image src="/octopus.png" h={size === 'md' ? 6 : 8} />
			<Heading size={size}>Octopush</Heading>
		</Flex>
	);
};
