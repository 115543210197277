import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  SimpleGrid,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { api } from '@/lib/axios';
import { createArray } from '@/lib/utils';

async function fetchDashboardData() {
  const { data } = await api.get<{
    projects: number;
    indicators: number;
  }>(`/dashboard`);
  return data;
}

export function DashboardCards() {
  const { isLoading, data } = useQuery({
    queryKey: ['dashboard'],
    queryFn: fetchDashboardData,
  });
  const { t } = useTranslation();

  return (
    <SimpleGrid
      spacing={8}
      templateColumns='repeat(auto-fill, minmax(300px, 1fr))'
    >
      {isLoading ? (
        createArray(2).map((index) => <CardSkeleton key={index} />)
      ) : (
        <>
          <Card bg='muted' className='step-4'>
            <CardHeader>
              <Heading>{data?.indicators}</Heading>
            </CardHeader>
            <CardBody>
              <Text> {t('myIndicators')}</Text>
            </CardBody>
          </Card>
          <Card bg='muted' className='step-5'>
            <CardHeader>
              <Heading>{data?.projects}</Heading>
            </CardHeader>
            <CardBody>
              <Text> {t('projects')}</Text>
            </CardBody>
          </Card>
        </>
      )}
    </SimpleGrid>
  );
}

const CardSkeleton = () => {
  return (
    <Card bg='muted' h='150px' borderRadius='md'>
      <CardHeader>
        <Skeleton h='40px' w='25%' />
      </CardHeader>
      <CardBody>
        <Skeleton h='20px' w='50%' />
      </CardBody>
    </Card>
  );
};
