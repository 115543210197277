import { Box, Button, Heading, HStack, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TooltipRenderProps } from 'react-joyride';

import { api } from '@/lib/axios';
import { useUserStore } from '@/store/user';

export default function Tooltip({
  backProps,
  index,
  size,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
}: TooltipRenderProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const { updateUser } = useUserStore();

  const handleSkipOnboarding = async () => {
    try {
      await api.patch('/user', { onboardingApp: true });
      updateUser({ onboardingApp: true });
    } catch (error) {
      toast({
        status: 'error',
        title: 'Error completing onboarding',
      });
    }
  };

  const combinedOnClickSkip = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (skipProps.onClick) {
      skipProps.onClick(event); // Llama a la función onClick existente en skipProps
    }

    await handleSkipOnboarding(); // Llama a mi función para manejar el salto de onboarding
    toast({
      status: 'info',
      title: t('tour.skipTour'),
      duration: 4000,
    });
  };

  const combinedOnClickLastStep = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (primaryProps.onClick) {
      primaryProps.onClick(event); // Llama a la función onClick existente en primaryProps
    }
    if (isLastStep) {
      await handleSkipOnboarding(); // Llama a mi función para manejar la finalización del onboarding si es el último paso.
      toast({
        status: 'info',
        title: t('tour.finishedTour'),
        duration: 4000,
      });
    }
  };
  return (
    <Box
      {...tooltipProps}
      bg='white'
      borderRadius='md'
      p={5}
      maxW={[350, 400]}
      textAlign={'center'}
    >
      <Box mb={5}>
        {step.title && (
          <Heading fontSize={'x-large'} mb={5}>
            {step.title}
          </Heading>
        )}
        {step.content && <Box>{t(String(step.content))}</Box>}
      </Box>
      <Box>
        <HStack justify={'space-between'}>
          {!isLastStep && (
            <Button
              {...skipProps}
              size='sm'
              variant='ghost'
              colorScheme='gray'
              title={''}
              aria-label={t('tour.skip')}
              onClick={combinedOnClickSkip}
            >
              {t('tour.skip')}
            </Button>
          )}
          <HStack justify={'end'} w={'100%'}>
            {index > 0 && (
              <Button
                {...backProps}
                size='sm'
                variant='ghost'
                title={''}
                aria-label={t('tour.back')}
              >
                {t('tour.back')}
              </Button>
            )}
            <Button
              {...primaryProps}
              size='sm'
              title={''}
              aria-label={!isLastStep ? t('tour.next') : t('tour.last')}
              onClick={combinedOnClickLastStep}
            >
              {!isLastStep
                ? ` ${t('tour.next')} (${index + 1}/${size}) `
                : ` ${t('tour.last')} (${index + 1}/${size})`}
            </Button>
          </HStack>
        </HStack>
      </Box>
    </Box>
  );
}
