import { RouteObject } from 'react-router-dom';

import { ResponsibleGuard } from '@/guards/ResponsibleGuard';
import { PublicLayout } from '@/layouts/PublicLayout';
import Unsuscribe from '@/pages/unsubscribe';

export const unsubscribe: RouteObject = {
	path: '/unsubscribe',
	element: (
		<ResponsibleGuard>
			<PublicLayout />
		</ResponsibleGuard>
	),
	children: [
		{
			path: 'emails',
			element: <Unsuscribe />,
		},
	],
};
