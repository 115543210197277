import { Button, useToast } from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { api } from "@/lib/axios";
import { EXPIRE_TOKEN } from "@/lib/constants";
import { useUserStore } from "@/store/user";
import { User } from "@/types";

interface Props {
  userId: string;
}
export function MagicLogin({ userId }: Props) {
  const { t } = useTranslation();
  const { setUser } = useUserStore();
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get<{ token: string; user: User }>(
        `/admin/magic-login/${userId}`
      );
      Cookies.set("token", data.token, {
        expires: EXPIRE_TOKEN,
      });
      setUser(data.user);
      navigate("/app/dashboard");
    } catch (error) {
      toast({
        title: "Error to handle user login",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button onClick={handleLogin} isLoading={isLoading}>
      {t("auth.login")}
    </Button>
  );
}
