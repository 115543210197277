import {
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  SimpleGrid,
  Stack,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Calendar } from "lucide-react";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { api } from "@/lib/axios";
import { createArray } from "@/lib/utils";
import { useProjectStore } from "@/store/project";
import { useUserStore } from "@/store/user";
import { Indicator } from "@/types";

const schema = z.object({
  name: z.string().min(3),
  description: z.string(),
  group: z.string().min(3).optional(),
  responsible: z.string().email().optional().or(z.literal("")),
  request_data: z.object({
    isPreviousMonth: z.boolean().default(false),
    ofType: z.string().min(3).default("never"),
    days: z.array(z.number()).optional(),
    months: z.array(z.number()).optional(),
  }),
});

type Schema = z.infer<typeof schema>;

interface AddIndicatorProps {
  onAdd: (newIndicator: Indicator) => void;
  onCancel: () => void;
  groupId: string;
}

export const AddIndicator = ({
  onAdd,
  onCancel,
  groupId,
}: AddIndicatorProps) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      // responsible: "",
    },
  });
  const toast = useToast();
  const { t } = useTranslation();
  const { project, addIndicator } = useProjectStore();
  const [selectedType, setSelectedType] = useState("");
  const [days, setDays] = useState<number[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useUserStore();

  const onSubmit = async (formData: Schema) => {
    try {
      const payload = {
        ...formData,
        responsible: formData.responsible?.toLowerCase() ?? "",
        project: project?._id,
        group: groupId,
      };
      const response = await api.post<Indicator>("/indicator", payload);
      addIndicator(response.data);
      onAdd(response.data);
      reset();
      toast({
        status: "info",
        title: "Indicador agregado",
        duration: 2000,
      });
    } catch (error) {
      toast({
        status: "error",
        title: "Error to create indicator",
      });
    }
  };

  const handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(e.target.value);
  };

  useEffect(() => {
    if (user?.email) {
      setValue("responsible", user.email.toLowerCase());
    }
    setValue("request_data.days", days);
  }, [user?.email, days, setValue]);

  return (
    <Card
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w={{ base: "100%", md: "68%" }}
      mt="-70px"
      zIndex="docked"
    >
      <CardBody>
        <Stack spacing={4}>
          <Heading as="h3" size="sm">
            {t("indicator.index")}
          </Heading>
          <Stack direction={{ base: "column", xl: "row" }} mt={6}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel htmlFor="name" mb="0" mr={2}>
                {t("title")}
              </FormLabel>
              <Input id="name" {...register("name")} />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.request_data?.ofType}>
              <FormLabel htmlFor="ofType" mb="0" mr={2}>
                {t("onboarding.requestInformation")}
              </FormLabel>
              <Select
                {...register("request_data.ofType")}
                onChange={handleTypeChange}
              >
                <option value="never">{t("never")}</option>
                <option value="every_day">{t("daily")}</option>
                <option value="monthly">{t("monthly")}</option>
              </Select>
              {selectedType === "monthly" && (
                <Popover isOpen={isOpen} onClose={onClose}>
                  <PopoverTrigger>
                    <Button
                      onClick={onOpen}
                      leftIcon={<Calendar />}
                      color="black"
                      mt={2}
                      colorScheme="gray"
                      w={{ xl: "full" }}
                      display="flex"
                      justifyContent="flex-start"
                    >
                      {t("onboarding.selectDays")}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverBody>
                      <SimpleGrid columns={7} spacing={2}>
                        {createArray(31).map((day) => (
                          <Button
                            key={`day-${day}`}
                            colorScheme={days.includes(day) ? "brand" : "gray"}
                            variant={days.includes(day) ? "solid" : "outline"}
                            onClick={() => {
                              if (days.includes(day)) {
                                setDays(days.filter((d) => d !== day));
                              } else {
                                if (days.length === 5) return;
                                setDays([...days, day]);
                              }
                            }}
                          >
                            {day}
                          </Button>
                        ))}
                      </SimpleGrid>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
              <FormErrorMessage>
                {errors.request_data?.ofType &&
                  errors.request_data?.ofType.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
          <FormControl>
            <FormLabel htmlFor="description">{t("description")}</FormLabel>
            <Textarea id="description" {...register("description")} />
          </FormControl>
          <FormControl isInvalid={!!errors.responsible}>
            <FormLabel htmlFor="responsible" mb="0" mr={2} mt={6}>
              {t("responsible.who")}
            </FormLabel>
            <Input
              id="responsible"
              type="email"
              {...register("responsible")}
              placeholder={user?.email}
            />
            <FormErrorMessage>
              {errors.responsible && errors.responsible.message}
            </FormErrorMessage>
          </FormControl>
          <Flex justify="flex-end" w="100%" gap={2}>
            <Button colorScheme="gray" w="100px" onClick={onCancel}>
              {t("cancel")}
            </Button>
            <Button isLoading={isSubmitting} type="submit" w="100px">
              {t("create")}
            </Button>
          </Flex>
        </Stack>
      </CardBody>
    </Card>
  );
};
