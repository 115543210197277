import { z } from 'zod';

export const member = z.object({
	email: z.string().email(),
	role: z.string().min(3),
	canReceiveEmail: z.boolean().default(false),
	_id: z.string().optional(),
});

export const project = z.object({
	name: z.string().min(3),
	description: z.string(),
	members: z.array(member),
});

export const members = z.object({
	members: z.array(member),
});

export const tag = z.object({
	tags: z.array(
		z.object({
			tag: z.string().min(3).max(25),
		})
	),
});

export const summary = z.object({
	isPreviousMonth: z.boolean(),
	days: z.array(z.number()),
	months: z.array(z.number()),
	ofType: z.string(),
});
