export const LIMIT = 12;

export const EXPIRE_TOKEN = 7;

export const ROLES = ["collaborator", "viewer"];

export const IS_ADMIN = ["admin", "super-admin"];

export const CAN_EDIT = ["owner", "collaborator"];

export const CAN_RESEND_EMAIL = ["owner", "admin", "super-admin"];

type Colour = {
  value: string;
  label: string;
  colorScheme: string;
};

export const colourOptions: Colour[] = [
  { value: "#525252", label: "Default", colorScheme: "blackAlpha" },
  { value: "#f44336", label: "Red", colorScheme: "red" },
  { value: "#e91e63", label: "Pink", colorScheme: "pink" },
  {
    value: "#9c27b0",
    label: "Purple",
    colorScheme: "purple",
  },
  { value: "#38A169", label: "Green", colorScheme: "green" },
  { value: "#D69E2E", label: "Yellow", colorScheme: "yellow" },
  { value: "#00bcd4", label: "Cyan", colorScheme: "cyan" },
];

export const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const COUNTRIES: string[] = [
  "China",
  "India",
  "United States",
  "Indonesia",
  "Brazil",
  "Nigeria",
  "Russia",
  "Mexico",
  "Japan",
  "Germany",
  "United Kingdom",
  "France",
  "Italy",
  "South Africa",
  "South Korea",
  "Colombia",
  "Spain",
  "Argentina",
  "Chile",
  "Peru",
  "Venezuela",
  "Ecuador",
  "Bolivia",
  "Paraguay",
  "Uruguay",
];

export const plans = [
  {
    plan: {
      type: "Free",
      cost: "$ 0",
      description: "desc_free",
      users: 2,
      recipients: 25,
      projects: 5,
      support: "email",
      email: true,
      branding: false,
      whatsapp_SMS: false,
      push: false,
    },
    color: "10px solid green",
    desc_branding: "",
    desc_notif: "",
  },
  {
    plan: {
      type: "Premium",
      cost: "$ 25.00",
      description: "desc_premium",
      users: 10,
      recipients: 250,
      projects: 25,
      support: "email_chat",
      email: true,
      branding: false,
      whatsapp_SMS: false,
      push: false,
    },
    color: "10px solid blue",
    desc_branding: "",
    desc_notif: "",
  },
  {
    plan: {
      type: "Enterprise",
      cost: " ",
      description: "desc_enterprise",
      users: "unlimited",
      recipients: "unlimited",
      projects: "unlimited",
      support: "manager",
      email: true,
      branding: true,
      whatsapp_SMS: true,
      push: true,
    },
    color: "10px solid orange",
    desc_branding: "desc_branding",
    desc_notif: "desc_notif",
  },
];

export const companySizeOptions = [
  { value: "1 - 20", label: "1 - 20" },
  { value: "21 - 50", label: "21 - 50" },
  { value: "51- 100", label: "51- 100" },
  { value: "101 - 200", label: "101 - 200" },
  { value: "201 - 1000", label: "201 - 1000" },
  { value: "1001 - 10000", label: "1001 - 10000" },
  { value: "10001 +", label: "10001 +" },
];
