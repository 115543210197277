import { Button, useToast } from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { api } from "@/lib/axios";
import { EXPIRE_TOKEN } from "@/lib/constants";
import { useUserStore } from "@/store/user";
import { User } from "@/types";

export const GoogleButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const { setUser } = useUserStore();

  const login = useGoogleLogin({
    onSuccess: async (googleData) => {
      try {
        const { data } = await api.post<{ token: string; user: User }>(
          "/auth/google?type=basic",
          {
            token: googleData.access_token,
          }
        );
        Cookies.set("token", data.token, {
          expires: EXPIRE_TOKEN,
        });
        setUser(data.user);
        navigate("/app/dashboard");
      } catch (error) {
        toast({
          status: "error",
          title: "Failed google sign in",
        });
      } finally {
        setIsLoading(false);
      }
    },
    onError: () => {
      toast({
        status: "error",
        title: "Failed google sign in",
      });
      setIsLoading(false);
    },
  });

  return (
    <Button
      onClick={() => {
        setIsLoading(true);
        login();
      }}
      leftIcon={<Icons.google />}
      isDisabled={isLoading}
      isLoading={isLoading}
      variant="outline"
      colorScheme="gray"
      type="button"
    >
      {t("auth.signInGoogle")}
    </Button>
  );
};
