import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { api } from '@/lib/axios';
import { useUserStore } from '@/store/user';

export const EnableDashboardTour = () => {
  const toast = useToast();
  const { updateUser } = useUserStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnboarding = async () => {
    try {
      await api.patch('/user', { onboardingApp: false });
      updateUser({ onboardingApp: false });
      navigate('/app/dashboard');
      toast({
        status: 'info',
        title: t('tour.tourEnabled'),
        duration: 2000,
      });
    } catch (error) {
      toast({
        status: 'error',
        title: t('tour.tourError'),
      });
    }
  };

  return (
    <Card as='form'>
      <CardHeader>
        <Heading size='md'>{t('tour.enableTour')}</Heading>
      </CardHeader>
      <CardBody as={Stack} spacing={8}>
        <Text>{t('tour.repeatTour')}</Text>
      </CardBody>
      <CardFooter justify='flex-end'>
        <Button onClick={handleOnboarding}>{t('tour.enable')}</Button>
      </CardFooter>
    </Card>
  );
};
