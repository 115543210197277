import {
	IconButton,
	Menu,
	MenuButton,
	MenuGroup,
	MenuItem,
	MenuList,
} from '@chakra-ui/react';
import { MoreVertical, Pencil, Trash } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { DeleteGroup } from '@/components/group/Delete';
import { UpdateGroup } from '@/components/group/Update';
import { Group } from '@/types';

interface Props {
	group: Group;
}

export function GroupMenu({ group }: Props) {
	const { t } = useTranslation();

	return (
		<Menu>
			<MenuButton
				as={IconButton}
				icon={<MoreVertical size={16} />}
				variant="ghost"
				size="sm"
				colorScheme="gray"
			/>
			<MenuList>
				<MenuGroup title={t('options')} fontSize="small">
					<MenuItem
						as={UpdateGroup}
						icon={<Pencil size={14} />}
						group={group}
						size="sm"
						colorScheme="gray"
						rounded="none"
						fontWeight="normal"
					>
						{t('edit')}
					</MenuItem>
					<MenuItem
						as={DeleteGroup}
						icon={<Trash size={14} />}
						groupId={group._id}
						size="sm"
						colorScheme="gray"
						rounded="none"
						fontWeight="normal"
					>
						{t('delete')}
					</MenuItem>
				</MenuGroup>
			</MenuList>
		</Menu>
	);
}
