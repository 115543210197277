import {
  Box,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Select,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { api } from "@/lib/axios";
import { LIMIT } from "@/lib/constants";
import { createArray } from "@/lib/utils";
import { ExportModal } from "@/pages/admin/components/ExportModal";
import { MagicLogin } from "@/pages/admin/components/MagicLogin";
import { useUserStore } from "@/store/user";

interface User {
  _id: string;
  name: string;
  lastName: string;
  provider: string;
  email: string;
  password: string;
  isActive: boolean;
  tokenConfirm: null;
  picture: null;
  company: string;
  country: string;
  role: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  emails: Emails;
  totalProjects: number;
  totalCollaborators: number;
  webSessions: number;
  lastLogin: Date;
}

interface Emails {
  indicator: boolean;
}

const fetchUsers = async (year = "0") => {
  let url = `/admin/users`;

  if (year !== "0") {
    url += `&year=${year}`;
  }

  return api.get<User[]>(url).then(({ data }) => data);
};

const currentYear = new Date().getFullYear();

export default function Users() {
  const { user } = useUserStore();
  const [year, setYear] = useState("0");
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const { isLoading, data } = useQuery(
    ["users", year],
    () => fetchUsers(year),
    {
      keepPreviousData: true,
    }
  );

  const { t } = useTranslation();

  const columns = React.useMemo<ColumnDef<User>[]>(() => {
    const base: ColumnDef<User>[] = [
      {
        accessorKey: "_id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: t("name"),
      },
      {
        accessorKey: "lastName",
        header: t("lastName"),
      },
      {
        accessorKey: "email",
        header: t("email.index"),
      },
      {
        accessorKey: "company",
        header: t("company"),
      },
      {
        accessorKey: "country",
        header: t("country"),
      },
      {
        accessorKey: "lastLogin",
        header: t("last_login"),
        cell: ({ getValue }) => {
          if (getValue()) {
            return dayjs(getValue<Date>()).format("DD/MM/YYYY HH:mm:ss");
          }
        },
      },
      {
        accessorKey: "totalCollaborators",
        header: t("admin.projects.numbers"),
      },
      {
        accessorKey: "createdAt",
        header: t("created_at"),
        cell: ({ getValue }) =>
          dayjs(getValue<Date>()).format("DD/MM/YYYY HH:mm:ss"),
      },
      {
        accessorKey: "webSessions",
        header: t("web_sessions"),
      },
    ];

    if (user && user.role === "super-admin") {
      base.push({
        accessorKey: "webSessions",
        header: t("auth.login"),
        cell: ({ row }) => <MagicLogin userId={row.original._id} />,
      });
    }

    return base;
  }, [t, user]);

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 12,
      },
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <Stack spacing={8}>
      <Flex justify="space-between">
        <Heading>{t("users")}</Heading>
        <Flex gap={4}>
          <Select
            w="auto"
            defaultValue="0"
            onChange={(e) => setYear(e.target.value)}
          >
            <option value="0">{t("all")}</option>
            <option value={currentYear}>{currentYear}</option>
            <option value={currentYear - 1}>{currentYear - 1}</option>
            <option value={currentYear - 2}>{currentYear - 2}</option>
          </Select>
          <ExportModal type="users" />
        </Flex>
      </Flex>
      <TableContainer minH="75vh">
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <Box
                          {...{
                            onClick: header.column.getToggleSortingHandler(),
                            cursor: header.column.getCanSort()
                              ? "pointer"
                              : "default",
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </Box>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isLoading &&
              createArray(LIMIT).map((item) => (
                <Tr key={item}>
                  <Td colSpan={10}>
                    <Skeleton w="100%" h={10} />
                  </Td>
                </Tr>
              ))}
            {!isLoading &&
              table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          textAlign={
                            cell.column.id === "totalCollaborators" ||
                            cell.column.id === "webSessions"
                              ? "center"
                              : "left"
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <Stack justify="center" align="center">
        <ButtonGroup size="sm" colorScheme="gray">
          <IconButton
            icon={<ChevronsLeft />}
            aria-label="prev"
            onClick={() => table.setPageIndex(0)}
            isDisabled={!table.getCanPreviousPage()}
          />
          <IconButton
            icon={<ChevronLeft />}
            aria-label="prev"
            onClick={() => table.previousPage()}
            isDisabled={!table.getCanPreviousPage()}
          />
          <IconButton
            icon={<ChevronRight />}
            aria-label="next"
            onClick={() => table.nextPage()}
            isDisabled={!table.getCanNextPage()}
          />
          <IconButton
            icon={<ChevronsRight />}
            aria-label="next"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            isDisabled={!table.getCanNextPage()}
          />
        </ButtonGroup>
      </Stack>
    </Stack>
  );
}
