import {
  Box,
  Button,
  Flex,
  Heading,
  Progress,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { api } from "@/lib/axios";
import { useProjectStore } from "@/store/project";
import { useUserStore } from "@/store/user";

import { AddMember } from "./steps/AddMember";
import { CreateGroup } from "./steps/CreateGroup";
import { CreateProject } from "./steps/CreateProject";

export interface Member {
  email: string;
  role: string;
  canReceiveEmail?: boolean;
}

export interface Group {
  name: string;
  color: string;
  indicators: Indicator[];
}

interface Indicator {
  name: string;
}

interface Step {
  number: string;
  title: string;
  description: string;
}

interface StepDisplayProps {
  step: Step;
  isActive: boolean;
}

export const OnboardingContainer = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsCompleted, setStepsCompleted] = useState([false, false, false]);
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const [skipOnboarding, setSkipOnboarding] = useState(false);
  const { updateUser } = useUserStore();
  const navigate = useNavigate();

  const steps = [
    {
      number: "1",
      title: t("onboarding.step_1.title"),
      description: t("onboarding.step_1.description"),
    },
    {
      number: "2",
      title: t("onboarding.step_2.title"),
      description: t("onboarding.step_2.description"),
    },
    {
      number: "3",
      title: t("onboarding.step_3.title"),
      description: t("onboarding.step_3.description"),
    },
  ];

  const onStepComplete = async (stepIndex: number) => {
    setStepsCompleted((stepsCompleted) => {
      const updatedStepsCompleted = [...stepsCompleted];
      updatedStepsCompleted[stepIndex] = true;
      return updatedStepsCompleted;
    });

    if (stepIndex >= steps.length - 1) {
      setOnboardingComplete(true);
      try {
        await api.patch("/user", { onboarding: true });
      } catch (error) {
        toast({
          status: "error",
          title: "Error completing onboarding",
        });
      }
    } else {
      setCurrentStep(stepIndex + 1);
    }
  };

  if (onboardingComplete) {
    return <Congratulations />;
  }

  const handleSkipOnboarding = async () => {
    try {
      await api.patch("/user", { onboarding: true });
      setSkipOnboarding(true);
      setOnboardingComplete(true);
      updateUser({ onboarding: true });
      navigate("/app/dashboard");
    } catch (error) {
      toast({
        status: "error",
        title: "Error completing onboarding",
      });
    }
  };

  const totalSteps = steps.length;
  const progressValue = (currentStep / totalSteps) * 100;

  return (
    <Stack pb={4}>
      <Stack justify="center" alignItems="center" mt={8}>
        <Progress
          value={progressValue}
          size="xs"
          colorScheme="brand"
          w="100%"
        />
      </Stack>
      <Flex direction={{ base: "column", md: "row" }} w="100%" mt={8}>
        <VStack w={{ base: "100%", md: "40%" }} align="stretch">
          {steps.map((step, index) => (
            <StepDisplay
              key={index}
              step={step}
              isActive={stepsCompleted[index] || index === currentStep}
            />
          ))}
        </VStack>
        <Flex w={{ base: "100%", md: "60%" }} justifyContent="center">
          {currentStep === 0 && (
            <CreateProject onStepComplete={() => onStepComplete(0)} />
          )}
          {currentStep === 1 && (
            <AddMember onStepComplete={() => onStepComplete(1)} />
          )}
          {currentStep === 2 && (
            <CreateGroup onStepComplete={() => onStepComplete(2)} />
          )}
        </Flex>
      </Flex>
      {currentStep === 0 && !skipOnboarding && (
        <Flex direction="column" align="center" justify="center" mt={8}>
          <Text p={2}>{t("onboarding.skipOnboarding")}</Text>
          <Button onClick={handleSkipOnboarding} mt={1} variant="ghost">
            {t("skip")}
          </Button>
        </Flex>
      )}
    </Stack>
  );
};

const StepDisplay: React.FC<StepDisplayProps> = ({ step, isActive }) => {
  return (
    <Flex p={5} gap={8} opacity={isActive ? 1 : 0.5} borderRadius="md">
      <Box
        w={10}
        h={10}
        bg="brand.500"
        color="white"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="md"
      >
        <Text fontSize="lg" fontWeight="bold">
          {step.number}
        </Text>
      </Box>
      <Box flex="1">
        <Heading fontSize="md">{step.title}</Heading>
        <Text fontSize="xs" mt={4}>
          {step.description}
        </Text>
      </Box>
    </Flex>
  );
};

const Congratulations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { project } = useProjectStore();
  const { updateUser } = useUserStore();

  return (
    <Stack minH="50vh" justify="center" align="center">
      <VStack textAlign="center" spacing={4}>
        <Heading size="xl">{t("onboarding.congratulations")}</Heading>
        <Text fontSize="md" color="gray">
          {t("onboarding.onboardingComplete")}
        </Text>
        <Button
          mr={3}
          onClick={() => {
            updateUser({ onboarding: true });
            navigate(`/app/project/${project?._id}`);
          }}
        >
          {t("onboarding.goToProject")}
        </Button>
      </VStack>
    </Stack>
  );
};
