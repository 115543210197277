import { Select, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const colors = ['dark', 'light', 'system'];

export default function ThemeSelect() {
	const { colorMode, setColorMode } = useColorMode();
	const { t } = useTranslation();

	return (
		<Select
			size="xs"
			maxW="100px"
			onChange={(e) => setColorMode(e.target.value)}
			value={colorMode}
		>
			{colors.map((color) => (
				<option key={color} value={color}>
					{t(color)}
				</option>
			))}
		</Select>
	);
}
