import { Container, Stack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";

import { Footer } from "@/components/layout/Footer";
import { Header } from "@/components/layout/Header";

export function PublicLayout({ children }: Partial<PropsWithChildren>) {
  return (
    <Container as={Stack} maxW="7xl" spacing={8} minH="90vh">
      <Header />
      <Stack pt="120px">{children || <Outlet />}</Stack>
      <Footer />
      <ScrollRestoration />
    </Container>
  );
}
