import { Heading, HStack, Stack } from '@chakra-ui/react';
import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import {
	SortableContext,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { GroupMenu } from '@/components/group/Menu';
import { useProjectStore } from '@/store/project';

import { SortableItem } from './SortableItem';

interface Props {
	id: string;
	items: UniqueIdentifier[];
}

export function Container({ id, items }: Props) {
	const { project } = useProjectStore();
	const { setNodeRef } = useDroppable({
		id,
	});

	const group = project?.groups.find((group) => group._id === id);
	if (!group) return null;

	return (
		<Stack
			spacing={6}
			minW="xs"
			bg="muted"
			p={4}
			borderTop="3px solid"
			borderColor={group.color}
		>
			<HStack justify="space-between">
				<Heading size="md">{group.name}</Heading>

				<GroupMenu group={group} />
			</HStack>
			<SortableContext
				id={id}
				items={items}
				strategy={verticalListSortingStrategy}
			>
				<Stack spacing={4} ref={setNodeRef}>
					{items?.map((id) => (
						<SortableItem key={id} id={id} />
					))}
				</Stack>
			</SortableContext>
		</Stack>
	);
}
