import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Switch,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { MoreVertical, Settings } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { api } from '@/lib/axios';
import { useProjectStore } from '@/store/project';
import { Project } from '@/types';

interface DashboardMenuProps {
	project: Project;
}

export function ProjectMenu({ project: initialProject }: DashboardMenuProps) {
	const { updateProject } = useProjectStore();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [isLoading, setIsLoading] = useState(false);
	const toast = useToast();
	const cancelRef = useRef(null);

	const [isPaused, setIsPaused] = useState(!!initialProject.paused_at);
	useEffect(() => {
		setIsPaused(!!initialProject.paused_at);
	}, [initialProject]);

	const togglePause = async () => {
		setIsLoading(true);
		const newPaused = !isPaused;

		try {
			const pausedAtValue = newPaused ? new Date() : null;
			await api.patch(`/project/${initialProject?._id}`, {
				paused_at: pausedAtValue,
			});
			setIsPaused(newPaused);
			updateProject({ ...initialProject, paused_at: pausedAtValue });

			toast({
				title: newPaused ? t('project.paused') : t('project.resumed'),
			});
		} catch (error) {
			toast({
				status: 'error',
				title: 'Failed update project',
			});
		} finally {
			setIsLoading(false);
			onClose();
		}
	};

	return (
		<Menu placement="bottom">
			<MenuButton
				as={IconButton}
				icon={<MoreVertical size={18} />}
				variant="ghost"
				size="sm"
				position="relative"
				zIndex={100}
				colorScheme="gray"
			/>
			<MenuList minW="180px" maxW="280px">
				<Text px={4} py={2} fontWeight="semibold" fontSize="small">
					{t('options')}
				</Text>
				<MenuItem
					as="button"
					icon={<Switch size="sm" isChecked={isPaused} />}
					rounded="none"
					fontWeight="normal"
					fontSize={14}
					onClick={onOpen}
				>
					{isPaused ? t('resume') : t('pause')}
				</MenuItem>
				<MenuItem
					as={Link}
					icon={<Settings size={18} />}
					to={`/app/project/${initialProject._id}/settings`}
					iconSpacing={4}
					pl={4}
					rounded="none"
					fontWeight="normal"
					fontSize={14}
					w="100%"
				>
					{t('settings')}
				</MenuItem>
			</MenuList>

			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{isPaused ? t('project.resume') : t('project.pause')}
						</AlertDialogHeader>
						<AlertDialogBody>
							{isPaused
								? t('project.messages.resume')
								: t('project.messages.pause')}
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose} colorScheme="gray">
								{t('cancel')}
							</Button>
							<Button onClick={togglePause} isLoading={isLoading} ml={3}>
								{isPaused ? t('resume') : t('pause')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</Menu>
	);
}
