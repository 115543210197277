import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select } from 'chakra-react-select';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

import { api } from '@/lib/axios';
import { companySizeOptions } from '@/lib/constants';
import { demoSchema, Inputs } from '@/lib/validations/demo';

export default function Demo() {
  const { t } = useTranslation();
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (formData: Inputs) => {
      await api.post<Inputs>('/contact', {
        ...formData,
        reason: 'Demo',
      });
    }
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(demoSchema),
    defaultValues: {
      companySize: '',
    },
  });
  const onSubmit = async (formData: Inputs) => {
    mutate(formData);
  };

  return (
    <Container maxW='xl'>
      <Stack
        as='form'
        p={[6, 10]}
        spacing={5}
        border='2px'
        borderColor={'muted'}
        borderRadius={10}
        onSubmit={handleSubmit(onSubmit)}
      >
        {!isSuccess && !isError && (
          <>
            <Heading mb={[3, 6]} size={'lg'}>
              {t('demo.demo_form_heading')}
            </Heading>
            <FormControl isInvalid={!!errors.email} isRequired>
              <FormLabel htmlFor='email'>{t('demo.work_email')}</FormLabel>
              <Input type='email' id='email' {...register('email')} />
              <FormErrorMessage>
                {errors.email?.message && t(errors.email.message)}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.name} isRequired>
              <FormLabel htmlFor='name'>{t('demo.firts_name')}</FormLabel>
              <Input type='text' id='name' {...register('name')} />
              <FormErrorMessage>
                {errors.name?.message && t(errors.name.message)}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.lastName} isRequired>
              <FormLabel htmlFor='lastName'>{t('demo.last_name')}</FormLabel>
              <Input type='text' id='lastName' {...register('lastName')} />
              <FormErrorMessage>
                {errors.lastName?.message && t(errors.lastName.message)}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.company} isRequired>
              <FormLabel htmlFor='company'>{t('demo.company')}</FormLabel>
              <Input type='text' id='company' {...register('company')} />
              <FormErrorMessage>
                {errors.company?.message && t(errors.company.message)}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.companySize} isRequired>
              <FormLabel htmlFor='companySize'>
                {t('demo.company_size')}
              </FormLabel>
              <Controller
                control={control}
                name='companySize'
                render={({ field: { onChange } }) => (
                  <Select
                    placeholder={t('demo.select')}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: '#F7FAFC',
                        borderColor: '#E1E7EE',
                      }),
                    }}
                    options={[
                      ...companySizeOptions,
                      { label: t('demo.unknown'), value: 'unknown' },
                    ]}
                    onChange={(option) => {
                      onChange(option?.value);
                    }}
                    isSearchable={false}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.companySize?.message && t(errors.companySize.message)}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='phone'>{t('demo.phone_number')}</FormLabel>
              <Input type='number' id='phone' {...register('phone')} />
            </FormControl>
            <FormControl isInvalid={!!errors.message}>
              <FormLabel htmlFor='message'>{t('demo.message')}</FormLabel>
              <Textarea id='message' {...register('message')} />
              <FormErrorMessage>
                {errors.message?.message && t(errors.message.message)}
              </FormErrorMessage>
            </FormControl>
            <Button
              type='submit'
              isLoading={isLoading}
              loadingText={t('demo.submitting')}
            >
              {t('demo.book_demo')}
            </Button>
            <Text color={'gray'} fontSize={'sm'}>
              {t('demo.privacy_message_1')}
              <Link as='b'> {t('demo.privacy_message_2')}</Link>
            </Text>
          </>
        )}
        {isError && !isLoading && (
          <VStack gap={10}>
            <Image src='/sad.png' w={20} />
            <Heading textAlign={'center'}>{t('demo.send_error')}</Heading>
            <Text align={'center'}>{t('demo.try_again')}</Text>

            <Button as={RouterLink} to='/'>
              {t('demo.go_start')}
            </Button>
          </VStack>
        )}
        {isSuccess && !isLoading && !isError && (
          <VStack gap={10}>
            <Image src='/send.png' w={20} />
            <Heading textAlign={'center'}> {t('demo.message_send')}</Heading>
            <Text align={'center'}>{t('demo.thanks')} </Text>

            <Button as={RouterLink} to='/'>
              {t('demo.go_start')}
            </Button>
          </VStack>
        )}
      </Stack>
      {/* </Flex> */}
    </Container>
  );
}
