import {
	Button,
	Input,
	InputGroup,
	InputProps,
	InputRightElement,
} from '@chakra-ui/react';
import { Eye, EyeOff } from 'lucide-react';
import { forwardRef, useState } from 'react';

export const InputPassword = forwardRef<HTMLInputElement, InputProps>(
	(props, ref) => {
		const [show, setShow] = useState(false);
		const handleClick = () => setShow(!show);

		return (
			<InputGroup size="md">
				<Input
					pr="4.5rem"
					type={show ? 'text' : 'password'}
					ref={ref}
					autoComplete="false"
					{...props}
				/>
				<InputRightElement width="4.5rem">
					<Button
						h="1.75rem"
						size="sm"
						onClick={handleClick}
						variant="ghost"
						colorScheme="gray"
					>
						{show ? <EyeOff size={14} /> : <Eye size={14} />}
					</Button>
				</InputRightElement>
			</InputGroup>
		);
	}
);
