import {
  // Button,
  Card,
  CardBody,
  // CardFooter,
  CardHeader,
  Divider,
  Heading,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { plans } from "@/lib/constants";

const PlanCards = () => {
  const { t } = useTranslation();
  return (
    <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={4}>
      {plans.map(({ plan, color }, index) => {
        const {
          type,
          cost,
          description,
          users,
          recipients,
          projects,
          support,
          email,
          branding,
          whatsapp_SMS,
          push,
        } = plan;

        return (
          <Card
            key={index}
            borderTop={color}
            bg="muted"
            maxWidth="-moz-fit-content"
            _hover={{
              bg: "muted.hover",
              transform: "translateY(-45px)",
              transition: `background-color 0.3s easy, transform 0.3s easy`,
            }}
            mt={8}
          >
            <CardHeader
              flexDir="column"
              borderBottom="1.5px solid"
              borderColor="gray"
            >
              <Heading size="lg" textAlign="center">
                {type}
              </Heading>
              <Heading size="xl" textAlign="center">
                {cost}
              </Heading>
              <Text>{t(`pricing.plans.${description}`)} </Text>
            </CardHeader>
            <Divider />
            <CardBody>
              <VStack justifyContent="start">
                <HStack>
                  <Text fontWeight="bold">{t("pricing.cards.users")} </Text>
                  <Text>
                    {typeof users === "number"
                      ? users
                      : t(`pricing.plans.${users}`)}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">{t("pricing.cards.projects")}</Text>
                  <Text>
                    {typeof projects === "number"
                      ? projects
                      : t(`pricing.plans.${projects}`)}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">{t("pricing.cards.recipients")}</Text>
                  <Text>
                    {typeof recipients === "number"
                      ? recipients
                      : t(`pricing.plans.${recipients}`)}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">{t("pricing.cards.support")} </Text>
                  <Text> {t(`pricing.plans.${support}`)}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">{t("pricing.cards.email")}: </Text>
                  <Text>
                    {email ? t("pricing.cards.yes") : t("pricing.cards.no")}
                  </Text>
                </HStack>

                <HStack>
                  <Text fontWeight="bold">{t("pricing.cards.branding")}</Text>
                  <Text>
                    {branding ? t("pricing.cards.yes") : t("pricing.cards.no")}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">
                    {t("pricing.cards.whatsapp_sms")}
                  </Text>
                  <Text>
                    {whatsapp_SMS
                      ? t("pricing.cards.yes")
                      : t("pricing.cards.no")}
                  </Text>
                </HStack>
                <HStack>
                  <Text flexWrap="nowrap" fontWeight="bold">
                    {t("pricing.cards.push")}
                  </Text>
                  <Text>
                    {push ? t("pricing.cards.yes") : t("pricing.cards.no")}
                  </Text>
                </HStack>
              </VStack>
            </CardBody>
            {/* <CardFooter justifyContent="center">
              <Button>{t("pricing.cards.view")}</Button>
            </CardFooter> */}
          </Card>
        );
      })}
    </SimpleGrid>
  );
};

export default PlanCards;
