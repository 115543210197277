import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { Check, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { plans } from '@/lib/constants';

// Tipo de los planes de usuario
type UserPlanType = 'Free' | 'Premium' | 'Enterprise';

// Estructura del plan del usuario
const userPlan: {
  type: UserPlanType;
  occupiedUsers: number;
  occupiedRecipients: number;
  occupiedProjects: number;
}[] = [
  {
    type: 'Premium',
    occupiedUsers: 1,
    occupiedRecipients: 4,
    occupiedProjects: 3,
  },
];

// Encuentra el plan del usuario
const userPlanType: UserPlanType | undefined = userPlan[0]?.type;

// Mapa de colores con transparencia
const colorsWithTransparency: Record<UserPlanType, string> = {
  Free: 'rgba(0, 128, 0, 0.2)',
  Premium: 'rgba(0, 0, 255, 0.2)',
  Enterprise: 'rgba(255, 165, 0, 0.2)',
};

const MyPlan = () => {
  const { t } = useTranslation();
  console.log(plans);

  return (
    <Table bg='muted' variant='striped' minW='auto' my={1}>
      <Thead>
        <Tr>
          <Th></Th>
          {plans.map(({ plan: { type }, color }, index) => (
            <Th
              key={index}
              borderTop={color}
              style={{
                width: '25%',
                backgroundColor:
                  userPlanType === type
                    ? colorsWithTransparency[userPlanType]
                    : 'inherit',
              }}
              textAlign='center'
            >
              {type}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        <TableRow title={t('pricing.titles.cost')}>
          {plans.map(({ plan: { cost, type, description } }, index) => {
            const enterprisePlan = userPlan.find(
              (p) => p.type === 'Enterprise'
            );

            return (
              <Td
                key={index}
                textAlign='center'
                style={{
                  width: '25%',
                  backgroundColor:
                    userPlanType === type
                      ? colorsWithTransparency[userPlanType]
                      : 'inherit',
                }}
              >
                {/* Chequear como va a venir el costo de plan para empresas, por el momento no aparece nada */}
                {cost}
                <Text>
                  {enterprisePlan && type === 'Enterprise'
                    ? 'Precio pactado'
                    : type === 'Enterprise' &&
                      t(`pricing.plans.${description}`)}{' '}
                </Text>
              </Td>
            );
          })}
        </TableRow>

        <TableRow title={t('pricing.titles.users')}>
          {plans.map(({ plan: { users, type } }, index) => {
            const matchingUserPlan = userPlan.find((p) => p.type === type);

            return (
              <Td
                key={index}
                textAlign='center'
                style={{
                  width: '25%',
                  backgroundColor:
                    userPlanType === type
                      ? colorsWithTransparency[userPlanType]
                      : 'inherit',
                }}
              >
                {matchingUserPlan && typeof users === 'number'
                  ? `${matchingUserPlan.occupiedUsers}/${users}`
                  : typeof users === 'number'
                  ? users
                  : t(`pricing.plans.${users}`)}
              </Td>
            );
          })}
        </TableRow>
        <TableRow title={t('pricing.titles.recipients')}>
          {plans.map(({ plan: { recipients, type } }, index) => {
            const matchingUserPlan = userPlan.find((p) => p.type === type);

            return (
              <Td
                key={index}
                textAlign='center'
                style={{
                  width: '25%',
                  backgroundColor:
                    userPlanType === type
                      ? colorsWithTransparency[userPlanType]
                      : 'inherit',
                }}
              >
                {matchingUserPlan && typeof recipients === 'number'
                  ? `${matchingUserPlan.occupiedRecipients}/${recipients}`
                  : typeof recipients === 'number'
                  ? recipients
                  : t(`pricing.plans.${recipients}`)}
              </Td>
            );
          })}
        </TableRow>
        <TableRow title={t('pricing.titles.projects')}>
          {plans.map(({ plan: { projects, type } }, index) => {
            const matchingUserPlan = userPlan.find((p) => p.type === type);

            return (
              <Td
                key={index}
                textAlign='center'
                style={{
                  width: '25%',
                  backgroundColor:
                    userPlanType === type
                      ? colorsWithTransparency[userPlanType]
                      : 'inherit',
                }}
              >
                {matchingUserPlan && typeof projects === 'number'
                  ? `${matchingUserPlan.occupiedProjects}/${projects}`
                  : typeof projects === 'number'
                  ? projects
                  : t(`pricing.plans.${projects}`)}
              </Td>
            );
          })}
        </TableRow>
        <TableRow title={t('pricing.titles.support')}>
          {plans.map(({ plan: { support, type } }, index) => (
            <Td
              key={index}
              textAlign='center'
              style={{
                width: '25%',
                backgroundColor:
                  userPlanType === type
                    ? colorsWithTransparency[userPlanType]
                    : 'inherit',
              }}
            >
              {t(`pricing.plans.${support}`)}
            </Td>
          ))}
        </TableRow>
        <TableRow title={t('pricing.titles.push')}>
          {plans.map(({ plan: { push, type } }, index) => (
            <Td
              textAlign='center'
              key={index}
              style={{
                width: '25%',
                backgroundColor:
                  userPlanType === type
                    ? colorsWithTransparency[userPlanType]
                    : 'inherit',
              }}
            >
              {push ? (
                <Check style={{ display: 'inline-block' }} color='green' />
              ) : (
                <X style={{ display: 'inline-block' }} color='red' />
              )}
            </Td>
          ))}
        </TableRow>
        <TableRow title={t('pricing.titles.branding')}>
          {plans.map(({ plan: { branding, type }, desc_branding }, index) => (
            <Td
              textAlign='center'
              key={index}
              style={{
                width: '25%',
                backgroundColor:
                  userPlanType === type
                    ? colorsWithTransparency[userPlanType]
                    : 'inherit',
              }}
            >
              {branding ? (
                <Check
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  color='green'
                />
              ) : (
                <X
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  color='red'
                />
              )}{' '}
              {desc_branding && t(`pricing.plans.${desc_branding}`)}
            </Td>
          ))}
        </TableRow>
        <TableRow title={t('pricing.titles.whatsapp_sms')}>
          {plans.map(({ plan: { whatsapp_SMS, type }, desc_notif }, index) => (
            <Td
              textAlign='center'
              key={index}
              style={{
                width: '25%',
                backgroundColor:
                  userPlanType === type
                    ? colorsWithTransparency[userPlanType]
                    : 'inherit',
              }}
            >
              {whatsapp_SMS ? (
                <Check
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  color='green'
                />
              ) : (
                <X
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }}
                  color='red'
                />
              )}{' '}
              {desc_notif && t(`pricing.plans.${desc_notif}`)}
            </Td>
          ))}
        </TableRow>
      </Tbody>
    </Table>
  );
};

const TableRow = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <Tr>
    <Td fontWeight='bold'>{title}</Td>
    {children}
  </Tr>
);

export default MyPlan;
