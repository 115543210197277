import { RouteObject } from 'react-router-dom';

import { ProjectGuard } from '@/guards/ProjectGuard';
import { ResponsibleGuard } from '@/guards/ResponsibleGuard';
import { PublicLayout } from '@/layouts/PublicLayout';
import DownloadPage from '@/pages/exports/project/Download';

export const exports: RouteObject = {
	path: '/export',
	element: (
		<ResponsibleGuard>
			<PublicLayout />
		</ResponsibleGuard>
	),
	children: [
		{
			path: 'project',
			element: <ProjectGuard />,
			children: [
				{
					path: ':id',
					element: <DownloadPage />,
				},
			],
		},
	],
};
