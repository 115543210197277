import {
	Button,
	Container,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Stack,
	useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { api } from '@/lib/axios';
import { Recover, recover } from '@/lib/validations/auth';

export default function PasswordRecoverPage() {
	const toast = useToast();
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<Recover>({
		resolver: zodResolver(recover),
	});
	const [isSuccess, setIsSuccess] = useState(false);

	const onSubmit = async (formData: Recover) => {
		try {
			await api.post('/auth/forgot-password', formData);
			localStorage.setItem('email-recover-password', formData.email);
			toast({
				status: 'success',
				title: 'Mail sended',
				duration: 100000,
			});
			setIsSuccess(true);
		} catch (error) {
			toast({
				status: 'error',
				title: 'Invalid Credentials',
			});
		}
	};

	return (
		<Container as={Stack} spacing={8}>
			<Heading>{t('auth.recover_password')}</Heading>

			<Stack as="form" spacing={4} mt={4} onSubmit={handleSubmit(onSubmit)}>
				<FormControl isInvalid={!!errors.email} isRequired>
					<FormLabel htmlFor="email">Email</FormLabel>
					<Input id="email" {...register('email')} readOnly={isSuccess} />
					<FormErrorMessage>
						{errors.email && errors.email.message}
					</FormErrorMessage>
				</FormControl>
				<Button isLoading={isSubmitting} type="submit" isDisabled={isSuccess}>
					{t('continue')}
				</Button>
			</Stack>
		</Container>
	);
}
