import {
  Box,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Select,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { api } from '@/lib/axios';
import { LIMIT } from '@/lib/constants';
import { createArray } from '@/lib/utils';
import { ExportModal } from '@/pages/admin/components/ExportModal';

interface Response {
  name: string;
  createdAt: Date;
  id: string;
  groups: number;
  indicators: number;
  indicators_with_push: number;
  members: number;
  users_with_push: number;
  summary_downloads: number;
  last_summary_download: null;
  owner: Owner;
}

interface Owner {
  _id: string;
  name: string;
  lastName: string;
  provider: string;
  email: string;
  password: string;
  isActive: boolean;
  tokenConfirm: null;
  picture: null;
  role: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  emails: Emails;
}

interface Emails {
  indicator: boolean;
}

const fetchProjects = async (year = '0') => {
  let url = `/admin/projects`;

  if (year !== '0') {
    url += `&year=${year}`;
  }

  return api.get<Response[]>(url).then(({ data }) => data);
};

const currentYear = new Date().getFullYear();

export default function Users() {
  const [year, setYear] = useState('0');
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const { isLoading, data } = useQuery(
    ['projects', year],
    () => fetchProjects(year),
    {
      keepPreviousData: true,
    }
  );

  const { t } = useTranslation();

  const columns = React.useMemo<ColumnDef<Response>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'name',
        header: t('name'),
      },
      {
        accessorKey: 'owner.name',
        header: t('owner'),
        cell: ({ getValue, row }) => {
          const lastName = row.original.owner?.lastName;
          if (lastName) {
            return `${getValue()} ${lastName}`;
          }
        },
      },
      {
        accessorFn: (row) => row.owner?.email || '',
        header: t('owner.email'),
      },
      {
        accessorKey: 'members',
        header: t('members'),
      },
      {
        accessorKey: 'groups',
        header: t('groups'),
      },
      {
        accessorKey: 'indicators',
        header: t('indicators'),
      },
      {
        accessorKey: 'indicators_with_push',
        header: t('indicators_with_push'),
      },
      {
        accessorKey: 'summary_downloads',
        header: t('summary_downloads'),
      },
      {
        accessorKey: 'last_summary_download',
        header: t('last_summary_download'),
      },
      {
        accessorKey: 'createdAt',
        header: t('created_at'),
        cell: ({ getValue }) =>
          dayjs(getValue<Date>()).format('DD/MM/YYYY HH:mm:ss'),
      },
    ],
    [t]
  );

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 12,
      },
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <Stack spacing={8}>
      <Flex justify='space-between'>
        <Heading>{t('projects')}</Heading>
        <Flex gap={[2, 4]}>
          <Select
            w='auto'
            defaultValue='0'
            onChange={(e) => setYear(e.target.value)}
          >
            <option value='0'>{t('all')}</option>
            <option value={currentYear}>{currentYear}</option>
            <option value={currentYear - 1}>{currentYear - 1}</option>
            <option value={currentYear - 2}>{currentYear - 2}</option>
          </Select>
          <ExportModal type='projects' />
        </Flex>
      </Flex>
      <TableContainer minH='75vh'>
        <Table variant='simple'>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <Box
                          {...{
                            onClick: header.column.getToggleSortingHandler(),
                            cursor: header.column.getCanSort()
                              ? 'pointer'
                              : 'default',
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                        </Box>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isLoading &&
              createArray(LIMIT).map((item) => (
                <Tr key={item}>
                  <Td colSpan={10}>
                    <Skeleton w='100%' h={10} />
                  </Td>
                </Tr>
              ))}
            {!isLoading &&
              table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          textAlign={
                            cell.column.id === 'members' ||
                            cell.column.id === 'groups' ||
                            cell.column.id === 'indicators' ||
                            cell.column.id === 'indicators_with_push' ||
                            cell.column.id === 'summary_downloads'
                              ? 'center'
                              : 'left'
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <Stack justify='center' align='center'>
        <ButtonGroup size='sm' colorScheme='gray'>
          <IconButton
            icon={<ChevronsLeft />}
            aria-label='prev'
            onClick={() => table.setPageIndex(0)}
            isDisabled={!table.getCanPreviousPage()}
          />
          <IconButton
            icon={<ChevronLeft />}
            aria-label='prev'
            onClick={() => table.previousPage()}
            isDisabled={!table.getCanPreviousPage()}
          />
          <IconButton
            icon={<ChevronRight />}
            aria-label='next'
            onClick={() => table.nextPage()}
            isDisabled={!table.getCanNextPage()}
          />
          <IconButton
            icon={<ChevronsRight />}
            aria-label='next'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            isDisabled={!table.getCanNextPage()}
          />
        </ButtonGroup>
      </Stack>
    </Stack>
  );
}
