import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BeaconRenderProps } from 'react-joyride';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    background-color:  rgb(82, 111, 229);
    transform: scale(1.4);
  }
`;

const BeaconButton = styled.button`
  animation: ${pulse} 1s ease-in-out infinite;
  background-color: rgb(46, 83, 231);
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  width: 2rem;
`;

const BeaconComponent = forwardRef<HTMLButtonElement, BeaconRenderProps>(
  (props, ref) => {
    const { t } = useTranslation();
    return (
      <BeaconButton
        ref={ref}
        {...props}
        title={t('tour.click')}
        aria-label={t('tour.click')}
      />
    );
  }
);

export default BeaconComponent;
