import { Flex, HStack, Link, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { MobileMenu } from '@/components/layout/MobileMenu';
import { Logo } from '@/components/Logo';
import { UserMenu } from '@/components/user/Menu';
import { NavItem } from '@/types';

const links: NavItem[] = [
  {
    href: '/app/dashboard',
    label: 'dashboard',
  },
];

interface Props {
  children: React.ReactNode;
}

export function AppLayout({ children }: Props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Stack p='6' spacing={12} minH='100vh'>
      <Flex as='header' justify='space-between' align='center'>
        <HStack spacing={12} alignItems='center'>
          <Logo />
          <HStack as='nav' display={{ base: 'none', md: 'flex' }}>
            {links.map(({ href, label }) => (
              <Link
                as={RouterLink}
                key={label}
                to={href}
                fontSize='15px'
                fontWeight='semibold'
                color={pathname === href ? 'brand.600' : 'text'}
                borderBottom={
                  pathname === href ? '2px solid' : '2px transparent'
                }
                borderColor={pathname === href ? 'brand.600' : 'transparent'}
                _hover={{ color: 'brand.700' }}
                textTransform='capitalize'
              >
                {t(label)}
              </Link>
            ))}
          </HStack>
        </HStack>
        <HStack gap={6}>
          <Stack className='step-2'>
            <UserMenu />
          </Stack>

          <MobileMenu links={links} />
        </HStack>
      </Flex>
      {children}
    </Stack>
  );
}
