import { RouteObject } from 'react-router-dom';

import { AuthGuard } from '@/guards/AuthGuard';
import { ProjectGuard } from '@/guards/ProjectGuard';
import SettingsLayout from '@/layouts/SettingsLayout';
import Dashboard from '@/pages/app/Dashboard';
import NewProject from '@/pages/app/NewProject';
import Profile from '@/pages/app/Profile';
import Project from '@/pages/app/project';
import Settings from '@/pages/app/project/Settings';
import Table from '@/pages/app/project/Table';
import ManagePlan from '@/components/profile/ManagePlan';

export const app: RouteObject = {
	path: '/app',
	element: <AuthGuard />,
	children: [
		{
			path: 'settings',
			element: <SettingsLayout />,
			children:[
				{
					path: 'profile',
					element:<Profile />,
				},
				{
					path: 'manage-plan',
					element:<ManagePlan />,
				}
			]
			
		},
		{
			path: 'dashboard',
			element: <Dashboard />,
		},
		{
			path: 'new-project',
			element: <NewProject />,
		},
		{
			path: 'project',
			element: <ProjectGuard />,
			children: [
				{
					path: ':id',
					element: <Project />,
				},
				{
					path: ':id/table',
					element: <Table />,
				},
				{
					path: ':id/settings',
					element: <Settings />,
				},
			],
		},
	],
};
