import { create } from 'zustand';

import { Group, Indicator, Project } from '@/types';

interface ProjectState {
	project?: Project;
	setProject: (payload: Project) => void;
	updateProject: (payload: Partial<Project>) => void;
	removeProject: () => void;
	addGroup: (payload: Group) => void;
	removeGroup: (payload: Group['_id']) => void;
	updateGroup: (payload: Partial<Group>) => void;
	addIndicator: (payload: Indicator) => void;
	updateIndicator: (payload: Partial<Indicator>) => void;
	removeIndicator: (payload: Indicator) => void;
}

export const useProjectStore = create<ProjectState>()((set) => ({
	project: undefined,
	setProject: (payload) => set(() => ({ project: payload })),
	updateProject: (payload) =>
		set((state) => ({
			project: {
				...state.project!,
				...payload,
			},
		})),
	removeProject: () => set(() => ({})),
	addGroup: (payload) =>
		set((state) => ({
			project: {
				...state.project!,
				groups: [
					...state.project!.groups,
					{
						...payload,
						indicators: [],
					},
				],
			},
		})),
	updateGroup: (payload) =>
		set((state) => ({
			project: {
				...state.project!,
				groups: state.project!.groups?.map((group) => {
					if (group._id === payload._id) {
						return {
							...group,
							...payload,
						};
					}

					return group;
				}),
			},
		})),
	removeGroup: (payload) =>
		set((state) => ({
			project: {
				...state.project!,
				groups: state.project!.groups.filter((group) => group._id !== payload),
			},
		})),
	addIndicator: (payload) =>
		set((state) => ({
			project: {
				...state.project!,
				groups: state.project!.groups?.map((group) => {
					if (group._id === payload.group) {
						return {
							...group,
							indicators: [...group.indicators, payload],
						};
					}

					return group;
				}),
			},
		})),
	updateIndicator: (payload) =>
		set((state) => ({
			project: {
				...state.project!,
				groups: state.project!.groups?.map((group) => {
					if (group._id === payload.group) {
						return {
							...group,
							indicators: group.indicators.map((i) => {
								if (i._id === payload._id) {
									return {
										...i,
										...payload,
									};
								}
								return i;
							}),
						};
					}

					return group;
				}),
			},
		})),
	removeIndicator: (payload) =>
		set((state) => ({
			project: {
				...state.project!,
				groups: state.project!.groups?.map((group) => {
					if (group._id === payload.group) {
						return {
							...group,
							indicators: group.indicators.filter(
								(indicator) => indicator._id !== payload._id
							),
						};
					}

					return group;
				}),
			},
		})),
}));
