import {
	Button,
	ButtonProps,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select } from 'chakra-react-select';
import { forwardRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { api } from '@/lib/axios';
import { colourOptions } from '@/lib/constants';
import { useProjectStore } from '@/store/project';
import { Group } from '@/types';

const group = z.object({
	name: z.string().min(3),
	color: z.string().min(3),
	project: z.string().min(3),
});

type Schema = z.infer<typeof group>;

export const CreateGroup = forwardRef<HTMLButtonElement, ButtonProps>(
	(props, ref) => {
		const { isOpen, onOpen, onClose } = useDisclosure();
		const { project, addGroup } = useProjectStore();
		const {
			register,
			handleSubmit,
			control,
			formState: { isSubmitting, errors },
		} = useForm<Schema>({
			resolver: zodResolver(group),
			defaultValues: {
				project: project?._id,
			},
		});
		const toast = useToast();
		const { t } = useTranslation();

		const onSubmit = async (formData: Schema) => {
			try {
				const { data } = await api.post<Group>('/group', formData);
				addGroup(data);
				onClose();
			} catch (error) {
				toast({
					status: 'error',
					title: 'Failed to create your account',
				});
			}
		};
		return (
			<>
				<Button ref={ref} {...props} onClick={onOpen} />
				<Modal isOpen={isOpen} onClose={onClose} size="lg">
					<ModalOverlay />
					<ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
						<ModalHeader>{t('group.create')}</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Stack>
								<FormControl isInvalid={!!errors.name} isRequired>
									<FormLabel htmlFor="name">{t('name')}</FormLabel>
									<Input id="name" {...register('name')} />
									<FormErrorMessage>
										{errors.name && errors.name.message}
									</FormErrorMessage>
								</FormControl>
								<FormControl isInvalid={!!errors.color} isRequired>
									<FormLabel htmlFor="color">Color</FormLabel>
									<Controller
										control={control}
										name="color"
										render={({ field }) => (
											<Select
												options={colourOptions}
												selectedOptionStyle="check"
												onChange={(newValue) => {
													if (!newValue) return;
													field.onChange(newValue.value);
												}}
											/>
										)}
									/>
									<FormErrorMessage>
										{errors.color && errors.color.message}
									</FormErrorMessage>
								</FormControl>
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button
								colorScheme="gray"
								mr={3}
								onClick={onClose}
								isDisabled={isSubmitting}
							>
								{t('cancel')}
							</Button>
							<Button type="submit" isLoading={isSubmitting}>
								{t('create')}
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</>
		);
	}
);
