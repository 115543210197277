import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Checkbox,
	Heading,
	Stack,
	useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { api } from '@/lib/axios';
import { emailPreferences as schema } from '@/lib/validations/user';
import { useUserStore } from '@/store/user';
import { User } from '@/types';

type Schema = z.infer<typeof schema>;

export function EmailPreferences() {
	const { user, updateUser } = useUserStore();
	const {
		register,
		handleSubmit,
		formState: { isSubmitting },
	} = useForm<Schema>({
		resolver: zodResolver(schema),
		defaultValues: user?.emails,
	});

	const toast = useToast();
	const { t } = useTranslation();

	const onSubmit = async (formData: Schema) => {
		try {
			const { data } = await api.patch<User>('/user/emails', formData);
			toast({
				title: 'Account updated.',
				description: 'We have saved the email preferences.',
				status: 'success',
			});
			updateUser(data);
		} catch (error) {
			toast({
				status: 'error',
				title: 'Error',
				description: 'We could not save the changes',
			});
		}
	};

	return (
		<Card as="form" onSubmit={handleSubmit(onSubmit)}>
			<CardHeader>
				<Heading size="md">{t('email.preferences')}</Heading>
			</CardHeader>

			<CardBody as={Stack} spacing={8}>
				<Checkbox {...register('indicator')}>{t('email.indicator')}</Checkbox>
			</CardBody>
			<CardFooter justify="flex-end">
				<Button isLoading={isSubmitting} type="submit">
					{t('save')}
				</Button>
			</CardFooter>
		</Card>
	);
}
